var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Configurações de Frete "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":"","depressed":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 text-h5 secondary--text",attrs:{"cols":"12"}},[_vm._v(" Parâmetros de Desconto/Sobra ")]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":[
                { text: 'Plataforma', value: true },
                { text: 'Geral', value: false },
              ],"label":"Cálculo de Tolerância"},model:{value:(_vm.form.unloadTolerance),callback:function ($$v) {_vm.$set(_vm.form, "unloadTolerance", $$v)},expression:"form.unloadTolerance"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":[
                { text: 'Descarga', value: 'DESCARGA' },
                { text: 'Percentual', value: 'PERCENTUAL' },
                { text: 'Produtor', value: 'PRODUTOR' },
              ],"label":"Tipo de Tolerância (Medidos)","disabled":!_vm.form.unloadTolerance},model:{value:(_vm.form.measuredToleranceType),callback:function ($$v) {_vm.$set(_vm.form, "measuredToleranceType", $$v)},expression:"form.measuredToleranceType"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[(_vm.form.measuredToleranceType === 'DESCARGA')?_c('masked-text-field',{attrs:{"label":"Tolerância por Descarga (Litros)","mask":{ mask: Number, min: 0 },"unmask":"","disabled":!_vm.form.unloadTolerance},model:{value:(_vm.form.unloadToleranceLiters),callback:function ($$v) {_vm.$set(_vm.form, "unloadToleranceLiters", $$v)},expression:"form.unloadToleranceLiters"}}):(_vm.form.measuredToleranceType === 'PERCENTUAL')?_c('masked-text-field',{attrs:{"label":"Tolerância por Descarga (%)","mask":{ mask: Number, min: 0, max: 100 },"unmask":"","disabled":!_vm.form.unloadTolerance},model:{value:(_vm.form.unloadTolerancePerc),callback:function ($$v) {_vm.$set(_vm.form, "unloadTolerancePerc", $$v)},expression:"form.unloadTolerancePerc"}}):(_vm.form.measuredToleranceType === 'PRODUTOR')?_c('masked-text-field',{attrs:{"label":"Tolerância por Produtor (Litros)","mask":{ mask: Number, min: 0 },"unmask":"","disabled":!_vm.form.unloadTolerance},model:{value:(_vm.form.collectToleranceLiters),callback:function ($$v) {_vm.$set(_vm.form, "collectToleranceLiters", $$v)},expression:"form.collectToleranceLiters"}}):_vm._e()],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Considerar Sobras na Tolerância","items":[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ],"disabled":!_vm.form.unloadTolerance},model:{value:(_vm.form.includeLeftovers),callback:function ($$v) {_vm.$set(_vm.form, "includeLeftovers", $$v)},expression:"form.includeLeftovers"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Tipo de Tolerância *","items":[
                { text: 'Percentual Geral', value: 'PERCENTUAL' },
                { text: 'Passe/Falta', value: 'PASS_OR_LACK' },
              ],"disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.toleranceType),callback:function ($$v) {_vm.$set(_vm.form, "toleranceType", $$v)},expression:"form.toleranceType"}})],1),(_vm.form.toleranceType === 'PASS_OR_LACK')?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('masked-text-field',{attrs:{"label":`Tolerância Passe/Falta ${!_vm.form.unloadTolerance ? '*' : ''}`,"sufix":"L","mask":{ mask: Number, min: 0 },"unmask":"","disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.passOrLackTolerance),callback:function ($$v) {_vm.$set(_vm.form, "passOrLackTolerance", $$v)},expression:"form.passOrLackTolerance"}})],1):_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('masked-text-field',{attrs:{"label":`Tolerância Geral ${!_vm.form.unloadTolerance ? '*' : ''}`,"prefix":"%","mask":{ mask: Number, min: 0, max: 100 },"unmask":"","disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.percentualTolerance),callback:function ($$v) {_vm.$set(_vm.form, "percentualTolerance", $$v)},expression:"form.percentualTolerance"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Tipo Preço Litro do Leite para Cálculo de Descontos","items":[
                { text: 'Média Geral', value: 'MEDIA_GERAL' },
                { text: 'Média Rota(s)', value: 'MEDIA_ROTA' },
                { text: 'Preço Fixo', value: 'PRECO_FIXO' },
              ]},model:{value:(_vm.form.milkPriceType),callback:function ($$v) {_vm.$set(_vm.form, "milkPriceType", $$v)},expression:"form.milkPriceType"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.booleanRules,"label":"Ignorar Custo do Frete no Cálculo de Descontos"},model:{value:(_vm.form.ignoreFreightCost),callback:function ($$v) {_vm.$set(_vm.form, "ignoreFreightCost", $$v)},expression:"form.ignoreFreightCost"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('money-input',{attrs:{"label":"Preço Litro do Leite","precision":4,"prefix":"R$","disabled":_vm.form.milkPriceType !== 'PRECO_FIXO',"rules":_vm.form.milkPriceType === 'PRECO_FIXO' ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.milkPriceFixed),callback:function ($$v) {_vm.$set(_vm.form, "milkPriceFixed", $$v)},expression:"form.milkPriceFixed"}})],1),_c('v-col',{staticClass:"pb-0 text-h5 secondary--text",attrs:{"cols":"12"}},[_vm._v(" Parâmetros Gerais ")]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.booleanRules,"label":"Considerar Máx. da Faixa Anterior como Frete Mínimo"},model:{value:(_vm.form.respectMinimumFreight),callback:function ($$v) {_vm.$set(_vm.form, "respectMinimumFreight", $$v)},expression:"form.respectMinimumFreight"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":[
                { text: 'Ativo (Sem Volume)', value: 'SEM_VOLUME' },
                { text: 'Ativo (Com Volume)', value: 'COM_VOLUME' },
                { text: 'Inativo', value: null },
              ],"label":"Incluir Itinerários Não Descarregados (Ex: Transferências)","persistent-hint":""},model:{value:(_vm.form.includeNotUnloadeds),callback:function ($$v) {_vm.$set(_vm.form, "includeNotUnloadeds", $$v)},expression:"form.includeNotUnloadeds"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Tipo de Volume para Pagamento *","items":[
                { text: 'Vale', value: 'VALE' },
                { text: 'Medido', value: 'MEDIDO' },
              ]},model:{value:(_vm.form.volumeType),callback:function ($$v) {_vm.$set(_vm.form, "volumeType", $$v)},expression:"form.volumeType"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"black--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" cancelar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"blue--text",attrs:{"outlined":"","loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.saving,"absolute":""}},[_c('v-card-text',[_vm._v(" Salvando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }