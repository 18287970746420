<template>
  <div>
    <v-card
      class="graph-card"
      color="transparent"
      dark
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="mb-3">
        <v-chart ref="chart" :options="generateGraphOptions()" :style="{ height: `500px !important` }" autoresize />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import printJS from "print-js";
import _ from "lodash";

export default {
  name: "exam-graphic",

  props: {
    title: String,
    type: String,
    data: {
      type: Array,
      deep: true
    },
    dateRange: {
      type: Array,
      deep: true
    }
  },

  data() {
    return {
      items: [],
    };
  },

  created() {
    this.loadExams();
  },

  watch: {
    data: {
      deep: true,
      handler: function() {
        this.loadExams();
      }
    }
  },

  methods: {
    async loadExams() {
      this.items = this.groupByYearMonth(this.data);
    },

    generateGraphOptions() {
      if (_.isEmpty(this.items)) {
        return;
      }

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(37, 251, 39, 0.8)', 'rgba(140, 23, 23, 1)', 'rgba(251, 84, 37, 0.8)'],
        title: {
          text: `Produtores`,
          left: "center",
          textStyle: { color: '#ddd' },
          subtextStyle: { fontSize: 15, color: '#ddd' },
          padding: [0, 0, 0, 50],
        },
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: { fontSize: 12 },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: { title: "", backgroundColor: "#0A2F54" },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {
                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({ printable: image, type: 'image', style: '@page { size: Letter landscape; }' });
              }
            },
          },
          iconStyle: { borderColor: "#F3F3F3" },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: Object.keys(this.items).map(o => this.formatMonth(o)),
          axisLabel: { color: '#fff' }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: { color: ['rgba(255, 255, 255, 0.1)'] }
          },
          axisLabel: { color: '#fff' }
        },
        series: [
          {
            name: 'Sem Vacina',
            type: 'bar',
            showSymbol: false,
            emphasis: { focus: 'series' },
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#6a7985',
              color: '#fff',
              borderRadius: 5,
              position: 'top',
              distance: 5,
              formatter: '  {c}  ',
            },
            data: Object.keys(this.items).map(o => this.items[o].notExam.length),
          },
          {
            name: 'Vencidos',
            type: 'bar',
            barGap: '-100%',
            data: Object.keys(this.items).map(o => this.items[o].expired.length),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
          {
            name: 'À Vencer (60 dias)',
            type: 'bar',
            barGap: '-100%',
            data: Object.keys(this.items).map(o => this.items[o].comingDue.length),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
          {
            name: 'Regular',
            type: 'bar',
            barGap: '-100%',
            data: Object.keys(this.items).map(o => this.items[o].regular.length),
            label: {
              show: true,
              lineHeight: 20,
              height: 20,
              backgroundColor: '#48535c',
              color: '#fff',
              borderRadius: 5,
              position: 'right',
            }
          },
        ]
      };
    },

    formatMonth: (value) => {
      // Remover espaços e garantir formato correto
      const formattedValue = moment(value.trim(), 'YYYY-MM', true); // true para validação estrita
      // Validar se a data é válida
      if (!formattedValue.isValid()) {
        console.error("Data inválida:", value);
        return "Data Inválida";
      }
      // Formatar corretamente
      return _.capitalize(formattedValue.format("MMM/YY"));
    },

    parseDate: (dateString) => {
      const [year, month, day] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day); // Mês em JS começa de 0 (Janeiro = 0)
    },

    groupByYearMonth(data) {
      const type = this.type;
      const [start, end] = this.dateRange;
      const startDate =  this.parseDate(start);
      const endDate =  this.parseDate(end);
      const today = new Date();
      const sixtyDaysLater = new Date();
      sixtyDaysLater.setDate(today.getDate() + 60);

      // Criar estrutura vazia para cada ano-mês dentro do período selecionado
      const acc = {};
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const yearMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
        acc[yearMonth] = {
          notExam: [],
          expired: [],
          comingDue: [],
          regular: []
        };

        // Avançar para o próximo mês corretamente
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      // Processar os dados
      data.forEach(item => {
        const exame = item[type]; // Acessa a propriedade dinamicamente
        const hasExame = exame && exame.data;
        const date = hasExame ? new Date(exame.data) : null;
        const expirationDate = hasExame && exame.data_vencimento ? new Date(exame.data_vencimento) : null;

        // Se o produtor tem exame, define seu ano-mês baseado na data do exame
        let yearMonth = hasExame
          ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
          : null;

        // Classificação dos produtores nos grupos adequados
        if (hasExame) {
          if (!expirationDate || expirationDate < today) {
            acc[yearMonth].expired.push(item);
          } else if (expirationDate > today && expirationDate <= sixtyDaysLater) {
            acc[yearMonth].comingDue.push(item);
          } else {
            acc[yearMonth].regular.push(item);
          }
        }
      });

      // Adicionar os produtores sem exame dentro do notExam de cada ano-mês do período
      data.forEach(item => {
        const exame = item[type]; // Acessa dinamicamente
        const hasExame = exame && exame.data;
        if (!hasExame) {
          // Distribuir nos meses do período selecionado
          Object.keys(acc).forEach(yearMonth => {
            acc[yearMonth].notExam.push(item);
          });
        }
      });

      return acc;
    },
  },
};
</script>
