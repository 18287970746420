<template>
  <div class="mx-5 px-6">

    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title class="mb-3">
            <v-col>
              <person-autocomplete-filter
                v-model="filter.technician"
                type="TECHNICAL"
                label="Técnico"
                dark
                @change="loadExams"
              />
            </v-col>
            <v-col>
              <routes-autocomplete-filter
                v-model="filter.routes"
                label="Rota"
                dark
                multiple
                @change="loadExams"
              />
            </v-col>
            <v-col>
              <month-picker
                v-if="tab === 'mensal'"
                v-model="filterDate.date.input"
                not-clearable
                disable-last-thirty
                @change="onMonthFilter"
              />
              <data-range-evolution-picker
                v-if="tab === 'geral'"
                v-model="filter.date.input"
                not-clearable
                @change="onDateFilter"
              />
            </v-col>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent"
        @change="onTabChange"
      >
        <v-tab href="#mensal">
          Mensal
        </v-tab>
        <v-tab href="#geral">
          Geral
        </v-tab>
        <v-tab-item value="mensal">
          <v-row>
            <v-col cols="12">
              <v-card
                dark
                color="transparent"
              >
                <exam-details
                  v-if="sanity === 'BRUCELOSE'"
                  title="Brucelose"
                  type="brucelose"
                  :data="data"
                />
                <exam-details
                  v-if="sanity === 'TUBERCULOSE'"
                  title="Tuberculose"
                  type="tuberculose"
                  :data="data"
                />
                <exam-details
                  v-if="sanity === 'VACINAS'"
                  title="Vacinas"
                  type="vacinas"
                  :data="data"
                />
                <exam-details
                  v-if="sanity === 'INVENTARIO'"
                  title="Inventário"
                  type="inventario"
                  :data="data"
                />
                <exam-graphic
                  v-if="sanity === 'BRUCELOSE'"
                  title="Brucelose"
                  type="brucelose"
                  :data="data"
                />
                <exam-graphic
                  v-if="sanity === 'TUBERCULOSE'"
                  title="Tuberculose"
                  type="tuberculose"
                  :data="data"
                />
                <exam-graphic
                  v-if="sanity === 'VACINAS'"
                  title="Vacinas"
                  type="vacinas"
                  :data="data"
                />
                <exam-graphic
                  v-if="sanity === 'INVENTARIO'"
                  title="Inventário"
                  type="inventario"
                  :data="data"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="geral">
          <v-row>
            <v-col cols="12">
              <v-card
                dark
                color="transparent"
              >
                <exam-graphic-general
                  v-if="sanity === 'BRUCELOSE'"
                  title="Brucelose"
                  type="brucelose"
                  :data="data"
                  :date-range="filterDate.range"
                />

                <exam-graphic-general
                  v-if="sanity === 'TUBERCULOSE'"
                  title="Tuberculose"
                  type="tuberculose"
                  :data="data"
                  :date-range="filterDate.range"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import ExamGraphic from "@/Domains/Projects/HealthExamination/Components/ExamGraphic.vue";
import ExamGraphicGeneral from "@/Domains/Projects/HealthExamination/Components/ExamGraphicGeneral.vue";
import ExamDetails from "@/Domains/Projects/HealthExamination/Components/ExamDetails.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import DataRangeEvolutionPicker from "@/Support/Components/DataRangeEvolutionPicker.vue";
import moment from "moment/moment";

export default {
  components: {
    ExamGraphic,
    ExamGraphicGeneral,
    ExamDetails,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker,
    DataRangeEvolutionPicker
  },

  props: {
    sanity: String,
  },

  data() {
    return {
      tab: "mensal",
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        date: {
          input: "",
          startDate: null,
          endDate: null,
        },
      },
      filterDate: {
        date: {
          input: "thisMonth",
        },
      },
      data: [],
      states: [],
    };
  },

  mounted() {
    this.getStates();
    this.loadExams();
  },
  methods: {
    onTabChange() {

      if (this.tab == 'geral') {
        this.filter.date.input = 'threeMonth';
        this.filterDate.range = [
          moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
          moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
        ];
        this.data = [];
      }

      this.loadExams();
    },

    onDateFilter(event) {
      this.filterDate.range = event;
      this.data = [];
      this.loadExams();
    },

    onMonthFilter(event) {
      this.filterDate.range = event;
      this.data = [];
      this.loadExams();
    },

    async loadExams() {
      try {
        this.$root.$progressBar.loading();

        let [startDate, endDate] = this.filterDate.range;

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            data_ini: startDate,
            data_fim: endDate,
          }});

        this.data = data;
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getStates() {
      try {
        const { data } = await this.$queries.get(`/estados/listaJson`);

        this.states = data.reduce((result, item) => {
          result[item.uf] = item.end_estado;
          return result;
        }, {});
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os estados!", "Atenção");
      }
    },
  }
};
</script>
