<template>
  <div ref="chart">
    <v-chart
      :options="option"
      :style="{width: '100%', height: `${height}px !important`}"
      autoresize
    />
  </div>
</template>

<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Gráfico',
    },
  },

  data() {
    return {
      height: 250,
      option: {
        color: ["#5793f3"],
        tooltip: {},
        title: {
          left: 10,
          top: 10,
          text: this.title,
          textStyle: {
            fontSize: 12,
            width: 200,
            overflow: 'break',
          }
        },
        toolbox: {
          top: 45,
          left: 10,
          feature: {
            saveAsImage: {
              title: '',
              backgroundColor: '#FFFF',
            }
          }
        },
        xAxis: {
          type: "category",
          data: this.category,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 68,
            color: 'black',
            fontSize: 8.6
          }
        },
        yAxis: {
          type: "value",
          max: function (value) {
            return value.max + 5;
          },
        },
        grid: {
          left: "13%",
          right: "3%",
          bottom: 55,
          top: 100,
        },
        series: this.series,
      },
    };
  },

  mounted() {
    const width = this.$refs.chart.offsetWidth;

    this.option.title.textStyle.width = width - 50;

    if (this.category.some((item) => item?.length > 15)) {
      this.option.grid.bottom = 100;
      this.height = 300;
    }

    if (this.category.some((item) => item?.length > 20)) {
      this.option.grid.bottom = 150;
      this.height = 350;
    }
  },
};
</script>
