<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col
        cols="12"
        class="text-xs-center"
      >
        <h2 class="menu-header white--text mt-0 mb-2">
          Entradas/Saídas de Matérias Primas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      dark
      class="transparent"
    >
      <v-card-title class="pb-0">
        <v-container>
          <v-row class="d-flex align-center justify-center">
            <v-col
              v-for="result in items"
              :key="result.rawMaterial.id"
              cols="12"
              md="2"
              lg="2"
              xl="2"
              xxl="2"
              class="pb-0"
            >
              <raw-material-info
                :title="result.rawMaterial.name"
                icon="account_balance_wallet"
                :raw-in="formatNumber(result.total['ENTRADA'])"
                :raw-out="formatNumber(result.total['SAIDA'])"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-date-range-picker
              v-model="filters.date.range"
              :ranges="filters.date.ranges"
              prepend-inner-icon="today"
              dark
              is-date-time
              label="Data"
              hide-details
              filled
              :max-days="30"
              @change="loadReport"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-select
              v-model="filters.inputOrOutput"
              :items="['ENTRADA', 'SAIDA']"
              prepend-inner-icon="reorder"
              dark
              label="Entrada/Saída"
              hide-details
              filled
              clearable
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-select
              v-model="filters.type"
              :items="types"
              prepend-inner-icon="local_shipping"
              dark
              label="Tipo"
              hide-details
              filled
              clearable
              @change="loadReport"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        light
        dark
        group-by="dairy.name"
      >
        <template #[`group.header`]="{group, isOpen, toggle}">
          <td
            class="text-start"
            :colspan="headers.length"
          >
            <v-btn
              icon
              @click="toggle"
            >
              <v-icon>
                {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
            </v-btn>
            {{ group }}
          </td>
        </template>

        <template #[`item.total`]="{ value }">
          <div class="d-flex flex-column align-center">
            <v-tooltip
              v-if="filters.inputOrOutput !== 'SAIDA'"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  color="blue darken-3"
                  small
                  pill
                  v-on="on"
                >
                  {{ formatNumber(value.ENTRADA) }}
                </v-chip>
              </template>
              ENTRADA
            </v-tooltip>
            <v-tooltip
              v-if="filters.inputOrOutput !== 'ENTRADA'"
              top
            >
              <template #activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  color="red darken-3"
                  small
                  pill
                  v-on="on"
                >
                  {{ formatNumber(value.SAIDA) }}
                </v-chip>
              </template>
              SAÍDA
            </v-tooltip>
          </div>
        </template>

        <template
          v-for="column in dateHeaders"
          #[`item.${column.value}`]="{ value }"
        >
          <div
            :key="column.value"
            class="d-flex flex-column"
          >
            <v-chip
              v-if="filters.inputOrOutput !== 'SAIDA'"
              pill
              small
            >
              <v-avatar
                left
                color="blue"
                class="white--text"
              >
                E
              </v-avatar>
              {{ value ? formatNumber(value.ENTRADA) : '-' }}
            </v-chip>
            <v-chip
              v-if="filters.inputOrOutput !== 'ENTRADA'"
              pill
              small
            >
              <v-avatar
                left
                color="red"
                class="white--text"
              >
                S
              </v-avatar>
              {{ value ? formatNumber(value.SAIDA) : '-' }}
            </v-chip>
          </div>
        </template>

        <template #[`body.append`]="{ headers }">
          <tr
            v-for="total in totals.filter(total => total.enabled)"
            :key="total.value"
          >
            <td
              v-for="(header,index) in headers"
              :key="index"
            >
              <span v-if="header.value ==='dairy.name'" />
              <b v-else-if="header.value ==='rawMaterial.name'">{{ total.text }}</b>
              <div
                v-else-if="header.value ==='total'"
                class="d-flex flex-column align-center"
              >
                <v-tooltip
                  v-if="filters.inputOrOutput !== 'SAIDA'"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      small
                      color="blue darken-4"
                      v-on="on"
                    >
                      {{ formatNumber(totalVol(total.value).ENTRADA) }}
                    </v-chip>
                  </template>
                  {{ total.value == 'TOTAL_LEITE' ? 'ENTRADA - LEITE CRU REFRIGERADO + LEITE FLUIDO GRANEL' : 'ENTRADA - TOTAL' }}
                </v-tooltip>
                <v-tooltip
                  v-if="filters.inputOrOutput !== 'ENTRADA'"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      small
                      color="red darken-4"
                      v-on="on"
                    >
                      {{ formatNumber(totalVol(total.value).SAIDA) }}
                    </v-chip>
                  </template>
                  {{ total.value == 'TOTAL_LEITE' ? 'SAÍDA - LEITE CRU REFRIGERADO + LEITE FLUIDO GRANEL' : 'SAÍDA - TOTAL' }}
                </v-tooltip>
              </div>

              <div
                v-else
                class="d-flex flex-column"
              >
                <v-chip
                  v-if="filters.inputOrOutput !== 'SAIDA'"
                  pill
                  small
                >
                  <v-avatar
                    left
                    color="blue"
                    class="white--text"
                  >
                    E
                  </v-avatar>
                  {{ formatNumber(totalsByDay(total.value)[header.value].ENTRADA) }}
                </v-chip>
                <v-chip
                  v-if="filters.inputOrOutput !== 'ENTRADA'"
                  pill
                  small
                >
                  <v-avatar
                    left
                    color="red"
                    class="white--text"
                  >
                    S
                  </v-avatar>
                  {{ formatNumber(totalsByDay(total.value)[header.value].SAIDA) }}
                </v-chip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment/moment";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import RawMaterialInfo from './Components/RawMaterialInfo.vue';

export default {
  components: {
    VDateRangePicker,
    RawMaterialInfo
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        inputOrOutput: null,
        type: null,
        date: {
          ranges: {
            'Este mês': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
            'Mês anterior': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
          },
          range: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        },
      },
      types: [
        { value: 'COLETA', text: 'Coleta' },
        { value: 'SPOT', text: 'Spot' },
        { value: 'TRANSFERENCIA', text: 'Transferência' }
      ],
      totals: [
        { value: 'TOTAL_LEITE', text: 'Total Leite', enabled: true },
        { value: 'TOTAL', text: 'Total', enabled: false },
      ],
      items: [],
      tipos_leite: []
    };
  },
  computed: {
    thisMonth() {
      const firstMonthDay = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastMonthDay = moment().format("YYYY-MM-DD");

      return [firstMonthDay, lastMonthDay];
    },

    headers() {
      return [
        { text: 'Laticínio', value: 'dairy.name', width: 250 },
        { text: 'Matéria Prima', value: 'rawMaterial.name', width: 250 },
        { text: "Total", value: "total", width: 120, align: "center" },
        ...this.dateHeaders
      ];
    },

    dateHeaders() {
      const [startDate, endDate] = this.filters.date.range;

      let header = [];
      let currentDate = moment(startDate).startOf('day');
      while (currentDate <= moment(endDate).endOf('day')) {
        header.push({
          text: currentDate.format('DD/MM'),
          value: currentDate.format('YYYY-MM-DD'),
          width: 110,
          align: "center",
          sortable: false
        });
        currentDate = moment(currentDate).add(1, 'days');
      }

      return header;
    },
  },

  async mounted() {
    await this.loadMilkTypeIds();
    await this.loadReport();
  },

  methods: {
    async loadReport() {
      try {
        this.loading = true;
        const [startDate, endDate] = this.filters.date.range;

        const { data } = await this.$axios.get(`/platform/reports/raw-material-volume`, { params: {
          date_start: startDate,
          date_end: endDate,
          water_discount: this.filters.waterDiscount,
          type: this.filters.type
        } });
        this.items = this.handleData(data);
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    handleData(response) {
      const fillDate = this.dateHeaders.reduce((acc, { value }) => ({ ...acc, [value]: { 'ENTRADA': '-', 'SAIDA': '-' } }), {});

      let  data = Object.keys(response).reduce((acc, cur) => {
        //Preenche os valores correspondente ao spot de cada dia
        const results = _.map(response[cur], res => {
          let total = { 'ENTRADA': 0, 'SAIDA': 0 };

          const items = Object.values(res);

          const [ first ] = items;

          const item = _.cloneDeep(fillDate);

          items.forEach(e => {
            _.set(item, `${e.data}.${e.tipo}`, e.volume);
            total[e.tipo] += e.volume;
          });

          item.dairy = {
            id: first.id_laticinio,
            name: first.nome_laticinio,
          };

          item.rawMaterial = {
            id: first.id_materia_prima,
            name: first.nome_materia_prima,
          };
          item.total = total;
          return item;
        });
        acc.push(...results)

        return acc;
      }, []);
      return data;
    },

    totalsByDay(type = 'TOTAL_LEITE') {
      const milkItems = type === 'TOTAL_LEITE'
        ? this.items.filter(item => this.tipos_leite.includes(item.rawMaterial.id))
        : this.items;

      return this.dateHeaders.reduce((acc, header) => {
        acc[header.value] = {
          'ENTRADA': _.sumBy(milkItems, values => parseFloat(_.get(values, `${header.value}.ENTRADA`)) || 0),
          'SAIDA': _.sumBy(milkItems, values => parseFloat(_.get(values, `${header.value}.SAIDA`)) || 0),
        };
        return acc;
      }, {});
    },

    totalVol(type = 'TOTAL_LEITE') {
      return Object.values(this.totalsByDay(type)).reduce((acc, item) => {
        acc.ENTRADA += Number(item.ENTRADA) || 0;
        acc.SAIDA += Number(item.SAIDA) || 0;
        return acc;
      }, { ENTRADA: 0, SAIDA: 0 });
    },

    getTitle() {
      const [startDate, endDate] = this.filters.date.range;
      return `Entradas e Saídas de Matérias Primas - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    exportExcel() {
      let data = this.XLSX.utils.json_to_sheet([]);

      const header = ['Matéria Prima', 'Total', ...this.dateHeaders.map(header => header.text)];

      const dairies = _.groupBy(this.items, "dairy.name");

      let currentRow = 1;
      for (const type of ['ENTRADA', 'SAIDA']) {
        if (!!this.filters.inputOrOutput && this.filters.inputOrOutput !== type) {
          continue;
        }

        new Map(Object.entries(dairies))
          .forEach((items, dairyName) => {

            const json = items
              .map(item => ({
                'Matéria Prima': item.rawMaterial.name,
                'Total': item.total[type],
                ...this.dateHeaders
                  .reduce((acc, header) => ({
                    ...acc, [header.text]: item[header.value][type]
                  }), {})
              }));

            data[`A${currentRow}`] = { v: `${dairyName} - ${type}` };

            this.XLSX.utils.sheet_add_json(data, json, {
              origin: currentRow,
              header,
            });

            currentRow += items.length + 2;
          });

        const totals = [{
          'Matéria Prima': `Total Leite - ${type}`,
          'Total': this.totalVol('TOTAL_LEITE')[type],
          ...this.dateHeaders
            .reduce((acc, header) => ({
              ...acc, [header.text]: this.totalsByDay('TOTAL_LEITE')[header.value][type]
            }), {})
        },
        ];

        this.XLSX.utils.sheet_add_json(data, totals, { origin: currentRow, header, skipHeader: true });

        currentRow += 3;
      }

      data['!cols'] = [
        { wch: 45 },
        { wch: 20 },
        { wch: 15 },
      ];

      const workbook = this.XLSX.utils.book_new();
      const filename = this.getTitle();
      this.XLSX.utils.book_append_sheet(workbook, data, _.truncate(filename, { length: 31 }));
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    async print() {
      const title = this.getTitle();

      if (this.items.length === 0) {
        return;
      }

      let body = [];

      for (const type of ['ENTRADA', 'SAIDA']) {
        if (!!this.filters.inputOrOutput && this.filters.inputOrOutput !== type) {
          continue;
        }

        const headers = this.headers
          .filter(header => header.value !== 'dairy.name')
          .map((header) => ({
            key: header.value === 'rawMaterial.name' ? 'rawMaterial' : header.value,
            label: header.text,
            class: 'text-left',
            attributes: {
              style: header.width ? `width: ${header.width}px;` : ''
            }
          }));

        const totals = [{
          'Matéria Prima': `Total Leite - ${type}`,
          'Total': this.totalVol('TOTAL_LEITE')[type],
          ...this.dateHeaders
            .reduce((acc, header) => ({
              ...acc, [header.text]: this.totalsByDay('TOTAL_LEITE')[header.value][type]
            }), {})
        },
        ];

        const dairies = _.groupBy(this.items, "dairy.name");

        new Map(Object.entries(dairies))
          .forEach((items, dairyName) => {
            body.push({
              tag: 'section',
              class: 'row',
              children: [{
                tag: 'datatable',
                title: `${dairyName} - ${type}`,
                headers,
                items: items.map(item =>
                  _.reduce(item, (acc, cur, key) => {
                    acc[key] = ['dairy', 'rawMaterial'].includes(key) ? cur.name : cur[type];
                    return acc;
                  }, {})),
              }]
            });
          });

        body.push({
          tag: 'section',
          class: 'row',
          children: [
            {
              tag: 'div',
              contents: '&nbsp;'
            },
            {
              tag: 'datatable',
              headers,
              items: totals,
            },
          ]
        });
      }

      const header = [{
        tag: 'div',
        class: 'row',
        children: [{
          tag: 'div',
          class: 'text-right',
          contents: '<b>Data/Hora Impressão: </b>' + moment().format('DD/MM/YYYY HH:mm:ss'),
        }]
      }];

      await this.printPdf({ pages: [{ title, header, body }], landscape: true });
    },

    async loadMilkTypeIds() {
      try {
        const { data } = await this.$axios.get('/item', {
          params: {
            tipo_materia_prima: 'MATERIA_PRIMA',

          }
        });

        this.tipos_leite = data.map(item => item.id_item);
      } catch (error) {
        this.$snotify.error('Erro ao carregar IDs do leite');
        this.tipos_leite = [];
      }
    },

    formatNumber: (value) => value !== '-' ? new Intl.NumberFormat('pt-BR').format(value) : '-',

  },
};
</script>
