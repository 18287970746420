<template>
  <v-dialog
    v-model="show"
    scrollable
    width="1200"
    persistent
  >
    <v-card>
      <v-card-title>
        Natureza de Operação
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
              class="pb-0"
            >
              <v-text-field
                v-model="form.descricao"
                label="Descrição *"
                :rules="[v => !!v || 'Campo obrigatório!']"
                prepend-inner-icon="subtitles"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="pb-0"
            >
              <v-select
                v-model="form.tipo"
                :items="[
                  { text: 'Entrada', value: 'ENTRADA' },
                  { text: 'Saída', value: 'SAIDA' },
                ]"
                label="Tipo *"
                prepend-inner-icon="import_export"
                :disabled="!!defaultType"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="pb-0"
            >
              <v-select
                v-model="form.padrao"
                :items="defaults"
                label="Padrão"
                clearable
                prepend-inner-icon="import_export"
                :disabled="!!defaultType"
              />
            </v-col>
            <v-col
              v-if="!isNfpe"
              cols="12"
              sm="6"
              md="3"
              class="pb-0"
            >
              <masked-text-field
                v-model="form.serie"
                label="Série *"
                placeholder="1"
                :mask="masks.integer"
                unmask
                prepend-inner-icon="tag"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="pb-0"
            >
              <v-select
                v-model="form.consumidor_final"
                :items="[
                  { text: 'Sim', value: true },
                  { text: 'Não', value: false },
                ]"
                label="Consumidor Final"
                prepend-inner-icon="person"
              />
            </v-col>
            <template>
              <v-col
                v-if="form.padrao === 'CONTRA_NOTA_PRODUTOR'"
                cols="12"
                sm="6"
                md="3"
                class="pb-0"
              >
                <v-select
                  v-model="form.nota_produtor_obrigatorio"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Nota de Produtor obrigatório"
                  prepend-inner-icon="request_page"
                />
              </v-col>
              <v-col
                v-if="form.padrao === 'CONTRA_NOTA_PRODUTOR' || isNfpe"
                cols="12"
                sm="6"
                md="3"
                class="pb-0"
              >
                <v-select
                  v-model="form.data_emissao_mes_referencia"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Emissão com data do mês de referência"
                  prepend-inner-icon="event"
                />
              </v-col>
              <v-col
                v-if="form.padrao === 'CONTRA_NOTA_PRODUTOR'"
                cols="12"
                sm="6"
                md="3"
                class="pb-0"
              >
                <v-select
                  v-model="form.incluir_descontos_fechamento"
                  :items="[
                    { text: 'Sim', value: true },
                    { text: 'Não', value: false },
                  ]"
                  label="Incluir descontos de fechamento"
                  prepend-inner-icon="paid"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md
                class="pb-0"
              >
                <v-select
                  v-model="form.modalidade_frete"
                  :items="[
                    { text: '0 - Contratação do Frete por conta do Remetente (CIF)', value: 0 },
                    { text: '1 - Contratação do Frete por conta do destinatário/remetente (FOB)', value: 1 },
                    { text: '2 - Contratação do Frete por conta de terceiros', value: 2 },
                    { text: '3 - Transporte próprio por conta do remetente', value: 3 },
                    { text: '4 - Transporte próprio por conta do destinatário', value: 4 },
                    { text: '9 - Sem Ocorrência de transporte', value: 9 },
                  ]"
                  label="Modalidade do frete"
                  prepend-inner-icon="local_shipping"
                />
              </v-col>
            </template>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-textarea
                v-model="form.informacoes_adicionais"
                label="Informações Adicionais"
                placeholder="Informações complementares"
                rows="2"
                auto-grow
                prepend-inner-icon="drive_file_rename_outline"
                hide-details
              />
              <div class="my-2">
                <span v-if="!showVariables">
                  Para exibir valores nas observações, utilize as <a @click.stop="showVariables = true">variáveis disponíveis</a>.
                </span>
                <span v-else>
                  Para exibir valores nas observações, utilize as variáveis [VALOR_BASE_ICMS], [VALOR_BASE_ICMS_ST], [VALOR_ICMS_ST], [PERC_OUTRAS_DESPESAS], [VALOR_OUTRAS_DESPESAS], [DATA_EMISSAO], [SERIE_NOTA_PRODUTOR], [NUMERO_NOTA_PRODUTOR] e [DATA_NOTA_PRODUTOR], incluindo colchetes.
                </span>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-tabs
          v-model="tab"
        >
          <v-tab href="#tab-icms">
            ICMS
          </v-tab>
          <v-tab href="#tab-ipi">
            IPI
          </v-tab>
          <v-tab href="#tab-pis">
            PIS
          </v-tab>
          <v-tab href="#tab-cofins">
            COFINS
          </v-tab>
          <v-tab
            v-if="false"
            href="#tab-issqn"
          >
            ISSQN
          </v-tab>
          <v-tab
            v-if="!isNfpe"
            href="#tab-funrural"
          >
            FUNRURAL
          </v-tab>
          <v-tab
            v-if="!isNfpe"
            href="#tab-outros"
          >
            OUTROS
          </v-tab>

          <v-tab-item
            eager
            value="tab-icms"
          >
            <v-form
              ref="icms"
              lazy-validation
              @submit.prevent="save()"
            >
              <icms-form
                :icms.sync="form.icms"
                :icms-uf-dest.sync="form.icms_uf_dest"
                :tax-regime="taxRegime"
                :final-consumer="form.consumidor_final"
                :in-out="form.tipo"
              />
            </v-form>

            <div class="d-flex">
              <span class="flex-grow-1 text-h5 mt-4 black--text">Exceções do ICMS</span>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="blue"
                  class="my-2"
                  @click="addIcms()"
                >
                  <v-icon>add</v-icon> Adicionar Exceção
                </v-btn>
              </div>
            </div>

            <v-divider class="mb-4" />

            <v-data-table
              v-show="form.icms_excecoes.length > 0"
              :headers="getHeadersTax('icms')"
              :items="form.icms_excecoes"
              light
              hide-default-footer
              disable-pagination
              class="mt-4"
            >
              <template #[`item.estados`]="{ item }">
                <v-chip
                  v-for="uf of item.estados"
                  :key="uf"
                  class="ma-1"
                >
                  {{ uf }}
                </v-chip>
                <span v-if="item.estados.length === 0">Todos</span>
              </template>
              <template #[`item.produtos`]="{ item }">
                <v-chip
                  v-for="produto of item.produtos"
                  :key="produto"
                  class="ma-1"
                >
                  {{ produtos[produto] || '' }}
                </v-chip>
                <v-chip
                  v-for="ncm of item.ncms"
                  :key="ncm"
                  class="ma-1"
                >
                  {{ applyMask(ncm, masks.ncm) }}
                </v-chip>
                <span v-if="item.produtos.length === 0 && item.ncms.length === 0">Todos</span>
              </template>
              <template #[`item.action`]="{ index }">
                <div class="d-flex">
                  <v-btn
                    icon
                    class="mr-2"
                    @click.stop="editIcms(index)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="removeIcms(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item
            eager
            value="tab-ipi"
          >
            <v-form
              ref="ipi"
              lazy-validation
              @submit.prevent="save()"
            >
              <ipi-form
                :data.sync="form.ipi"
                :in-out="form.tipo"
              />
            </v-form>

            <div class="d-flex">
              <span class="flex-grow-1 text-h5 mt-4 black--text">Exceções do IPI</span>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="blue"
                  class="my-2"
                  @click="addIpi()"
                >
                  <v-icon>add</v-icon> Adicionar Exceção
                </v-btn>
              </div>
            </div>

            <v-divider class="mb-4" />

            <v-data-table
              v-show="form.ipi_excecoes.length > 0"
              :headers="getHeadersTax('ipi')"
              :items="form.ipi_excecoes"
              light
              hide-default-footer
              disable-pagination
              class="mt-4"
            >
              <template #[`item.estados`]="{ item }">
                <v-chip
                  v-for="uf of item.estados"
                  :key="uf"
                  class="ma-1"
                >
                  {{ uf }}
                </v-chip>
                <span v-if="item.estados.length === 0">Todos</span>
              </template>
              <template #[`item.produtos`]="{ item }">
                <v-chip
                  v-for="produto of item.produtos"
                  :key="produto"
                  class="ma-1"
                >
                  {{ produtos[produto] || '' }}
                </v-chip>
                <v-chip
                  v-for="ncm of item.ncms"
                  :key="ncm"
                  class="ma-1"
                >
                  {{ applyMask(ncm, masks.ncm) }}
                </v-chip>
                <span v-if="item.produtos.length === 0 && item.ncms.length === 0">Todos</span>
              </template>
              <template #[`item.ipi.aliquota`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.action`]="{ index }">
                <div class="d-flex">
                  <v-btn
                    icon
                    class="mr-2"
                    @click.stop="editIpi(index)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="removeIpi(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item
            eager
            value="tab-pis"
          >
            <v-form
              ref="pis"
              lazy-validation
              @submit.prevent="save()"
            >
              <pis-cofins-form
                :data.sync="form.pis"
              />
            </v-form>

            <div class="d-flex">
              <span class="flex-grow-1 text-h5 mt-4 black--text">Exceções do PIS</span>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="blue"
                  class="my-2"
                  @click="addPis()"
                >
                  <v-icon>add</v-icon> Adicionar Exceção
                </v-btn>
              </div>
            </div>

            <v-divider class="mb-4" />

            <v-data-table
              v-show="form.pis_excecoes.length > 0"
              :headers="getHeadersTax('pis')"
              :items="form.pis_excecoes"
              light
              hide-default-footer
              disable-pagination
              class="mt-4"
            >
              <template #[`item.estados`]="{ item }">
                <v-chip
                  v-for="uf of item.estados"
                  :key="uf"
                  class="ma-1"
                >
                  {{ uf }}
                </v-chip>
                <span v-if="item.estados.length === 0">Todos</span>
              </template>
              <template #[`item.produtos`]="{ item }">
                <v-chip
                  v-for="produto of item.produtos"
                  :key="produto"
                  class="ma-1"
                >
                  {{ produtos[produto] || '' }}
                </v-chip>
                <v-chip
                  v-for="ncm of item.ncms"
                  :key="ncm"
                  class="ma-1"
                >
                  {{ applyMask(ncm, masks.ncm) }}
                </v-chip>
                <span v-if="item.produtos.length === 0 && item.ncms.length === 0">Todos</span>
              </template>
              <template #[`item.pis.aliquota`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.action`]="{ index }">
                <div class="d-flex">
                  <v-btn
                    icon
                    class="mr-2"
                    @click.stop="editPis(index)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="removePis(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item
            eager
            value="tab-cofins"
          >
            <v-form
              ref="cofins"
              lazy-validation
              @submit.prevent="save()"
            >
              <pis-cofins-form
                :data.sync="form.cofins"
              />
            </v-form>

            <div class="d-flex">
              <span class="flex-grow-1 text-h5 mt-4 black--text">Exceções do COFINS</span>

              <div class="d-flex justify-end">
                <v-btn
                  outlined
                  color="blue"
                  class="my-2"
                  @click="addCofins()"
                >
                  <v-icon>add</v-icon> Adicionar Exceção
                </v-btn>
              </div>
            </div>

            <v-divider class="mb-4" />

            <v-data-table
              v-show="form.cofins_excecoes.length > 0"
              :headers="getHeadersTax('cofins')"
              :items="form.cofins_excecoes"
              light
              hide-default-footer
              disable-pagination
              class="mt-4"
            >
              <template #[`item.estados`]="{ item }">
                <v-chip
                  v-for="uf of item.estados"
                  :key="uf"
                  class="ma-1"
                >
                  {{ uf }}
                </v-chip>
                <span v-if="item.estados.length === 0">Todos</span>
              </template>
              <template #[`item.produtos`]="{ item }">
                <v-chip
                  v-for="produto of item.produtos"
                  :key="produto"
                  class="ma-1"
                >
                  {{ produtos[produto] || '' }}
                </v-chip>
                <v-chip
                  v-for="ncm of item.ncms"
                  :key="ncm"
                  class="ma-1"
                >
                  {{ applyMask(ncm, masks.ncm) }}
                </v-chip>
                <span v-if="item.produtos.length === 0 && item.ncms.length === 0">Todos</span>
              </template>
              <template #[`item.cofins.aliquota`]="{ value }">
                {{ formatNumber(value) }}
              </template>
              <template #[`item.action`]="{ index }">
                <div class="d-flex">
                  <v-btn
                    icon
                    class="mr-2"
                    @click.stop="editCofins(index)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="removeCofins(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item
            v-if="false"
            eager
            value="tab-issqn"
          >
            <v-form
              ref="issqn"
              lazy-validation
              @submit.prevent="save()"
            >
              <issqn-form
                :data.sync="form.issqn"
              />
            </v-form>
          </v-tab-item>

          <v-tab-item
            v-if="!isNfpe"
            eager
            value="tab-funrural"
          >
            <v-form
              ref="funrural"
              lazy-validation
              @submit.prevent="save()"
            >
              <funrural-form
                :data.sync="form.funrural"
              />
            </v-form>
          </v-tab-item>

          <v-tab-item
            v-if="!isNfpe"
            eager
            value="tab-outros"
          >
            <v-form
              ref="outros"
              lazy-validation
              @submit.prevent="save()"
            >
              <others-form
                :data.sync="form.outros"
              />
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <icms-dialog
      v-model="icmsDialog.show"
      :tax-regime="taxRegime"
      :final-consumer="form.consumidor_final"
      :data="icmsDialog.data"
      @save="onIcmsSaved"
    />

    <ipi-dialog
      v-model="ipiDialog.show"
      :in-out="form.tipo"
      :data="ipiDialog.data"
      @save="onIpiSaved"
    />

    <pis-cofins-dialog
      v-model="pisCofinsDialog.show"
      :type="pisCofinsDialog.type"
      :data="pisCofinsDialog.data"
      @save="onPisCofinsSaved"
    />

    <v-overlay
      :value="loading || saving"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

import IcmsForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IcmsForm.vue";
import IpiForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IpiForm.vue";
import PisCofinsForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/PisCofinsForm.vue";
import IssqnForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/IssqnForm.vue";
import FunruralForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/FunruralForm.vue";
import OthersForm from "@/Domains/Settings/Views/Fiscal/Components/Operation/Forms/OthersForm.vue";

import IcmsDialog from "@/Domains/Settings/Views/Fiscal/Components/Operation/IcmsDialog.vue";
import PisCofinsDialog from "@/Domains/Settings/Views/Fiscal/Components/Operation/PisCofinsDialog.vue";
import IpiDialog from "@/Domains/Settings/Views/Fiscal/Components/Operation/IpiDialog.vue";

import IMask from 'imask';

import { icms, icms_uf_dest, ipi, pis, cofins, issqn, funrural, outros } from '@/Domains/Settings/Views/Fiscal/Components/Operation/Services/FiscalOperationDefault.js'

export default {

  components: {
    MaskedTextField,
    IcmsForm,
    IpiForm,
    PisCofinsForm,
    IssqnForm,
    FunruralForm,
    OthersForm,
    IcmsDialog,
    PisCofinsDialog,
    IpiDialog,
  },

  props: {
    value: Boolean,
    editId: String,
    companyId: String,
    defaultType: String,
    taxRegimeId: {
      type: Number,
      default: 3,
      validator: (value) => [1, 2, 3].includes(value)
    }
  },

  data() {
    return {
      loading: false,
      saving: false,
      showVariables: false,
      tab: null,
      form: {
        descricao: null,
        padrao: null,
        serie: 1,
        tipo: 'SAIDA',
        consumidor_final: true,
        nota_produtor_obrigatorio: true,
        data_emissao_mes_referencia: false,
        incluir_descontos_fechamento: false,
        modalidade_frete: 9,
        informacoes_adicionais: null,
        icms: { ...icms },
        icms_uf_dest: { ...icms_uf_dest },
        ipi: { ...ipi },
        pis: { ...pis },
        cofins: { ...cofins },
        issqn: { ...issqn },
        funrural: { ...funrural },
        outros: { ...outros },
        icms_excecoes: [],
        ipi_excecoes: [],
        pis_excecoes: [],
        cofins_excecoes: [],
      },

      masks: {
        integer: { mask: Number, min: 0, scale: 0, signed: false },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
        ncm: { mask: '0000.00.00' },
      },

      icmsDialog: {
        show: false,
        data: {}
      },

      ipiDialog: {
        show: false,
        data: {}
      },

      pisCofinsDialog: {
        show: false,
        type: null,
        data: {}
      },

      produtos: {}
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isNfpe() {
      return this.defaultType === 'NFPE' || this.form.padrao === 'NFPE_PADRAO';
    },

    taxRegime() {
      if (this.isNfpe) {
        return 'NORMAL'
      }

      return this.taxRegimeId === 3 ? 'NORMAL' : 'SIMPLES'
    },

    defaults() {
      if (this.defaultType === 'NFPE') {
        return [
          { text: 'NFP-e', value: 'NFPE' },
        ]
      }

      if (this.form.tipo === 'ENTRADA') {
        return [
          { text: 'Compra', value: 'COMPRA' },
          { text: 'Contra Nota Produtor', value: 'CONTRA_NOTA_PRODUTOR' },
        ];
      }

      //SAIDA
      return [
        { text: 'Venda', value: 'VENDA' },
        { text: 'Devolução', value: 'DEVOLUCAO' },
        { text: 'Bonificação', value: 'BONIFICACAO' },
        { text: 'Remessa', value: 'REMESSA' },
        { divider: true },
        { text: 'NFP-e Padrão', value: 'NFPE_PADRAO' },
      ];
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.reset();

        if (this.editId) {
          this.load();
        }
      }
    }
  },

  created() {
    this.loadItems();
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/fiscal/operation/${this.editId}`);

        this.form = {
          descricao: data.descricao,
          padrao: data.padrao,
          serie: data.serie,
          tipo: data.tipo === 0 ? 'ENTRADA' : 'SAIDA',
          consumidor_final: data.consumidor_final,
          nota_produtor_obrigatorio: data.nota_produtor_obrigatorio,
          data_emissao_mes_referencia: data.data_emissao_mes_referencia,
          incluir_descontos_fechamento: data.incluir_descontos_fechamento,
          modalidade_frete: data.modalidade_frete,
          informacoes_adicionais: data.informacoes_adicionais,

          icms: {
            cfop: data.icms.cfop,
            tributacao: data.icms.tributacao,
            aliquota_credito: data.icms.aliquota_credito,
            modalidade: data.icms.modalidade,
            aliquota_reducao: data.icms.aliquota_reducao,
            aliquota: data.icms.aliquota,
            aliquota_diferimento: data.icms.aliquota_diferimento,
            aliquota_fcp: data.icms.aliquota_fcp,
            somar_ipi_na_base: data.icms.somar_ipi_na_base,
            // subtrair_fcp_no_st: data.icms.subtrair_fcp_no_st,

            // Campos para ICMS ST
            modalidade_st: data.icms.modalidade_st,
            aliquota_reducao_st: data.icms.aliquota_reducao_st,
            valor_pauta_st: data.icms.valor_pauta_st,
            aliquota_mva_st: data.icms.aliquota_mva_st,
            aliquota_st: data.icms.aliquota_st,
            aliquota_fcp_st: data.icms.aliquota_fcp_st,
            //somar_ipi_na_base_st: data.icms.somar_ipi_na_base_st,

            // Campos para ICMS Efetivo
            aliquota_reducao_efetivo: data.icms.aliquota_reducao_efetivo,
            aliquota_efetivo: data.icms.aliquota_efetivo,
          },

          icms_uf_dest: {
            aliquota_interestadual: data.icms_uf_dest.aliquota_interestadual,
            aliquota_interna: data.icms_uf_dest.aliquota_interna,
            aliquota_fcp: data.icms_uf_dest.aliquota_fcp,
            calculo_base_por_dentro: data.icms_uf_dest.calculo_base_por_dentro,
          },

          ipi: {
            tributacao: data.ipi.tributacao,
            aliquota: data.ipi.aliquota,
            codigo_enquadramento: data.ipi.codigo_enquadramento,
          },

          pis: {
            tributacao: data.pis.tributacao,
            aliquota: data.pis.aliquota,
            base: data.pis.base,
          },

          cofins: {
            tributacao: data.cofins.tributacao,
            aliquota: data.cofins.aliquota,
            base: data.cofins.base,
          },

          // issqn: {
          //   tributacao: data.issqn.tributacao,
          //   aliquota: data.issqn.aliquota,
          //   base: data.issqn.base,
          //   descontar_iss: data.issqn.descontar_iss,
          // },

          funrural: {
            aliquota: data.funrural.aliquota,
            descontar_do_total_faturado: data.funrural.descontar_do_total_faturado,
          },

          outros: {
            percentual_despesas: data.outros?.percentual_despesas,
            aliquota_fundesa: data.outros?.aliquota_fundesa,
            beneficio_fiscal: data.outros?.beneficio_fiscal,
          },

          icms_excecoes: data.icms_excecoes || [],
          ipi_excecoes: data.ipi_excecoes || [],
          pis_excecoes: data.pis_excecoes || [],
          cofins_excecoes: data.cofins_excecoes || [],
        };
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },

    async validate() {
      if (!await this.$refs['form'].validate()) {
        return false;
      }
      if (!await this.$refs['icms'].validate()) {
        this.tab = 'tab-icms';
        return false;
      }
      if (!await this.$refs['ipi'].validate()) {
        this.tab = 'tab-ipi';
        return false;
      }
      if (!await this.$refs['pis'].validate()) {
        this.tab = 'tab-pis';
        return false;
      }
      if (!await this.$refs['cofins'].validate()) {
        this.tab = 'tab-cofins';
        return false;
      }
      // if (!await this.$refs['issqn'].validate()) {
      //   this.tab = 'tab-issqn';
      //   return false;
      // }

      if (this.isNfpe) {
        return true;
      }

      if (!await this.$refs['funrural'].validate()) {
        this.tab = 'tab-funrural';
        return false;
      }
      if (!await this.$refs['outros'].validate()) {
        this.tab = 'tab-outros';
        return false;
      }

      return true;
    },

    async save() {
      try {
        if (!await this.validate()) {
          return;
        }

        this.saving = true;

        const payload = {
          id_empresa: this.companyId,
          descricao: this.form.descricao,
          padrao: this.form.padrao,
          serie: this.form.serie,
          tipo: this.form.tipo === 'ENTRADA' ? 0 : 1,
          consumidor_final: this.form.consumidor_final,
          nota_produtor_obrigatorio: this.form.nota_produtor_obrigatorio,
          data_emissao_mes_referencia: this.form.data_emissao_mes_referencia,
          incluir_descontos_fechamento: this.form.incluir_descontos_fechamento,
          modalidade_frete: this.form.modalidade_frete,
          informacoes_adicionais: this.form.informacoes_adicionais,

          icms: {
            cfop: this.form.icms.cfop,
            tributacao: this.form.icms.tributacao,
            aliquota_credito: this.form.icms.aliquota_credito,
            modalidade: this.form.icms.modalidade,
            aliquota_reducao: this.form.icms.aliquota_reducao,
            aliquota: this.form.icms.aliquota,
            aliquota_diferimento: this.form.icms.aliquota_diferimento,
            aliquota_fcp: this.form.icms.aliquota_fcp,
            somar_ipi_na_base: this.form.icms.somar_ipi_na_base,
            // subtrair_fcp_no_st: this.form.icms.subtrair_fcp_no_st,

            // Campos para ICMS ST
            modalidade_st: this.form.icms.modalidade_st,
            aliquota_reducao_st: this.form.icms.aliquota_reducao_st,
            valor_pauta_st: this.form.icms.valor_pauta_st,
            aliquota_mva_st: this.form.icms.aliquota_mva_st,
            aliquota_st: this.form.icms.aliquota_st,
            aliquota_fcp_st: this.form.icms.aliquota_fcp_st,
            //somar_ipi_na_base_st: this.form.icms.somar_ipi_na_base_st,

            // Campos para ICMS Efetivo
            aliquota_reducao_efetivo: this.form.icms.aliquota_reducao_efetivo,
            aliquota_efetivo: this.form.icms.aliquota_efetivo,
          },

          icms_uf_dest: {
            aliquota_interestadual: this.form.icms_uf_dest.aliquota_interestadual,
            aliquota_interna: this.form.icms_uf_dest.aliquota_interna,
            aliquota_fcp: this.form.icms_uf_dest.aliquota_fcp,
            calculo_base_por_dentro: this.form.icms_uf_dest.calculo_base_por_dentro,
          },

          ipi: {
            tributacao: this.form.ipi.tributacao,
            aliquota: this.form.ipi.aliquota,
            codigo_enquadramento: this.form.ipi.codigo_enquadramento,
          },

          pis: {
            tributacao: this.form.pis.tributacao,
            aliquota: this.form.pis.aliquota,
            base: this.form.pis.base,
          },

          cofins: {
            tributacao: this.form.cofins.tributacao,
            aliquota: this.form.cofins.aliquota,
            base: this.form.cofins.base,
          },

          // issqn: {
          //   tributacao: this.form.issqn.tributacao,
          //   aliquota: this.form.issqn.aliquota,
          //   base: this.form.issqn.base,
          //   descontar_iss: this.form.issqn.descontar_iss,
          // },

          funrural: {
            aliquota: this.form.funrural.aliquota,
            descontar_do_total_faturado: this.form.funrural.descontar_do_total_faturado,
          },

          outros: {
            percentual_despesas: this.form.outros.percentual_despesas,
            aliquota_fundesa: this.form.outros.aliquota_fundesa,
            beneficio_fiscal: this.form.outros.beneficio_fiscal,
          },

          icms_excecoes: this.form.icms_excecoes || [],
          ipi_excecoes: this.form.ipi_excecoes || [],
          pis_excecoes: this.form.pis_excecoes || [],
          cofins_excecoes: this.form.cofins_excecoes || [],
        }

        if (this.isNfpe) {
          payload.funrural = { ...funrural };
          payload.outros = { ...outros };
        }

        const data = this.editId ? await this.update(payload) : await this.store(payload);

        this.$snotify.success("Salvo com sucesso!", "Sucesso");
        this.show = false;
        this.$emit('save', data.id);
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async store(payload) {
      const { data } = await this.$axios.post(`/fiscal/operation`, payload);
      return data;
    },

    async update(payload) {
      const { data } = await this.$axios.put(`/fiscal/operation/${this.editId}`, payload);
      return data;
    },

    cancel() {
      this.show = false;
    },

    reset() {
      this.tab = null;
      this.$refs.form?.resetValidation();
      this.form = {
        descricao: null,
        serie: 1,
        tipo: 'SAIDA',
        consumidor_final: false,
        nota_produtor_obrigatorio: true,
        data_emissao_mes_referencia: false,
        incluir_descontos_fechamento: false,
        modalidade_frete: 9,
        informacoes_adicionais: null,
        icms: { ...icms },
        icms_uf_dest: { ...icms_uf_dest },
        ipi: { ...ipi },
        pis: { ...pis },
        cofins: { ...cofins },
        issqn: { ...issqn },
        funrural: { ...funrural },
        outros: { ...outros },
        icms_excecoes: [],
        ipi_excecoes: [],
        pis_excecoes: [],
        cofins_excecoes: [],
      };

      if (this.isNfpe) {
        this.form.padrao = this.defaultType
        this.form.tipo = 'SAIDA'
        this.form.descricao = 'Venda'
        this.form.serie = null
        this.form.consumidor_final = false
        this.form.nota_produtor_obrigatorio = false
      }
    },

    addIcms() {
      this.icmsDialog = {
        show: true,
        idx: this.form.icms_excecoes.length,
        data: {}
      };
    },

    editIcms(idx) {
      const data = this.form.icms_excecoes[idx];
      this.icmsDialog = {
        show: true,
        idx,
        data
      }
    },

    async removeIcms(idx) {
      if (!(await this.$root.$confirm('Remover exceção', 'Tem certeza que deseja remover esta exceção?', { color: 'red' }))) {
        return;
      }
      this.form.icms_excecoes.splice(idx, 1);
    },

    onIcmsSaved(data) {
      this.form.icms_excecoes.splice(this.icmsDialog.idx, 1, data);
    },

    addIpi() {
      this.ipiDialog = {
        show: true,
        idx: this.form.ipi_excecoes.length,
        data: {}
      };
    },

    editIpi(idx) {
      const data = this.form.ipi_excecoes[idx];
      this.ipiDialog = {
        show: true,
        idx,
        data
      }
    },

    async removeIpi(idx) {
      if (!(await this.$root.$confirm('Remover exceção', 'Tem certeza que deseja remover esta exceção?', { color: 'red' }))) {
        return;
      }
      this.form.ipi_excecoes.splice(idx, 1);
    },

    onIpiSaved(data) {
      this.form.ipi_excecoes.splice(this.ipiDialog.idx, 1, data);
    },

    addPis() {
      this.pisCofinsDialog = {
        show: true,
        type: 'PIS',
        idx: this.form.pis_excecoes.length,
        data: {}
      };
    },

    editPis(idx) {
      const data = this.form.pis_excecoes[idx];
      this.pisCofinsDialog = {
        show: true,
        type: 'PIS',
        idx,
        data
      }
    },

    async removePis(idx) {
      if (!(await this.$root.$confirm('Remover exceção', 'Tem certeza que deseja remover esta exceção?', { color: 'red' }))) {
        return;
      }
      this.form.pis_excecoes.splice(idx, 1);
    },

    addCofins() {
      this.pisCofinsDialog = {
        show: true,
        type: 'COFINS',
        idx: this.form.cofins_excecoes.length,
        data: {}
      };
    },

    editCofins(idx) {
      const data = this.form.cofins_excecoes[idx];
      this.pisCofinsDialog = {
        show: true,
        type: 'COFINS',
        idx,
        data
      }
    },

    async removeCofins(idx) {
      if (!(await this.$root.$confirm('Remover exceção', 'Tem certeza que deseja remover esta exceção?', { color: 'red' }))) {
        return;
      }
      this.form.cofins_excecoes.splice(idx, 1);
    },

    onPisCofinsSaved(data) {
      if (this.pisCofinsDialog.type === 'PIS') {
        this.form.pis_excecoes.splice(this.pisCofinsDialog.idx, 1, data);
      } else {
        this.form.cofins_excecoes.splice(this.pisCofinsDialog.idx, 1, data);
      }
    },

    getHeadersTax(tax) {
      const headers = [
        { text: 'Destino(s)', value: 'estados' },
        { text: 'Produto(s)', value: 'produtos' },
      ];

      if (tax === 'icms') {
        headers.push({ text: 'CFOP', value: 'icms.cfop' });
      }

      if (['ipi', 'pis', 'cofins'].includes(tax)) {
        headers.push({ text: 'Alíq. %', value: `${tax}.aliquota` });
      }

      if (['pis', 'cofins'].includes(tax)) {
        headers.push({ text: 'Base %', value: `${tax}.base` });
      }

      if (['icms', 'ipi', 'pis', 'cofins'].includes(tax)) {
        headers.push({ text: 'Situação Tributária', value: `${tax}.tributacao` });
      }

      headers.push({ text: '', value: `action` });

      return headers;
    },

    async loadItems() {
      const { data } = await this.$axios.get(`/item`, { params: {
        tipos: ['PRODUTO_ACABADO', 'MATERIA_PRIMA']
      } });

      this.produtos = data.reduce((acc, cur) => {
        acc[cur.id_item] = `${cur.cod_item} - ${cur.nome}`
        return acc;
      }, {});
    },

    applyMask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
    formatNumber: (value) => value ? new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value) : '-',
  }
}
</script>
