<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-value="id"
    item-text="description"
    :return-object="returnObject"
    :filter="() => true"
    hide-no-data
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { debounce, isString } from 'lodash';

import { default as $axios } from "@/Support/Resources/axios-instance.js";

const props = defineProps({
  value: {
    type: [String, Object, Array],
  },
  types: {
    type: Array,
    default: () => ['PRODUTO_ACABADO'],
  },
  itemTypes: {
    type: Array,
    default: () => [],
  },
  hideMeasurementDescription: {
    type: Boolean,
  },
  showOtherMeasurements: {
    type: Boolean,
  },
  returnObject: {
    type: Boolean,
  },
  clearOnSelection: {
    type: Boolean,
  },
});

const emit = defineEmits(['input', 'change']);

const input = ref(props.value);
const loading = ref(false);
const items = ref([]);
const search = ref('');

const { proxy: { $snotify } } = getCurrentInstance();

const queryProducts = debounce(async (searchValue) => {
  await fetchItems(searchValue);
}, 1000);

onBeforeUnmount(() => {
  queryProducts.cancel();
});

watch(
  () => props.value,
  (newVal) => {
    input.value = newVal;
  }
);

watch(search, (val) => {
  if (!val || (isString(input.value) && input.value === val)) {
    return;
  }

  return queryProducts(val);
});

async function fetchItems(searchValue) {
  try {
    if (!searchValue || items.value.find(item => item.description.includes(searchValue))) {
      return;
    }

    loading.value = true;

    const response = await $axios.get('/item', {
      params: {
        exibir_outras_unidades: props.showOtherMeasurements ? 1 : 0,
        tipos: props.types,
        tipos_item: props.itemTypes,
        busca: searchValue,
      },
    });

    items.value = response.data.map(parseProduct);
  } catch (error) {
    console.error(error);

    $snotify.error(
      "Oops, ocorreu um erro ao carregar os produtos!",
      "Atenção"
    );
  } finally {
    loading.value = false;
  }
}

async function loadProductById(id) {
  if (!id) return;
  try {
    const response = await proxy.$axios.get(`/item/${id}`);

    const product = parseProduct(response.data);

    items.value.push(product);

    return emit("input", product);
  } catch (error) {

    console.warn(error);
  }
}

function parseProduct(data) {
  return {
    id: data.id_item,
    itemId: data.id_item,

    measurementId: data.id_unidade_medida,
    measurement: data.unidade,
    measurementDescription: data.unidade_descricao,
    conversionFactor: parseFloat(data.fator_conversao) || 1,

    defaultMeasurementId: data.id_unidade_padrao,
    defaultMeasurement: data.unidade_padrao,
    defaultMeasurementDescription: data.unidade_padrao_descricao,

    name: data.nome.trim(),
    code: data.cod_item,
    sif: data.sif,
    sifDipoa: data.sif_dipoa,
    barcodes: data.codigos_barras,
    duncodes: data.codigos_dun,
    barcode: data.codigo_barras,
    defaultBarcode: data.cod_barras_padrao,
    description: `${data.cod_item} - ${data.nome.trim()}` + (!props.hideMeasurementDescription && data.unidade_descricao ? ` (${data.unidade_descricao})` : ''),
    type: data.tipo,
    requiresLot: data.controlar_lotes || false,
    manualLot: data.lote_manual || false,
    validateStock: data.validar_estoque,
    stockControl: data.controle_estoque,

    tareWeight1: parseFloat(data.peso_tara_1) || 0,
    grossWeight: parseFloat(data.peso_bruto) || 0,
    tareWeight2: parseFloat(data.peso_tara) || 0,
    fixedWeight: data.peso_fixo,

    price: data.valor ? parseFloat(data.valor) : null,

    group: {
      value: data.id_item_grupo,
      text: data.nome_item_grupo,
    },
  };
}

function onChange(event) {
  if (props.clearOnSelection) {
    input.value = null;
    items.value = [];

    emit("change", event);

    return emit("input", null);
  }

  // Emit both the v-model update and the change event.
  emit("input", event || {});
  emit("change", event);
}

// --- Lifecycle Hook ---
onMounted(async () => {
  if (!props.value) return;

  if (isString(props.value)) {
    await loadProductById(props.value);

    return;
  }

  if (!props.value?.name) return;

  return await fetchItems(props.value.name);
});
</script>
