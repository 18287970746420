import { computed, ref } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import api from '@/Domains/Shipment/Api/Shipment.js'

export function useShipment(form) {

  const { progressBar, notify } = useUtils()

  /**
   * TODO: Criar configuração no laticínio para esse parâmetro.
   * Esse parâmetro serve para bloquear a montagem dos pallets apenas com os lotes expedidos no dialog de Carregamento.
   * Está fixo no código, pois quem está usando no momento é apenas a Geslat, e a Expedição deles não possui integração com o módulo de Pedidos.
   */
  const onlyShipped = ref(false)

  const products = computed(() => {
    if (!form.shipment?.shippings) {
      return {}
    }

    const groupedProducts = form.shipment.shippings.reduce((acc, shipping) => {
      for (const product of shipping.products) {
        const group = product.item.id
        if (!(group in acc)) {
          acc[group] = {
            item: product.item,
            quantity: 0,
            shipped: 0,
            remaining: {
              quantity: 0,
              shipped: 0
            }
          }
        }

        acc[group].quantity += product.quantity
        acc[group].shipped += product.shipped
        acc[group].remaining.shipped += product.remaining.shipped
        acc[group].remaining.quantity += product.remaining.quantity
      }

      return acc
    }, {})

    return Object.values(groupedProducts)
  })

  const shippingOptions = computed(() => {
    if (!form.shipment.shippings) {
      return []
    }

    const options = []

    if (onlyShipped.value) {
      options.push({
        text: 'SEQUENCIALMENTE',
        value: 'SEQUENCIALMENTE',
      })
    }

    options.push(...form.shipment.shippings.map(shipment => ({
      text: shipment.code ? `${shipment.code} - ${shipment.customer.name}` : shipment.customer.name,
      value: shipment.id,
    })))

    return options
  })

  const findPacking = async (code) => {
    try {
      // Antes de buscar no servidor, verifica se o código já não foi adicionado em uma embalagem ou pallet
      const alreadyAddedPacking = form.shipment.shippings.flatMap(s => s.products.flatMap(p => p.remaining.packingCodes)).includes(code)
      const alreadyAddedPallet = form.shipment.shippings.flatMap(s => s.pendingPalletCodes).includes(code)

      if (alreadyAddedPacking) {
        return notify.warning('Embalagem já adicionada', 'Atenção')
      }

      if (alreadyAddedPallet) {
        return notify.warning('Pallet já adicionado', 'Atenção')
      }

      progressBar.loading()

      const response = await api.findStorage({ code })

      if (!response) {
        return notify.warning('Código não encontrado', 'Atenção')
      }

      const { type, data } = response

      // Substitui o code pois a busca pode ser via sscc, mas será mantido o original para manter a lógica atual
      code = data.codigo_barras

      if (type === 'PACKING') {
        if (!products.value.find(p => [data.id_unidade_medida, data.id_item].includes(p.item.id))) {
          return notify.warning('Código não encontrado', 'Atenção')
        }

        const product = getProduct(data.id_unidade_medida, data.quantidade, 'MEASUREMENT') || getProduct(data.id_item, data.quantidade)

        if (product) {
          applyStock(product, data)

          return 'SUCCESS'
        } else {
          return notify.warning('Quantidade indisponível', 'Atenção')
        }
      }
      else if (type === 'PALLET') {
        const items = Object.values(data.estoques.reduce((acc, cur) => {
          if (!acc[cur.id_item]) {
            acc[cur.id_item] = {
              id: cur.id_item,
              quantity: 0
            }
          }

          acc[cur.id_item].quantity += cur.quantidade
          return acc
        }, {}))

        // Busca a primeira entrega que contém todos os produtos do pallet
        const compatibleShipping = form.shipment.shippings.find(shipping => {
          // Se houver seleção de pedido, a expedição será feita no pedido selecionado
          if (form.shippingId !== shipping.id) {
            return false
          }

          for (const item of items) {
            const product = shipping.products.find(p => p.item.itemId === item.id)
            if (!product) {
              return false
            }

            const availableQuantity = (product.remaining.quantity - product.remaining.shipped) * product.item.conversionFactor
            if ((item.quantity > availableQuantity * product.item.conversionFactor) && onlyShipped.value) {
              return false
            }
          }

          return true
        })

        if (!compatibleShipping) {
          return notify.warning('Quantidade indisponível', 'Atenção')
        }

        for (const stock of data.estoques) {
          const product = compatibleShipping.products.find(p => p.item.itemId === stock.id_item)
          if (product) {
            applyStock(product, stock)
          }
        }

        compatibleShipping.pendingPalletCodes.push(data.codigo_barras)

        return 'SUCCESS'
      }
      else if (type === 'ERROR') {
        return notify.warning(data, 'Atenção')
      }

    } catch (err) {
      console.warn(err)
      const message = err?.response?.data?.message || 'Erro ao buscar embalagem'
      notify.error(message, 'Atenção')
    } finally {
      progressBar.hide()
    }
  }

  const applyStock = (product, data) => {
    product.remaining.packingCodes.push(data.codigo_barras)

    const lotNumber = data.estoque.numero_lote
    const packingQuantity = data.quantidade

    const lot = product.stocks.find(stock => stock.lotNumber === lotNumber)

    const stockQuantity = lot ? lot.quantity - lot.packingQuantity : 0
    const remainingQuantity = packingQuantity - stockQuantity

    if (lot && stockQuantity > 0) {
      lot.packingQuantity += Math.min(stockQuantity, packingQuantity)
    }

    if (remainingQuantity > 0) {
      if (data.estoque.tipo === 'LOTE') {
        product.remaining.lots.push({
          numero: lotNumber,
          quantidade: remainingQuantity
        });
      }

      product.remaining.quantityLot += remainingQuantity / product.item.conversionFactor
      product.remaining.shipped += remainingQuantity / product.item.conversionFactor
    }
  }

  const getProduct = (id, quantity, type = 'ITEM') => {
    if (!id) {
      return null
    }

    let shippings = form.shipment.shippings

    // Se houver seleção de pedido, a expedição será feita no pedido selecionado
    if (form.shippingId && form.shippingId !== 'SEQUENCIALMENTE') {
      shippings = shippings.filter(s => s.id === form.shippingId)
    }

    for (const shipping of shippings) {
      for (const p of shipping.products) {
        const availableQuantity = p.remaining.quantity - p.remaining.shipped
        if (type === 'MEASUREMENT') {
          if (p.item.measurementId === id && (((availableQuantity * p.item.conversionFactor) >= quantity) || !onlyShipped.value)) {
            return p
          }
        } else {
          if (p.item.itemId === id && ((availableQuantity >= quantity) || !onlyShipped.value)) {
            return p
          }
        }
      }
    }

    return null
  }

  return { onlyShipped, products, shippingOptions, findPacking }
}
