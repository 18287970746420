<template>
  <div class="mx-6">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filters.project"
              :items="projectList"
              :loading="loading"
              label="Vigência do Projeto"
              item-value="dateStart"
              item-text="text"
              return-object
              hide-details
              clearable
              dark
              filled
              :rules="[v => !!v || 'Campo obrigatório']"
              @change="loadQuestionnaires"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="filters.project.protocol"
              label="Protocolo"
              type="text"
              persistent-hint
              dark
              filled
              hide-selected
              hide-details
            />
          </v-col>
          <v-col
            cols="2"
          >
            <v-autocomplete
              v-model="filters.show"
              :items="[
                { text: 'Todos', value: 'all' },
                { text: 'Com Respostas', value: 'withAnswers' },
                { text: 'Sem Respostas', value: 'withoutAnswers' },
              ]"
              label="Exibir"
              placeholder=" "
              hide-details
              filled
              dark
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="5"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              append-icon="search"
              single-line
              hide-details
              clearable
              dark
              filled
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-date-range-picker
              v-model="filters.range"
              :ranges.sync="filters.ranges"
              :min="filters.min"
              :max="filters.max"
              dark
              @change="loadQuestionnaires"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-autocomplete
              v-model="filters.types"
              :items="types"
              label="Tipos"
              placeholder=" "
              multiple
              hide-details
              dark
              clearable
              filled
              small-chips
              @change="loadQuestionnaires"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="filters.prospected"
              label="Exibir prospectados"
              dark
              class="ma-1 pb-1"
              color="teal accent-3"
              hide-details
              @change="loadQuestionnaires"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="white--text "
          >
            *total de respostas considera somente as últimas respostas dos produtores ativos e suspensos
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :search="filters.search"
        :headers="[
          { text: 'Questionário', value: 'title' },
          { text: 'Versão', value: 'version' },
          { text: 'Protocolo/RQ', value: 'code' },
          { text: 'Tipos', value: 'types', width: 400, },
          { text: 'Status', value: 'status', width: 30, },
          { text: '', value: 'model', width: 30, align: 'end', sortable: false },
          { text: '', value: 'totalAnswered', width: 30, align: 'end', sortable: false },
          { text: '', value: 'actions', width: 30, align: 'end', sortable: false },
        ]"
        :items="filteredList"
        item-key="id"
        single-expand
        dark
        @click:row="handleClickRow"
      >
        <template #item.types="{ value }">
          <v-chip
            v-for="type in value"
            :key="type"
            x-small
          >
            {{ getTypeLabel(type) }}
          </v-chip>
        </template>

        <template #item.status="{ value }">
          <v-chip
            x-small
            :color="value === 'ATIVO' ? 'light-green darken-3' : 'red darken-3'"
          >
            {{ value }}
          </v-chip>
        </template>

        <template #item.model="{ item }">
          <v-tooltip
            v-if="item.status === 'ATIVO'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                class="pa-0 ma-0"
                v-on="on"
                @click.stop="printModel(item.id)"
              >
                <v-icon
                  color="orange lighten-3"
                  class="pa-0 ma-0"
                >
                  receipt_long
                </v-icon>
              </v-btn>
            </template>
            Modelo do questionário
          </v-tooltip>
        </template>

        <template #item.totalAnswered="{ item }">
          <div
            v-if="item.totalAnswered"
            class="d-flex d-inline-flex"
          >
            <span
              style="color:#00E676; font-size:20px; font-weight: bold;"
              class="mr-1"
            >
              {{ item.totalAnswered }}
            </span>
            <v-icon>
              rate_review
            </v-icon>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-menu
            v-if="resources.deleteQuestionnaire || resources.editQuestionnaire"
            bottom
            right
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="onChangeTab('aba-charts', item)"
              >
                <v-list-item-icon>
                  <v-icon>bar_chart</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Gráficos </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="onChangeTab('aba-reports', item)"
              >
                <v-list-item-icon>
                  <v-icon>assignment</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Relatórios </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="onChangeTab('aba-list-producer', item)"
              >
                <v-list-item-icon>
                  <v-icon>supervisor_account</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Respostas </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="onChangeTab('aba-list-answers-deleted', item)"
              >
                <v-list-item-icon>
                  <v-icon>supervisor_account</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Respostas Excluidas </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="chartExportExcel(item.id)"
              >
                <v-list-item-icon>
                  <v-icon>backup_table</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Excel </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'ATIVO' && resources.deleteQuestionnaire"
                @click.stop="openConfirmDialog(item.title, item.id)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Deletar Questionário</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'ATIVO' && resources.editQuestionnaire"
                @click.stop="openQuestionnaireEditon(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Editar Questionário
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'ATIVO' && item.totalAnswered > 0"
                @click.stop="exportPoints(item)"
              >
                <v-list-item-icon>
                  <v-icon>download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Download Pontuação
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="resources.pointsQuestionnaire"
                @click.stop="calculatePoints(item)"
              >
                <v-list-item-icon>
                  <v-icon>refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Calcular a Pontuação
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="resources.insertQuestionnaire"
        fab
        dark
        color="teal darken-1"
        @click="createQuestionnaire()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              note_add
            </v-icon>
          </template>
          Novo Questionário
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>

    <v-dialog
      v-model="confirmDeleteDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <span
            class="subtitle-1"
          >
            Deseja realmente excluir o questionário <span class="font-weight-medium">"{{ questionnaireAction.name }}"</span>?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pa-1"
            color="blue-grey darken-1"
            dark
            text
            @click="confirmDeleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="pa-1"
            color="blue darken-2 "
            dark
            text
            @click="deleteQuestionnaire"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx-js-style";
import * as _ from "lodash";
import printJS from "print-js";
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {

  components: {
    VDateRangePicker,
  },

  props: {
    view: {
      type: String,
    },
    project: {
      type: String,
    },
  },

  data() {
    return {
      //projeto: 'PQFL',

      filters: {
        search: "",
        ranges: {
          'Este ano': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
        },
        range: [],
        min: moment().subtract(5, 'years').format('YYYY-MM-DD'),

        max: moment().endOf('year').format('YYYY-MM-DD'),

        show: "all",
        types: null,
        project: {
          start: null,
          end: null,
          protocol: '',
          id: '',
        },
        prospected: null,
      },

      projectList: [],
      questionnaires: [],

      expanded: [],

      loading: false,

      confirmDeleteDialog: false,

      questionnaireAction: {
        name: null,
        id: null,
        disabledEdition: false,
      },

      nameDelete: null,

      questionnaireIdDelete: null,

      //Permissões de recursos
      resources: {
        insertQuestionnaire: true,
        deleteQuestionnaire: true,
        editQuestionnaire: true,
        pointsQuestionnaire: false,
      },

      actionMenu: false,

      questionnairesAnswered: [],

      abcGoals: {
        show: false,
        id: null,
      },

      types: [
        // Metas de laticinio - Visita Padrão (Tipo principal)
        { text: "Meta Laticinio", value: "VISITA_PADRAO", type: "visits" },
        // PQFL (Tipo principal)
        { text: "PQFL", value: "VISITA_PQFL", type: "visits" },
        // Subtipos de PQFL
        { text: "IN77", value: "VISITA_IN77", type: "visits" },
        { text: "IN59", value: "VISITA_IN59", type: "visits" },
        { text: "BPA", value: "VISITA_BPA", type: "visits" },
        { text: "CPP", value: "VISITA_CPP", type: "visits" },
        { text: "CCS", value: "VISITA_CCS", type: "visits" },
        { text: "Checklist Inicial", value: "VISITA_CHECKLIST_INICIAL", type: "visits" },
        { text: "Brucelose / Tuberculose", value: "VISITA_BRUCELOSE_TUBERCULOSE", type: "visits" },
        // PMLS (Tipo principal)
        { text: "PMLS", value: "VISITA_PMLS", type: "visits" },
      ],
    };
  },

  computed: {

    filteredList() {
      return this.questionnaires.filter(questionnaire => {
        return this.checkAnswers(questionnaire);
      })
    },

    typesMap() {
      return _.mapValues(_.keyBy(this.types, 'value'), 'text');
    }
  },

  watch: {
    confirmDeleteDialog() {
      if (!this.confirmDeleteDialog) {
        this.questionnaireAction = {
          name: null,
          id: null
        }

      }
    },
  },

  async mounted() {
    if (this.project == 'PMLS') { //se for o menu de pmls filtrar somente esse tipo na listagem
      this.filters.types = ["VISITA_PMLS"];
    } else if (this.project == 'Dairy') {
      this.filters.types = ["VISITA_PADRAO"];
    } else if (this.project == 'PQFL') {
      this.filters.types = ["VISITA_PQFL", "VISITA_IN77", "VISITA_IN59", "VISITA_BPA", "VISITA_CPP", "VISITA_CCS", "VISITA_CHECKLIST_INICIAL", "VISITA_BRUCELOSE_TUBERCULOSE" ];
    }

    await this.loadProject();
    this.loadResources();

  },

  methods: {
    async loadProject() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: this.project ? this.project : "PQFL",
            view: this.view,
          }
        );

        this.projectList = data.map((proj) => {
          return {
            dateStart: proj.data,
            dateEnd: proj.data_fim,
            text: "De " + moment(proj.data).format('MM/YYYY') + " até " + moment(proj.data_fim).format('MM/YYYY'),
            protocol: proj.protocolo,
            id: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filters.project = this.projectList[0];
          this.filters.range = [moment(this.filters.project.dateStart).format('YYYY-MM-DD'), moment(this.filters.project.dateEnd).format('YYYY-MM-DD')];
        }

      } catch (error) {
        console.warn(error);
      } finally {
        await this.loadQuestionnaires();
        this.loading = false;
      }
    },
    async loadQuestionnaires() {
      try {
        this.loading = true;
        if (_.isEmpty(this.filters.types)) {
          return false;
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: _.isEmpty(this.filters.types) ? _.map(this.types, 'value') : this.filters.types,
            data_inicio: moment(this.filters.range[0]).format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
            nome_projeto: this.project,
            prospectado: this.filters.prospected,
            id_assistencia: this.filters.project.id,
          }
        );

        this.questionnaires = data.map((item) => {
          return {
            id: item.id,
            title: item.titulo,
            version: item.versao,
            code: item.codigo,
            totalAnswered: item.total_respostas,
            types: item.tipo,
            status: item.status,
          };
        });

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     * Método para altera a data do filtro
     */
    // onDateFilter(event) {
    //   this.filters.date.range = event;

    //   this.loadQuestionnaires();
    // },

    /**
     * @void
     * Impressão do modelo do questionário
     */
    async printModel(id) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/impressaoModeloQuestionario`,
          {
            id_formulario: id,
            tipo: this.view,
          }
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao imprimir o itinerario!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async exportPoints(item) {

      try {
        this.loading = true;
        if (item.id) {

          const { data } = await this.$axios.post(
            `/formularioCustomizado/listaPontosProdutoresFormulario`,
            {
              id_formulario: item.id,
            }
          );

          let producers = XLSX.utils.json_to_sheet(data.map((item) => {
            return {
              Produtor: item.nome_produtor,
              codigo: item.codigo_laticinio,
              pontos: Math.abs(item.pontos).toFixed(0),
              data: moment(item.data).format('DD/MM/YYYY'),
            };
          }));

          const workbook = XLSX.utils.book_new();
          const filename = "Pontuação_Produtores";
          XLSX.utils.book_append_sheet(workbook, producers, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    //regravar pontos nas respostas do questionario
    async calculatePoints(item) {
      try {
        this.loading = true;
        if (item.id) {

          const { data } = await this.$axios.post(
            `/formularioCustomizado/gravarPontuacao`,
            {
              id_formulario: item.id,
            }
          );

          if (!data || data.codigo != 1) {
            throw data;
          }
          this.$snotify.success("Pontos gravados nas respostas", "Sucesso");
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @void
     * Exporta informacao excel
     */
    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.questionnaires.map((item) => {
        return {
          Questionário: item.title,
        };
      }));
      const workbook = XLSX.utils.book_new();
      const filename = "Questionário";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Abre dialog de cadastro de questionário
     */
    createQuestionnaire() {
      this.$emit('newQuestionnaire');
    },

    openConfirmDialog(name, id) {
      this.questionnaireAction = {
        name,
        id
      };

      this.confirmDeleteDialog = true;
    },

    /**
     * Deleta questionário
     */
    async deleteQuestionnaire() {
      try {
        if (!this.questionnaireAction.id) {
          this.confirmDeleteDialog = false;
          throw "Identificador do formulário não encontrado";
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/deletaFormularioCustomziado`,
          this.$qs.stringify({
            id_formulario: this.questionnaireAction.id,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        if (!data.codigo) {
          this.$snotify.error("Oops, ocorreu um erro ao excluir o questionário!", "Atenção");
        }

        this.confirmDeleteDialog = false;
        this.$snotify.success("Sucesso, questionário excluido com sucesso !", "Sucesso");
        this.loadQuestionnaires();
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Trata o evento de click na linha da tabela
     */
    handleClickRow(questionnaire) {

      return this.onChangeTab("aba-edit-questionnaires", questionnaire);
    },

    async chartExportExcel(id) {
      try {
        this.loading = true;
        let { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasFormulario`,
          {
            id_formulario: id,
            exibe_todas_respostas: true,
            data_inicio: moment(this.filters.range[0]).format('YYYY-MM-DD') || moment().startOf('month').format("YYYY-MM-DD"),
            data_fim: moment(this.filters.range[1]).format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD'),
            tipo: this.project || null,
          }
        );

        if (!data) {
          data = [];
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          data = [];
        }

        const report = _(data).groupBy('id_formulario_customizado_cabecalho')
          .map((items) => ({
            Código: items[0].codigo_laticinio,
            Produtor: items[0].nome_produtor,
            Data: items[0].data_hora_registro,
            'Rota Principal': items[0].rota_principal,
            ...items.reduce((acc, cur) => ({
              ...acc,
              [cur.rotulo]: cur.resposta,
            }), {})
          })).value();

        let sheet = XLSX.utils.json_to_sheet(report);

        const workbook = XLSX.utils.book_new();
        const filename = "Questionários_Respondidos";
        XLSX.utils.book_append_sheet(workbook, sheet, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao exportar, tente escolher períodos menores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     *
     * Evento para abrir graficos de um determinado formulario
     */
    onChangeTab(tabName, value) {
      this.$emit("changeTab", {
        id: value.id,
        name: value.title,
        tab: tabName,
        dateStart: this.filters.project.dateStart || '', //data inicio do projeto
        dateEnd: this.filters.project.dateEnd || '',
        totalAnswered: value.totalAnswered,
      });
    },

    /**
     * Trata evento de fechamento do dialog do questionário
     */
    onCloseDialog() {
      this.loadQuestionnaires();
      this.questionnaireAction = {
        name: null,
        id: null
      };
    },

    /**
     * Carrega as permissões dos recursosa
     */
    loadResources() {
      const recursosUsuario = this.$store.state.settings.recursosUsuario;

      if (_.isEmpty(recursosUsuario)) {
        return true;
      }

      const insertQuestionnaire = recursosUsuario.find((recurso) => recurso.recurso === "questionnaire-insert" && recurso.tipo == "COMPONENTE");

      this.resources.insertQuestionnaire = !!insertQuestionnaire;

      const deleteQuestionnaire = recursosUsuario.find((recurso) => recurso.recurso === "questionnaire-delete" && recurso.tipo == "COMPONENTE");

      this.resources.deleteQuestionnaire = !!deleteQuestionnaire;

      const editQuestionnaire = recursosUsuario.find((recurso) => recurso.recurso === "questionnaire-edit" && recurso.tipo == "COMPONENTE");

      this.resources.editQuestionnaire = !!editQuestionnaire;

      const pointsQuestionnaire = recursosUsuario.find((recurso) => recurso.recurso === "questionnaire-calculate-points" && recurso.tipo == "COMPONENTE");

      this.resources.pointsQuestionnaire = !!pointsQuestionnaire;
    },

    /**
     * Abre  para edição
     */
    openQuestionnaireEditon(item) {
      this.handleClickRow(item);

    },

    openGoalsDialog(id) {
      this.abcGoals.id = id;
      this.abcGoals.show = true;
    },

    checkAnswers(questionnaire) {
      if (this.filters.show === 'withAnswers') {
        return questionnaire.totalAnswered > 0;
      }

      if (this.filters.show === 'withoutAnswers') {
        return !questionnaire.totalAnswered;
      }

      return true;
    },

    getTypeLabel(type) {
      return _.get(this.typesMap, type);
    },

  },
};
</script>
