<template>
  <div>
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="routes"
      item-text="description"
      item-value="id"
      prepend-inner-icon="icon-rota"
      :label="label"
      :dark="dark"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :filled="filled"
      clearable
      v-bind="$attrs"
      :multiple="multiple"
      :show-code-route="showCodeRoute"
      :filter="customFilter"
      @change="onChange"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            {{ showCodeRoute && item.code ? `${item.code} - ${item.description}` : item.description }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.types.length">
            <v-chip
              v-for="type in item.types"
              :key="type"
              x-small
            >
              {{ type }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip small>
          {{ showCodeRoute && item.code ? `${item.code} - ${item.description}` : item.description }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";

export default {

  props: {

    value: {
      type: [Object, Array, String],
    },

    type: {
      type: String,
      default: 'coleta',
      validator(value) {
        return ['coleta', 'comercial', 'visita'].indexOf(value) !== -1
      }
    },

    technician: {
      type: [Number, String, Object],
      default: null
    },

    label: {
      type: String,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: false
    },

    showCodeRoute: {
      type: Boolean,
      default: false
    }

  },

  data() {
    return {
      // Loader
      loading: false,

      // Model
      input: [],

      // Rotas (Autocomplete)
      routes: [],
    };
  },

  watch: {
    value() {
      this.input = this.value;
    },
    technician: {
      // immediate: true,
      handler() {
        this.input = [];
        this.loadRoutes();
      }
    }
  },

  async mounted() {
    await this.loadRoutes();
  },

  methods: {

    customFilter(item, queryText) {
      const text = this.showCodeRoute && item.code
        ? `${item.code} - ${item.description}`
        : item.description;

      return text.toLowerCase().includes(queryText.toLowerCase().trim());
    },

    async loadRoutes() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/rota/listaRotaResumidaJson`,
          {
            tipo: this.type,
            technician: this.technician
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.routes = data.map(route => {

          const types = !_.isEmpty(route.tipos) ? _.uniq(route.tipos.map(type => _.startCase(type.descricao))) : [];

          return {
            id: route.id_rota,
            description: route.descricao,
            types,
            rawMaterialId: route.id_materia_prima,
            code: route.codigo_rota,
          }
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar as rotas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {

      const newValue = event || {};

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change", newValue);
    },
  },
};
</script>
