<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Relatório de Inventário
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>
    <div class="pt-0">
      <v-row>
        <v-col cols="12">
          <v-card
            dark
            color="transparent"
          >
            <v-card-title>
              <v-spacer />
              <v-col
                cols="4"
                md-4
                sm-4
                class="pa-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  single-line
                  hide-details
                  dark
                  class="pt-0 my-2"
                />
              </v-col>
            </v-card-title>
            <data-table
              ref="report"
              :headers="headers"
              :items="search(items)"
              :search="filter.search"
              show-custom-group
              dark
              @click:row="edit"
            >

              <template #[`item.action`]="{ item }">
                <v-menu
                  bottom
                  right
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="remove(item)"
                    >
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="edit(item)">
                      <v-list-item-icon>
                        <v-icon>edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template #activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          color="blue"
          @click="add()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                add
              </v-icon>
            </template>
            Novo
          </v-tooltip>
        </v-btn>
        <v-btn
          fab
          dark
          color="green darken-1"
          @click="exportExcel()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>
            Download (Excel)
          </v-tooltip>
        </v-btn>
        <v-btn
          fab
          dark
          color="orange darken-1"
          @click="print()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                print
              </v-icon>
            </template>

            Imprimir
          </v-tooltip>
        </v-btn>
      </v-speed-dial>
      <add-inventory-dialog
        v-model="showDialog"
        :edit-id="editId"
        @save="loadInventories"
      />
    </div>
  </v-container>
</template>

<script>
import AddInventoryDialog from "@/Domains/Projects/HealthExamination/Components/AddInventoryDialog.vue";
import isEmpty from "lodash/isEmpty";
import { isNull } from "lodash";
import moment from "moment-timezone";

export default {

  components: {
    AddInventoryDialog,
  },

  data() {
    return {
      showDialog: false,
      editId: null,
      filter: {
        search: '',
      },
      animalTypesList: [],

      items: [],
      settings: [],
      headers: [],
    };
  },


  async mounted() {

    await this.getSettings();
    await this.loadAnimalTypes();
    await this.getHeaders();
    await this.loadInventories();
  },

  methods: {
    async loadInventories() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/projects/producer-inventory`);

        let total = 0;

        this.items = data.map(item => {
          total = 0;
          var fieldsMap = [];

          for (const key in item.inventario) {

            if(isEmpty(item.inventario[key])) {
              fieldsMap[key] = 0;
            } else {
              fieldsMap[key] = parseInt(item.inventario[key] ?? 0);
            }

            total = parseInt(total) + parseInt(fieldsMap[key]);
          }

          return {
            id: item.id,
            producer: item.producer ? item.producer : null,
            inventario: item.inventario ? item.inventario: '',
            nome_pessoa_registro: item.nome_pessoa_registro,
            data_hora_registro: this.formatDate(item.data_hora_registro, 'DD/MM/YYYY HH:mm:ss'),
            total: total,
            ... fieldsMap
          };
        });

      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.$root.$progressBar.hide();
      }
    },

    add() {
      this.editId = null;
      this.showDialog = true;
    },

    edit(item) {
      this.editId = item.id;
      this.showDialog = true;
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.loading();

        await this.$axios.delete(`/projects/producer-inventory/${id}`);
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir registro", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        await this.loadInventories();
      }
    },

    print() {
      this.$refs.report.print(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Inventários');
    },

    exportExcel() {
      this.$refs.report.exportExcel(this.$refs.report.getReportJson().map(({Opções, ...rest}) => rest), 'Inventários');
    },

    search(data) {
      return this.filter.search ? data.filter(item => JSON.stringify(Object.values(item)).toUpperCase().includes(this.filter.search.toUpperCase())) : data;
    },

    async loadAnimalTypes() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get('/projects/animals-type', { params: {
            deleted: true,
          } });

        this.animalTypesList = data.map(item => {
          return {
            id: item.id_tipo_animal,
            description: item.descricao,
          };
        });

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar tipos de animal!", "Atenção");

        console.warn(err);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatDate(value, format) {
      if(!value) {
        return '';
      } else {
        return moment(value).format(format);
      }
    },

    async getSettings() {
      try {
        this.$root.$progressBar.loading();
        let { data } = await this.$axios.get(`settings/general`);

        if (!_.isEmpty(data)) {
          this.settings = data;
        }

      } catch (e) {
        this.$snotify.error("Erro ao carregar configuracao", "Atenção");
        console.warn(e);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getHeaders() {
      const headerActions = [
        { text: '', value: '', align: 'end' },
        { text: 'Total', value: 'total', align: 'center' },
        { text: 'Usuário Atualização', value: 'nome_pessoa_registro', align: 'start' },
        { text: 'Data Atualização', value: 'data_hora_registro', align: 'start' },
        { text: 'Opções', value: 'action', width: 30, align: 'center' }
      ];

      const width = 150;

      const headersDynamic = this.animalTypesList.map(function(field) {
        return { text: field.description, value: field.id, align: 'center', width: width};
      });


      let producerInfos = [
        { text: 'Código', value: 'producer.codigo_laticinio' },
        { text: 'Produtor', value: 'producer.nome' },
      ];

      if( this.settings.mostrar_cpf ?? false) {
        producerInfos.push({ text: 'CPF', value: 'producer.cnpj_cpf' });
      }

      if( this.settings.mostrar_telefone) {
        producerInfos.push({ text: 'Telefone', value: 'producer.telefone' });
      }

      if( this.settings.mostrar_cidade) {
        producerInfos.push({ text: 'Cidade', value: 'producer.end_cidade' });
      }

      if( this.settings.mostrar_regiao) {
        producerInfos.push({ text: 'Região', value: 'producer.regiao' });
      }

      let headers = [
        ... producerInfos,
        ... headersDynamic,
        ... headerActions
      ];

      this.headers =  headers;
    },
  },
};
</script>
