<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="60%"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ form.customer?.name || title }}</span>
          <v-spacer />
          <v-btn
            icon
            small
            depressed
            @click="close()"
          >
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="form.customer?.name">
          {{ title }}
        </v-card-subtitle>

        <v-card-text>
          <v-form
            v-if="dialog"
            ref="formRef"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-row>
              <v-col
                class="pt-0"
              >
                <person-autocomplete-filter
                  v-model="form.customer"
                  label="Cliente *"
                  type="CUSTOMER"
                  prepend-inner-icon="person_outline"
                  placeholder=" "
                  :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                  :hint="getCustomerHint()"
                  persistent-hint
                  :hide-details="false"
                  :filled="false"
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.code"
                  label="Código Pedido"
                  icon="subtitles"
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.invoiceNumber"
                  label="Nº Nota"
                  icon="subtitles"
                />
              </v-col>

              <v-col
                v-if="!hideDeliveryDate"
                cols="12"
                md="3"
                class="pt-0"
              >
                <date-time-text-field
                  v-model="form.dateTime"
                  label="Data Hora Entrega"
                  icon="access_time"
                  input-format="YYYY-MM-DD HH:mm"
                  manual
                />
              </v-col>

              <v-col
                cols="12"
                class="pt-0"
              >
                <v-textarea
                  v-model="form.notes"
                  label="Observação"
                  rows="2"
                  auto-grow
                />
              </v-col>
            </v-row>

            <v-tabs>
              <v-tab>
                Produtos
              </v-tab>

              <v-tab-item>
                <template
                  v-for="(item, idx) in form.products"
                >
                  <v-row
                    :key="idx"
                    style="border-bottom: 1px #eee solid;"
                  >
                    <v-col
                      class="py-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <div>
                        <v-icon>
                          reorder
                        </v-icon>
                        {{ idx + 1 }}
                      </div>
                    </v-col>
                    <v-col class="pt-1 pb-0">
                      <item-autocomplete-filter
                        :value="item.sellingItem"
                        label="Produto *"
                        prepend-inner-icon="inventory"
                        return-object
                        :item-types="['VENDA']"
                        show-other-measurements
                        :disabled="!!item.shipped"
                        :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                        @input="onItemChange($event, item)"
                      />
                    </v-col>

                    <v-col
                      cols="3"
                      class="pt-1 pb-0 d-flex align-center"
                    >
                      <masked-text-field
                        v-model="item.salesQuantity"
                        label="Quantidade Pedido: *"
                        prepend-inner-icon="subtitles"
                        unmask
                        :mask="masks.float"
                        inputmode="numeric"
                        :suffix="item.salesUnit"
                        :rules="[
                            v => !!v && item.salesQuantity > 0 || 'Campo obrigatório!',
                            () => !item.shipped || item.quantity >= item.shipped || 'Quantidade menor que a expedida!',
                        ]"
                        :hint="item.quantity > 0 ? `${formatNumber(item.quantity)} ${item.measurement}` : ''"
                        persistent-hint
                        validate-on-blur
                        @input="onQuantityChange($event, item)"
                      >
                      </masked-text-field>
                    </v-col>

                    <v-col
                      cols="3"
                      class="pt-1 pb-0 d-flex align-center"
                    >
                      <masked-text-field
                        :value="item.salesShipped"
                        label="Expedido: *"
                        prepend-inner-icon="subtitles"
                        :mask="masks.float"
                        inputmode="numeric"
                        :suffix="item.measurement"
                        disabled
                        unmask
                        validate-on-blur
                        :hint="item.shipped > 0 ? `${formatNumber(item.shipped || 0)} ${item.measurement}` : ''"
                        persistent-hint
                      >
                      </masked-text-field>
                    </v-col>

                    <v-col
                      class="px-0 d-flex flex-column justify-center"
                      style="max-width: 49px;"
                    >
                      <v-btn
                        icon
                        :disabled="item.shipped > 0"
                        @click="removeProduct(idx)"
                      >
                        <v-icon>
                          delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <div class="d-flex justify-end">
                  <v-btn
                    outlined
                    color="blue"
                    class="my-2"
                    @click="addProduct"
                  >
                    <v-icon>add</v-icon> Adicionar
                  </v-btn>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-form>
          <small>* Campo obrigatório</small>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'
import IMask from "imask";
import { v4 as uuidv4 } from 'uuid'

import PersonAutocompleteFilter from '@/Support/Components/Filters/PersonAutocompleteFilter.vue'
import ItemAutocompleteFilter from '@/Support/Components/Filters/ItemAutocompleteFilter.vue'
import DateTimeTextField from '@/Support/Components/DateTimeTextField.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import { useDialogHistory } from '@/Support/Composables/dialogHistory.js'

import { default as $axios } from "@/Support/Resources/axios-instance.js";

// eslint-disable-next-line no-undef
defineProps({
  hideDeliveryDate: Boolean
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const loading = ref(false);

const form = reactive({
  id: null,
  customer: {},
  code: null,
  invoiceNumber: null,
  dateTime: null,
  notes: null,
  products: [],
})

const title = computed(() => form.id ? 'Editando Entrega' : 'Nova Entrega')

const dialog = ref(false)

useDialogHistory(dialog)

const formRef = ref()

const show = (shipping) => {
  form.id = shipping.id
  form.customer = shipping.customer || {}
  form.code = shipping.code
  form.invoiceNumber = shipping.invoiceNumber
  form.dateTime = shipping.dateTime
  form.notes = shipping.notes
  if (shipping.products) {
    form.products = shipping.products;
  } else {
    form.products = [{ id: uuidv4(), item: {}, showWeight: false }]
  }

  dialog.value = true

  formRef.value?.reset()
}

const close = () => {
  form.id = null
  dialog.value = false
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  return emit('save', { ...form })
}

const removeProduct = (idx) => {
  form.products.splice(idx, 1);
}

const addProduct = () => {
  form.products.push({ id: uuidv4(), item: {}, showWeight: false })
}

const onItemChange = async (changedItem, item) => {
  const shipmentMeasureUnit = await fetchShipmentItem(changedItem.itemId);

  if (changedItem.itemId === item.item.itemId) {
    form.products = form.products.map(product => {
      if (product.id !== item.id) {
        return product;
      }

      return {
        ...product,
        item: changedItem,
      }
    });
  }

  form.products = form.products.map(product => {
    if (product.id !== item.id) {
      return product;
    }

    const salesConversionFactor = changedItem.conversionFactor || 1;
    const realQuantity = item.salesQuantity * salesConversionFactor;

    return {
      ...product,

      item: changedItem,

      salesQuantity: item.salesQuantity,
      salesUnitId: changedItem.measurementId,
      salesUnit: changedItem.measurement,
      salesUnitDescription: changedItem.measurementDescription,
      salesConversionFactor,

      realQuantity: realQuantity,
      realMeasurementId: changedItem.defaultMeasurementId,
      realMeasurement: changedItem.defaultMeasurement,
      realMeasurementDescription: changedItem.defaultMeasurementDescription,

      quantity: realQuantity / shipmentMeasureUnit.conversionFactor,
      measurementId: shipmentMeasureUnit.measurementId,
      measurement: shipmentMeasureUnit.measurement,
      measurementDescription: shipmentMeasureUnit.measurementDescription,
      conversionFactor: shipmentMeasureUnit.conversionFactor || 1,
    }
  });
}

const onQuantityChange = (inputAmount, item) => {
  form.products = form.products.map(product => {
    if (product.id !== item.id) {
      return product;
    }

    const realQuantity = inputAmount * product.salesConversionFactor; // Da unidade de venda, para a unidade de estoque

    return {
      ...product,
      salesQuantity: parseFloat(inputAmount),
      realQuantity,
      quantity: realQuantity / product.conversionFactor,
    }
  });
}

const fetchShipmentItem = async (productId) => {
  try {
    loading.value = true;

    const { data } = await $axios.get('/item', {
      params: {
        exibir_outras_unidades: 1,
        tipos_item: ['EXPEDICAO'],
        id_item: productId,
      },
    });

    const items = data.map((item) => {
      return {
        id: item.id_item,
        measurementId: item.id_unidade_medida,
        measurement: item.unidade,
        measurementDescription: item.unidade_descricao,
        defaultMeasurementId: item.id_unidade_padrao,
        defaultMeasurement: item.unidade_padrao,
        defaultMeasurementDescription: item.unidade_padrao_descricao,
        grossWeight: parseFloat(item.peso_bruto) || 0,
        conversionFactor: parseFloat(item.fator_conversao) || 1,
      };
    });

    return items[0];
  } catch (error) {

    console.error(error);
  } finally {
    loading.value = false;
  }
}

function getCustomerHint() {
  if (!this.form?.customer?.federalTaxNumber) {
    return '';
  }

  return `CNPJ: ${cnpjFormat(this.form?.customer?.federalTaxNumber)}`;
}

const formatNumber = val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val);

function cnpjFormat(cnpj) {
  if (!cnpj) {
    return '-';
  }

  const masked = IMask.createMask({
    mask: '00.000.000/0000-00',
  });

  masked.resolve(cnpj);

  return masked.value;
}

// eslint-disable-next-line
defineExpose({
  show,
  close
})
</script>
