<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-container>
      <v-card
        class="v-tamanho collecton-city"
        color="transparent"
        dark
      >
        <v-card-title>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <v-date-range-picker
              v-model="localDateFilter"
              label="Data"
              dark
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <person-autocomplete-filter
              v-model="localTechnicianFilter"
              label="Técnico"
              type="TECHNICIAN"
              :return-object="false"
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <person-autocomplete-filter
              v-model="localProducersFilter"
              label="Produtor"
              type="PRODUCER"
              multiple
              show-groupings
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <routes-autocomplete-filter
              v-model="localRoutesFilter"
              label="Rota"
              dark
              multiple
              @change="onFilter"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md
          >
            <masked-input
              v-model="filters.horas"
              :mask="{ mask: '00:00' }"
              :max-length="5"
              prepend-inner-icon="access_time"
              placeholder="Filtrar Horas (00:00) Coletas acima de:"
              filled
              hide-details
              @change="onTimeFormat"
            />
          </v-col>
        </v-card-title>
        <data-table
          ref="report"
          class="collecton-city-table"
          dark
          :items="relatorio"
          :search="filters.search"
          :headers="[
            { text:'Código', value:'codigo_produtor', align: 'start', width: '10%'},
            { text:'Produtor', value:'nome_produtor', width: '40%', align:'left'},
            { text:'Última Coleta (Hrs)', value:'ultima_coleta', width: '20%', align:'center'},
            { text:'Data Última Coleta', value:'data_ultima_coleta', width: '20%', align:'center'},
            { text:'Volume Coletado', value:'volume_coletado', width: '20%', align:'center'},
          ]"
          item-key="id_produtor"
          show-expand
          single-expand
        >
          <template #expanded-item="{ headers, item }">
            <td
              :colspan="7"
              class="pa-0 text-center"
            >
              <data-table
                :headers="[
                  { text:'Rota', value:'nome_rota', width: '20%', align:'center'},
                  { text:'Data', value:'data', width: '20%', align:'center'},
                  { text:'Coleta (Hrs)', value:'hora', width: '20%', align:'center'},
                  { text:'Volume Coletado', value:'quantidade_coleta', width: '40%', align: 'center'},
                ]"
                :items="item.details"
                :items-per-page="-1"
                dense
                hide-default-footer
                class="elevation-1 ma-3 text-center"
              />
            </td>
          </template>
          <template #[`footer.prepend`]>
            <div
              v-if="producersAbove48Hours.length"
              class="d-flex text-caption text-left ml-4"
            >
              <div>
                Produtores acima de 48 horas |
              </div>
              <div class="ml-2">
                Total:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatNumber(producersAbove48Hours.length) }}
                </v-chip>
              </div>
              <div class="ml-2">
                Perc.:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatNumber(percentualAbove48Hours) }}%
                </v-chip>
              </div>
              <div class="ml-2">
                Volume:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatNumber(volumeAbove48Hours) }} L
                </v-chip>
              </div>
            </div>
          </template>
        </data-table>
      </v-card>
    </v-container>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-3"
        @click="printDetails"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir (Detalhado)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
    <v-overlay v-model="loading">
      Carregando ...
    </v-overlay>
  </v-container>
</template>

<script>
import moment from 'moment';
import qs from "qs";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    VDateRangePicker,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    MaskedInput
  },

  props: {
    dateFilter: Array,
    technicianFilter: String,
    producersFilter: Array,
    routesFilter: Array,
  },

  data() {
    return {
      aba: 'aba-relatorio',
      loading: false,
      filters: {
        search: null,
        horas: "",
        routes: [],
      },
      relatorio: [],
      data: [],
      coletas: [],
      rules: {
        time: v =>  !v || !!v && v.length == 5 || 'Hora Inválida!',
      },
    };
  },

  computed: {
    localDateFilter: {
      get() {
        return this.dateFilter;
      },
      set(newValue) {
        return this.$emit("update:date-filter", newValue);
      },
    },
    localTechnicianFilter: {
      get() {
        return this.technicianFilter;
      },
      set(newValue) {
        return this.$emit("update:technician-filter", newValue);
      },
    },
    localProducersFilter: {
      get() {
        return this.producersFilter;
      },
      set(newValue) {
        return this.$emit("update:producers-filter", newValue);
      },
    },
    localRoutesFilter: {
      get() {
        return this.routesFilter;
      },
      set(newValue) {
        return this.$emit("update:routes-filter", newValue);
      },
    },

    producersAbove48Hours() {
      return this.relatorio.filter(item => item.horas >= 48);
    },

    percentualAbove48Hours() {
      return (this.producersAbove48Hours.length / this.relatorio.length) * 100;
    },

    volumeAbove48Hours() {
      return this.producersAbove48Hours.reduce((acc, cur) => acc + parseFloat(cur.volume_coletado || 0), 0);
    },
  },

  mounted() {
    this.loadRelatorio();
  },

  methods: {
    async loadRelatorio() {
      try {
        this.loading = true;

        let routes = this.routesFilter.map(({ id }) => id)

        const [startDate, endDate] = this.dateFilter;

        const produtores = this.producersFilter.flatMap(p => {
          const producers = [p.id];
          if (p.grouping) {
            producers.push(...p.grouping.map(p => p.id_pessoa))
          }
          return producers;
        });

        const { data } = await this.$axios.post(
          `/relatorios/periodoColetas`,
          qs.stringify({
            data_inicio: startDate,
            data_fim: endDate,
            hora: this.filters.horas,
            rotas: JSON.stringify(routes),
            produtores: JSON.stringify(produtores),
            id_tecnico: this.technicianFilter,
          })
        );

        this.relatorio = data.map(item => ({
          ...item,
          horas: moment.duration(item.ultima_coleta).asHours(),
        }));

      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    async onFilter() {
      await this.$nextTick();
      return this.loadRelatorio();
    },

    getDetailedReport() {
      return this.relatorio.flatMap((item) => item.details
        .map((detail) => ({
          'Código': item.codigo_produtor,
          'Produtor': item.nome_produtor,
          'Rota': detail.nome_rota,
          'Data': detail.data,
          'Última Coleta': detail.hora,
          'Último Volume Coletado': detail.quantidade_coleta,
        })));
    },

    onTimeFormat() {

      if (this.filters.horas.length === 0) {
        this.onFilter();
        return;
      }

      let valor = this.filters.horas.replace(/\D/g, "");

      // Adiciona zeros à direita para ter pelo menos 4 caracteres
      while (valor.length < 4) {
        valor += "0";
      }

      // Limita a 4 caracteres
      valor = valor.slice(0, 4);

      // Formata para "00:00"
      valor = valor.replace(/(\d{2})(\d{2})/, "$1:$2");

      // Atualiza o valor do campo de entrada
      this.filters.horas = valor;

      this.onFilter();
    },

    getReportTitle(detailed = false) {
      const [startDate, endDate] = this.dateFilter;
      return `Período entre Coletas - ${detailed ? 'Detalhado - ' : ''} ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    printDetails() {
      const title = this.getReportTitle(true);
      const report = this.getDetailedReport();
      this.$refs.report.print(report, title);
    },

    exportExcel() {
      const report = this.getDetailedReport();

      const title = this.getReportTitle(true);

      this.$refs.report.exportExcel(report, title);
    },

    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(val),
  },
}
</script>
