<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Média de Qualidade Por Mês
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      ref="analysis-filter"
      color="transparent"
      flat
    >
      <v-row class="pa-0">
        <v-col
          md="3"
        >
          <date-range-picker
            v-model="filter.date.input"
            :clearable="false"
            @change="onDateFilter"
          />
        </v-col>
        <v-col
          md="3"
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
            @change="loadItems"
          />
        </v-col>
        <v-col
          md="3"
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            label="Rota"
            dark
            multiple
            @change="loadItems"
          />
        </v-col>
        <v-col
          md="3"
        >
          <v-autocomplete
            v-model="filter.status"
            :items="[
              { text: 'Ativos', value: 'ATIVOS' },
              { text: 'Desvinculados', value: 'DESVINCULADOS' },
            ]"
            label="Exibir"
            placeholder=" "
            hide-details
            dark
            filled
            @change="loadItems"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-spacer />
        <v-col
          md="3"
        >
          <v-text-field
            v-model="filter.search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          />
        </v-col>
      </v-card-title>
      <data-table
        ref="report"
        :headers="headers"
        :items="items"
        :search="filter.search"
        :loading="loading"
        class="elevation-1"
        show-custom-group
      />
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "ColetaMediaQualidadeMes",

  components: {
    DateRangePicker,
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter
  },

  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filter: {
        technician: {
          id: "",
          description: ""
        },
        search: '',
        date: { input: 'today', range: [] },
        routes: [],
        status: 'ATIVOS',
      },

      items: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Código", value: "codigo_laticinio", width: 90 },
        { text: "Produtor", value: "nome", align: "start" },
        { text: "Data", value: "data", align: "start", formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: "Quilômetros", value: "distancia_industria", formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: "Rota", value: "descricao" },
        { text: "CPP", value: "cbt_geometrica_mes", formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: "CCS", value: "ccs_geometrica_mes", formatter: value => this.formatNumber(value), mask: '#,##0' },
        { text: "Gordura", value: "gordura", formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: "Proteina", value: "proteina", formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: "Ureia", value: "ureia", formatter: value => this.formatNumber(value), mask: '#,##0.00' },
        { text: "Volume", value: "volume_total", formatter: value => `${this.formatNumber(value)} L`, mask: '#,##0' },
      ];
    },
  },

  methods: {
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    onDateFilter(event) {
      this.filter.date.range = event;
      this.loadItems();
    },

    getReportJson() {
      return this.items.map(o => ({
        Código: o.codigo_laticinio,
        Produtor: o.nome,
        Quilômetros: o.distancia_industria,
        CPP: o.cbt_geometrica_mes,
        CCS: o.ccs_geometrica_mes,
        Gordura: o.gordura,
        Proteína: o.proteina,
        Ureia: o.ureia,
        Volume: o.volume_total,
      }));
    },

    exportExcel() {
      this.$refs.report.exportExcel(null, 'Média de Qualidade por Mês');
    },

    print() {
      this.$refs.report.print(null, 'Média de Qualidade por Mês');
    },

    async loadItems() {
      try {

        this.loading = true;
        const [startDate, endDate] = this.filter.date.range;

        const data = await this.$socket.proxy({
          method: 'post',
          url: '/relatorios/mediaQualidade',
          data: {
            data_inicio: startDate || moment().format("YYYY-MM-DD"),
            data_fim: endDate || moment().format("YYYY-MM-DD"),
            rotas: this.filter.routes.map(({ id }) => id),
            id_tecnico: this.filter.technician.id,
            status: this.filter.status,
          }
        });

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data
          .map(item => ({
            ...item,
            distancia_industria: !_.isNull(item.distancia_industria) ? parseFloat(item.distancia_industria) : null,
            cbt_geometrica_mes: !_.isNull(item.cbt_geometrica_mes) ? parseInt(item.cbt_geometrica_mes) : null,
            ccs_geometrica_mes: !_.isNull(item.cbt_geometrica_mes) ? parseInt(item.ccs_geometrica_mes) : null,
            gordura: !_.isNull(item.gordura) ? parseFloat(item.gordura) : null,
            proteina: !_.isNull(item.proteina) ? parseFloat(item.proteina) : null,
            ureia: !_.isNull(item.ureia) ? parseFloat(item.ureia) : null,
            volume_total: parseInt(item.volume_total) || 0,
          }))
          .sort((a, b) => a.nome?.localeCompare(b.nome));
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar o relatório!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatNumber: (value) => _.isNull(value) ? '-' : new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
