<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
  >
    <v-card class="questionnaire-dialog">
      <v-card-title class="text-h5">
        {{ questionnaire.titulo }}

        <v-spacer />

        <v-menu
          bottom
          right
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="onPrint()"
            >
              <v-list-item-icon>
                <v-icon>print</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Imprimir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-text-field
              :value="formatDate(questionnaire.data_cadastro_app, 'DD/MM/YYYY')"
              prepend-inner-icon="subtitles"
              label="Data"
              disabled
              dense
            />
          </v-col>
          <v-col
            v-if="questionnaire.objeto"
            cols="12"
            class="pb-0"
          >
            <v-text-field
              :value="questionnaire.nome_objeto"
              prepend-inner-icon="subtitles"
              :label="getObjectLabel(questionnaire.objeto)"
              disabled
              dense
            />
          </v-col>
        </v-row>
        <v-row v-if="questionnaire.producer?.nome">
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="questionnaire.producer.nome"
              prepend-inner-icon="subtitles"
              label="Produtor"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="questionnaire.producer.codigo_laticinio"
              prepend-inner-icon="subtitles"
              label="Código Laticínio"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="questionnaire.producer.cnpj_cpf"
              prepend-inner-icon="assignment_ind"
              label="CPF/CNPJ"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pb-0"
          >
            <v-text-field
              v-model="questionnaire.producer.end_cidade"
              prepend-inner-icon="assignment_ind"
              label="Município"
              disabled
              dense
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-divider class="my-4" />
          </v-col>
        </v-row>

        <v-row
          v-for="(answer, idx) of questionnaire.answers"
          :key="idx"
        >
          <template v-if="answer.tipo_campo === 'TITULO'">
            <v-col
              cols="12"
              class="pb-0 text-h6 black--text"
            >
              {{ answer.rotulo }}
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'CHECKBOX'">
            <v-col
              cols="12"
              class="pb-0"
            >
              <span class="text-body-2 black--text">
                {{ answer.rotulo }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-row no-gutters>
                <v-checkbox
                  v-for="option of answer.valores"
                  :key="`${idx}-${option.value}`"
                  :input-value="option.checked"
                  :label="option.value"
                  disabled
                  dense
                  class="shrink mr-2 mt-0"
                />
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'TEXTO'">
            <v-col
              cols="12"
              class="pb-0 mt-2"
            >
              <v-textarea
                :label="answer.rotulo"
                :value="answer.resposta"
                rows="1"
                auto-grow
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'DATA'">
            <v-col
              cols="12"
              class="pb-0 mt-2"
            >
              <v-text-field
                :label="answer.rotulo"
                :value="answer.resposta ? formatDate(answer.resposta, 'DD/MM/YYYY') : ''"
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'DATA_HORA'">
            <v-col
              cols="12"
              class="pb-0 mt-2"
            >
              <v-text-field
                :label="answer.rotulo"
                :value="answer.resposta ? formatDate(answer.resposta, 'DD/MM/YYYY HH:mm') : ''"
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'HORA'">
            <v-col
              cols="12"
              class="pb-0 mt-2"
            >
              <v-text-field
                :label="answer.rotulo"
                :value="answer.resposta"
                disabled
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else-if="answer.tipo_campo === 'FOTO'">
            <v-col
              cols="12"
              class="pb-0"
            >
              <span class="text-body-2 black--text">
                {{ answer.rotulo }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-img
                v-if="answer.resposta"
                :lazy-src="answer.resposta"
                class="mr-3 mb-3"
                max-width="100"
                min-height="133"
                style="cursor: zoom-in !important;"
                @click="openGallery(answer.resposta)"
              />
              <span v-else>---</span>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="12"
              class="pb-0"
            >
              {{ answer }}
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-divider class="my-0" />
            </v-col>
          </template>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-card-actions>

      <LightBox
        v-if="pictures.length > 0"
        ref="lightbox"
        :media="pictures"
        :show-light-box="false"
      />
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.questionnaire-dialog {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import printJS from 'print-js';

import LightBox from 'vue-image-lightbox';

export default {

  components: {
    LightBox,
  },

  data() {
    return {
      dialog: false,
      answerId: null,
      questionnaire: {
        producer: {},
        answers: [],
      },

      pictures: [],
    }
  },

  methods: {
    async loadAnswers() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.get(`/questionnaires/${this.answerId}`);

        this.questionnaire = {
          ...data,
          titulo: this.titleCase(data.titulo),
          answers: _(data.answers)
            .groupBy('id_rotulo')
            .map((answers) => {
              const [answer] = answers;

              answer.rotulo = this.titleCase(answer.rotulo);

              if (!answer.tipo_campo) {
                const extension = answer.resposta
                  .split(".").pop()
                  .split("?").shift();

                if ((/(gif|jpe?g|jpg|tiff?|png|webp|bmp)$/i).test(extension)) {
                  answer.tipo_campo = 'FOTO';
                }
              }

              if (answer.tipo_campo === 'CHECKBOX') {
                const responses = answers.map(response => response.resposta?.trim()?.toUpperCase());

                answer.valores = answer.valores.map(option => {
                  return {
                    checked: responses.includes(option.trim().toUpperCase()),
                    value: option,
                  };
                })
              }

              return answer;
            })
            .filter((answer) => {
              if (answer.tipo_campo === 'TEXTO') {
                if (!answer.resposta) {
                  return false;
                }

                if (answer.rotulo === 'Recomendação' && _.isEmpty(answer.condicional)) {
                  return false;
                }

                if (!_.isEmpty(answer.condicional)) {
                  const ref = data.answers.find(item => item.id_rotulo == answer.condicional.id_campo_comparacao);

                  if (ref?.resposta !== answer.condicional.comparacao) {
                    return false;
                  }
                }
              }

              if (answer.tipo_campo === 'DATA' && answer.resposta == 0) {
                return false;
              }

              return true;
            })
            .value()
        };

      } catch (error) {
        this.$snotify.error('Erro ao carregar produtor', 'Atenção');
        console.warn(error);
        this.close();
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    show({ id }) {
      this.dialog = true;
      this.answerId = id;
      this.loadAnswers();
    },

    close() {
      this.dialog = false;
      this.questionnaire = {
        producer: {},
        answers: [],
      };
      this.$emit('close');
    },

    async openGallery(picture) {
      this.pictures = [{ src: picture, thumb: picture }];

      await this.$nextTick();

      this.$refs.lightbox.showImage(0);
    },

    async onPrint() {
      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/formularioCustomizado/impressaoRespotasFormulario`, {
          id_formulario: this.questionnaire.id_formulario,
          id_respostas: [this.answerId],
        });

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        this.$snotify.error('Erro ao imprimir questionário', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    getObjectLabel(value) {
      const labels = {
        'PLACA_LEITE': 'Equipamento',
        'ROTA': 'Rota',
        'SILO': 'Silo',
        'CIP_SILO': 'CIP Silo',
        'DESCARGA': 'Carga/Descarga',
        'ITINERARIO': 'Itinerário',
        'ORDEM_PRODUCAO': 'Ordem de Produção',
        'EXPEDICAO': 'Expedição',
        'GERAL': 'Sem seleção'
      }

      return labels[value] || value;
    },

    titleCase: (value) => String(value).replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
