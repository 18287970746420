<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.percentual_despesas"
        label="Percentual Despesas (Incentivo)"
        :mask="masks.percentual"
        unmask
        prepend-inner-icon="percent"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.aliquota_fundesa"
        label="Fundesa (Alíquota/L)"
        :mask="masks.fundesa"
        unmask
      />
    </v-col>
    <v-col
      v-if="showValores && form.valor_fundesa"
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <money-input
        v-model="form.valor_fundesa"
        label="Valor Fundesa"
        prefix="R$"
        :disabled="autocalculation"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
      class="pb-0"
    >
      <masked-text-field
        v-model="form.beneficio_fiscal"
        label="Código de Benefício Fiscal"
        :mask="masks.cBenef"
        hint="Código de Benefício Fiscal na UF aplicado ao item"
        unmask
      />
    </v-col>
  </v-row>
</template>

<script>

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";

export default {

  components: {
    MaskedTextField,
    MoneyInput,
  },

  props: {
    data: Object,
    showValores: Boolean,
    autocalculation: Boolean,
  },

  data() {
    return {
      masks: {
        fundesa: { mask: Number, min: 0, scale: 6 },
        percentual: { mask: Number, min: 0, max: 100, scale: 4 },
        cBenef: { mask: 'aa000000' }
      },
    }
  },

  computed: {
    form: {
      get() {
        return this.data;
      },

      set(newValue) {
        return this.$emit("update:data", newValue);
      },
    },
  },
}
</script>
