<template>
  <div class="text-center d-flex flex-wrap pa-4 mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Mapa de Rotas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-flex xs12>
      <v-card
        color="transparent"
        dark
        class="mt-5"
      >
        <v-card-title class="py-1 d-block">
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="3"
            >
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                content-class="menu-select"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateRefFilter"
                    label="Mês de Referência"
                    prepend-inner-icon="event"
                    readonly
                    filled
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="filters.date"
                  :max="maxDateRef"
                  type="month"
                  no-title
                  scrollable
                  @change="loadReport"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="3"
            >
              <v-select
                v-model="filters.referenceDate"
                :items="['DESCARGA', 'COLETA']"
                label="Data de referência"
                prepend-inner-icon="local_shipping"
                dark
                clearable
                filled
                @change="loadReport"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
            >
              <v-text-field
                v-model="filters.search"
                label="Pesquisar"
                prepend-inner-icon="search"
                single-line
                hide-details
                clearable
                filled
              />
            </v-col>

            <filter-menu
              :options="filters.options"
              :customs="headers"
              :selected-options.sync="filters.selectedOptions"
              :selected-customs.sync="filters.selectedCustoms"
              @removed="onFilterRemoved"
            />
          </v-row>
          <filter-items
            :selected-options.sync="filters.selectedOptions"
            :selected-customs.sync="filters.selectedCustoms"
            @removed="onFilterRemoved"
          >
            <filter-item value="routes">
              <routes-autocomplete-filter
                v-model="filters.routes"
                label="Rota Principal"
                multiple
                dark
                :return-object="false"
                :show-code-route="true"
                @change="loadReport"
              />
            </filter-item>
          </filter-items>
        </v-card-title>
        <data-table
          ref="report"
          name="Mapa de rotas"
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          item-key="id_produtor"
          group-by="nome_rota"
          :items-per-page="50"
          class="elevation-1"
        />
        <v-card-title>
          <v-spacer />
        </v-card-title>
      </v-card>
    </v-flex>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="yellow darken-1"
        @click="exportExcelMapa"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Exportar Mapa (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";
import isEmpty from "lodash/fp/isEmpty";
import isArray from "lodash/fp/isArray";
import debounce from "lodash/debounce";
import FilterMenu from "@/Support/Components/FilterMenu/FilterMenu.vue";
import FilterItems from "@/Support/Components/FilterMenu/FilterItems.vue";
import FilterItem from "@/Support/Components/FilterMenu/FilterItem.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";

export default {
  components: {
    FilterMenu,
    FilterItems,
    FilterItem,
    RoutesAutocompleteFilter
  },
  mixins: [ReportMixin],

  data() {
    return {
      loading: false,

      filters: {
        date: moment().subtract(1, "month").format("YYYY-MM"),
        search: '',
        referenceDate: 'DESCARGA',
        options: [
          { text: 'Rota Principal', value: 'routes', icon: 'icon-rota' },
        ],
        selectedOptions: [],  // Garante que é um array
        selectedCustoms: [],  // Garante que é um array
        routes: [],
      },

      headers: [
        { text: "Rota", value: "nome_rota", align: "center" },
        { text: "Cód.", value: "codigo_laticinio", align: "center" },
        { text: "Produtor", value: "nome", align: "center" },
        { text: "Serviço de Inspeção", value: "sif", align: "center" },
        { text: "NRP", value: "nrp", align: "center" },
        { text: "CPF/CNPJ", value: "cnpj_cpf", align: "center" },
        { text: "Endereço", value: "end_rua", align: "center" },
        { text: "Bairro", value: "end_bairro", align: "center" },
        { text: "CEP", value: "end_cep", align: "center" },
        { text: "Cidade", value: "end_cidade", align: "center" },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "NIRF", value: "nirf", align: "center" },
        { text: "Vol. Méd./dia (L)", value: "litros", align: "center" },
        { text: "Vol. Mês (L)", value: "volume_mes", align: "center" },
        { text: "E-mail", value: "prestadores_rotas.0.email", align: "center" },
        { text: "Transportador ", value: "prestadores_rotas.0.nome_prestador", align: "center" },
        { text: "Telefone ", value: "telefone", align: "center" },
        { text: "Latitude ", value: "end_latitude", align: "center" },
        { text: "Longitude ", value: "end_longitude", align: "center" },
        { text: "Cap. do resfriador (L)", value: "capacidade_resf_inte", align: "center" },
        { text: "Tipo de resfriador", value: "tipo_resfriador", align: "center" },
        { text: "Hora da última coleta", value: "data_hora_ultima_coleta", formatter: value => this.formatDate(value, 'DD/MM/YYYY HH:mm'), align: "center" },
        { text: "Frequência de coleta", value: "frequencia_coleta", align: "center" },
        { text: "Descrição da rota", value: "descricao_rota", align: "center" },
        { text: "Codigo da rota", value: "codigo_rota", align: "center" },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center" },
      ],

      items: [],

      itemsDetail: [],

      nfeDetails: [],
    };
  },

  computed: {

    filteredItems() {
      if (!this.filters.search && !this.filters.selectedCustoms.some(filter => filter.search)) {
        return this.items;
      }

      const search = this.filters.search.toUpperCase().trim()

      const filterableColumns = this.headers.filter(header => header.filterable !== false).map(header => header.value)

      const searchFilter = (item) => {
        const searchTerm = search.toUpperCase()
        return filterableColumns.some(column => item[column]?.toString()?.toUpperCase()?.includes(searchTerm))
      }

      const customs = this.filters.selectedCustoms
        .filter(filter => filter.search)
        .map(filter => ({ value: filter.value, search: filter.search.toUpperCase().trim() }))

      const customsFilter = (item) => customs.every(filter => item[filter.value]?.toString()?.toUpperCase()?.includes(filter.search))

      return this.items.filter(item => {
        const matchSearch = !search || searchFilter(item)
        const matchCustoms = customs.length === 0 || customsFilter(item);

        return matchSearch && matchCustoms
      });
    },

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, "YYYY-MM").format("MM/YYYY");
    },

    laticinio() {
      return this.$store.state.settings.laticinio;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadReport();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = {
          ...this.filters,
          ...this.$store.state.settings.filters[filterId],
        };
      }
    },
    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async loadReport() {
      this.loading = true;
      try {

        const [{ data }, { data: details }, { data: dataNfe }] = await Promise.all([
          this.$axios.post(
            `/produtores/mapaProdutores`,
            {
              mes_referencia: this.filters.date,
              data_referencia_coleta: this.filters.referenceDate,
              id_rota_principal: this.filters.routes,
            }
          ),
          this.$axios.post(
            `/produtores/mapaProdutoresDetalhado`,
            {
              mes_referencia: this.filters.date,
              data_referencia_coleta: this.filters.referenceDate,
              id_rota_principal: this.filters.routes,
            }
          ),
          this.$axios.post(
            `/produtores/mapaNfeProdutor`,
            {
              mes_referencia: this.filters.date,
              data_referencia_coleta: this.filters.referenceDate,
            }
          ),
        ]);

        if (!_.isArray(data)) {
          throw data;
        }

        this.items = data;

        if (!_.isArray(details)) {
          throw details;
        }

        this.itemsDetail = details;

        this.nfeDetails = dataNfe;
      }
      catch (err) {
        this.$snotify.error("Erro ao carregar o relatório", "Atenção");
      }
      finally {
        this.loading = false;
      }
    },

    print() {
      const title = `Mapa - ${moment(this.filters.date, 'YYYY-MM').format('MMM-YY').toUpperCase()}`;

      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = `Mapa - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

      this.$refs.report.exportExcel(null, filename);
    },

    padWithZeros(str, length) {
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    },

    removeSpecialCharacters(value) {
      return value === null ? '' : value.replace(/[^\w\s]/g, '');
    },

    exportExcelMapa() {
      if (!this.laticinio?.ie) {
        this.$snotify.info("Para exportar o Mapa é necessário selecionar um Laticínio.");
        return;
      }

      const workbook = this.XLSX.utils.book_new();

      const produtores = this.items.filter(function(produtor) {
        return this.nfeDetails.some(item => item.id_produtor === produtor.id_produtor);
      }.bind(this));

      const dtsProdutor = produtores.map(function(produtor) {

        const cdProdutorIe = this.padWithZeros(this.removeSpecialCharacters(produtor.inscricao_estadual), 13);
        const cdProdutorCpf = this.removeSpecialCharacters(produtor.cnpj_cpf);

        return {
          'CD_PRODUTOR_IE': cdProdutorIe,
          'CD_PRODUTOR_CPF': cdProdutorCpf,
          'NM_PRODUTOR': produtor.nome
        }
      }.bind(this));

      const dtsRecebimentosLeite = this.itemsDetail.map(function(produtor) {
        const cdProdutorIe = this.padWithZeros(this.removeSpecialCharacters(produtor.inscricao_estadual), 13);
        const cdPlaca = this.padWithZeros(this.removeSpecialCharacters(produtor.placa), 7);
        const dtRecebimento = this.formatDate(produtor.data_hora_descarga, 'DD/MM/YYYY');
        return {
          'CD_PRODUTOR_IE': cdProdutorIe,
          'DT_RECEBIMENTO': dtRecebimento,
          'QT_LITROS': produtor.volume,
          'CD_PLACA': cdPlaca,
        }
      }.bind(this));

      const dtsNf = this.nfeDetails.map(function(produtor) {

        const cdProdutorIe = this.padWithZeros(this.removeSpecialCharacters(produtor.inscricao_estadual), 13);
        const dtEmissao = this.formatDate(produtor.data_emissao, 'DD/MM/YYYY');
        const calcularFrete = false;

        return {
          'CD_PRODUTOR_IE': cdProdutorIe,
          'DT_NF': dtEmissao,
          'NR_NF': produtor.numero,
          'CD_SERIE': produtor.serie,
          'CD_CHAVE': produtor.chave,
          'FL_RESPONSABILIDADE': 'L',
          'QT_LITROS': this.formatCurrencyWithoutThousandSeparator(produtor.volume_resultado),
          'VR_TOTAL_NF': this.formatCurrencyWithoutThousandSeparator(produtor.valor_base),
          'VR_MERCADORIA': this.formatCurrencyWithoutThousandSeparator(produtor.valor_unitario, 4),
          'VR_FRETE': calcularFrete ? this.formatCurrencyWithoutThousandSeparator(produtor.valor_frete) : this.formatCurrencyWithoutThousandSeparator(0),
          'VR_BC': this.formatCurrencyWithoutThousandSeparator(produtor.valor_nfe),
          'VR_DEDUCOES': this.formatCurrencyWithoutThousandSeparator(produtor.valor_deducoes),
          'VR_INCENTIVO': this.formatCurrencyWithoutThousandSeparator(produtor.valor_incentivo),
          'VR_ICMS': this.formatCurrencyWithoutThousandSeparator(produtor.valor_icms),
        }
      }.bind(this));

      const worksheetProdutores = this.XLSX.utils.json_to_sheet(dtsProdutor);
      const worksheetRecebimentos = this.XLSX.utils.json_to_sheet(dtsRecebimentosLeite);
      const worksheetNfe = this.XLSX.utils.json_to_sheet(dtsNf);

      this.XLSX.utils.book_append_sheet(workbook, worksheetProdutores, 'Produtores');
      this.XLSX.utils.book_append_sheet(workbook, worksheetRecebimentos, 'Recebimento-Leite');
      this.XLSX.utils.book_append_sheet(workbook, worksheetNfe, 'Notas Fiscais - Globais');

      const anoMes = this.removeSpecialCharacters(this.filters.date);
      const ieLaticinio = this.removeSpecialCharacters(this.laticinio.ie);
      const filename = `MRL_P_01_${ieLaticinio}_${anoMes}`;
      this.XLSX.writeFile(workbook, `${filename}.xlsx`);

    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
    formatCurrencyWithoutThousandSeparator(value, minimumDigits = 2) {
      const formattedValue = (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: minimumDigits }).format(Number(value).toFixed(minimumDigits)));
      return formattedValue.replace(/\./g, '');
    },

    onFilterRemoved(value) {
      if (!isEmpty(this.filters[value])) {
        if (isArray(this.filters[value])) {
          this.filters[value] = []
        } else {
          this.filters[value] = null
        }
        this.onFilter()
      }
    },

    onFilter: debounce(function () {
      this.loadReport();
    }, 1000),

  },
};
</script>
