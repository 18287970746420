<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title>
            <v-row no-gutters>
              <v-spacer />
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filters.search"
                  label="Pesquisar"
                  prepend-inner-icon="search"
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filters.search"
            dark
            @click:row="edit"
          >
            <template #[`item.startDate`]="{ value }">
              {{ formatDate(value, 'MM/YYYY') }}
            </template>

            <template #[`item.ignoreItems`]="{ value }">
              <v-chip
                v-for="item in value"
                :key="item"
                x-small
              >
                {{ itemNames[item] }}
              </v-chip>
            </template>

            <template #[`item.incentives`]="{ value }">
              <v-chip
                v-for="(item, idx) in value"
                :key="idx"
                x-small
              >
                R$ {{ formatNumber(item) }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="edit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="form.dialog"
      persistent
      max-width="630px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Particularidades do Produtor
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              lazy-validation
              @submit.prevent="save"
            >
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-menu
                    ref="menu"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        :value="dateRefFilter(form.startDate)"
                        label="Mês de Início *"
                        prepend-inner-icon="event"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="form.startDate"
                      type="month"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <person-autocomplete-filter
                    v-model="form.producer"
                    label="Produtor *"
                    type="PRODUCER"
                    :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    persistent-hint
                    :filled="false"
                    :clearable="false"
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.ignoreItems"
                    label="Ignorar itens na Bonificação"
                    prepend-inner-icon="view_stream"
                    :items="qualityItems"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <span
                    class="text-h6"
                    style="display: inline-flex;"
                  >
                    Campanha de Novos Produtores
                    <v-spacer />
                    <v-btn
                      icon
                      color="green"
                    >
                      <v-icon
                        @click="addIncentive()"
                      >
                        add
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="form.incentives.length > 0"
                      icon
                      color="red"
                    >
                      <v-icon
                        @click="removeIncentive()"
                      >
                        remove
                      </v-icon>
                    </v-btn>
                  </span>
                  <v-row>
                    <v-col
                      v-for="(incentive, idx) of form.incentives"
                      :key="idx"
                      cols="3"
                    >
                      <v-text-field
                        v-model.number="form.incentives[idx]"
                        :label="`${idx + 1}º Mês *`"
                        hint="Valor Incentivo"
                        persistent-hint
                        type="number"
                        prefix="R$"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                      />
                    </v-col>
                    <v-col
                      v-if="form.incentives.length === 0"
                      cols="3"
                    >
                      <v-text-field
                        value="0,00"
                        label="1º Mês"
                        hint="Valor Incentivo"
                        persistent-hint
                        prefix="R$"
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="form.motive"
                    label="Motivo"
                    persistent-hint
                    rows="2"
                    auto-grow
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          class="mr-5"
          v-on="on"
          @click.stop="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Nova particularidade</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import moment from "moment-timezone";
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "producer-particularities",

  components: {
    PersonAutocompleteFilter,
  },

  props: {
    rawMaterialId: String,
  },

  data() {
    return {
      headers: [
        { text: 'Produtor', value: 'producer.name' },
        { text: 'Mês de Início', value: 'startDate' },
        { text: 'Ignorar Itens', value: 'ignoreItems' },
        { text: 'Campanha de Incentivos', value: 'incentives' },
        { text: 'Motivo', value: 'motive' },
        { text: '', value: 'action', width: 30 },
      ],

      items: [],

      form: {
        dialog: false,
        id: null,
        startDate: null,
        producer: {},
        ignoreItems: [],
        incentives: [],
      },

      filters: {
        search: '',
      },

      qualityItems: [],
      itemNames: {},
    };
  },

  async created() {
    this.loadQualities();
    this.loadParticularities();
  },

  methods: {
    async loadParticularities() {
      try {
        const { data } = await this.$axios.get(`/bonus/particularities`);

        this.items = data.map(item => ({
          id: item.id,
          producer: {
            id: item.id_produtor,
            name: item.producer.nome
          },
          startDate: item.data_inicio,
          ignoreItems: item.ignorar_itens || [],
          incentives: item.meses_incentivos || [],
          motive: item.motivo,
        }));
      }
      catch (err) {
        this.$snotify.error("Erro ao carregar dados", "Atenção");
        console.warn(err)
      }
    },

    add() {
      this.form = {
        dialog: true,
        id: null,
        startDate: null,
        producer: {},
        ignoreItems: [],
        incentives: [],
      };
      this.$refs.form?.resetValidation();
    },

    edit(data) {
      this.form = {
        dialog: true,
        ...cloneDeep(data),
        startDate: data.startDate ? moment(data.startDate).format('YYYY-MM') : null,
      };
      this.$refs.form?.resetValidation();
    },

    close() {
      this.form.dialog = false
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        const payload = {
          id_materia_prima: this.rawMaterialId,
          data_inicio: this.form.startDate + '-01',
          id_produtor: this.form.producer.id,
          motivo: this.form.motive,
          ignorar_itens: this.form.ignoreItems,
          meses_incentivos: this.form.incentives,
        };

        this.form.id
          ? await this.$axios.put(`/bonus/particularities/${this.form.id}`, payload)
          : await this.$axios.post(`/bonus/particularities`, payload);

        this.close();

        this.$snotify.success("Particularidade salva com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadParticularities();
      }
    },

    async remove({ id }) {
      if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
        return;
      }
      try {
        this.$root.$progressBar.saving();

        await this.$axios.delete(`/bonus/particularities/${id}`);

        this.$snotify.success("Dados excluídos", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao excluir dados: " + error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadParticularities();
      }
    },

    dateRefFilter(date) {
      if (isEmpty(date)) {
        return null;
      }

      return moment(date, 'YYYY-MM').format('MM/YYYY');
    },

    addIncentive() {
      this.form.incentives.push(null);
    },

    removeIncentive() {
      this.form.incentives.splice(-1, 1);
    },

    async loadQualities() {
      try {
        const { data } = await this.$queries.post(`/qualidade/listaQualidadeJson`, { nome: '%' });

        this.qualityItems = data.map(({ id_qualidade, item }) => ({
          value: id_qualidade,
          text: item,
        }));

        this.itemNames = data.reduce((acc, cur) => ({
          ...acc,
          [cur.id_qualidade]: cur.item
        }), {})
      }
      catch (err) {
        console.warn(err)
      }
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: (value, decimals = 2) => new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals }).format(value),
  },
};
</script>
