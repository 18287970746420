<template>
  <div>
    <v-card
      color="transparent"
      dark
    >
      <v-card-text class="pb-2">
        <v-form
          ref="form"
          v-model="Validation"
        >
          <v-row>
            <v-spacer />
            <v-col
              md="2"
            >
              <v-text-field
                v-model="settings.version"
                outlined
                filled
                dark
                dense
                placeholder="Versão"
                hide-details
              />
            </v-col>
            <v-col
              v-if="project"
              md="2"
              class="pr-5"
            >
              <v-text-field
                v-model="settings.code"
                outlined
                dense
                filled
                dark
                placeholder="Protocolo / RQ"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-autocomplete
                v-model="settings.units"
                label="Unidades"
                :loading="loading"
                :items="listUnits"
                item-text="nome"
                :rules="[(v) => v.length > 0 || 'Campo obrigatorio']"
                item-value="id_pessoa"
                return-object
                multiple
                dense
                prepend-icon="business"
              >
                <template #selection="{ attrs, selected, item, select }">
                  <v-chip
                    dark
                    v-bind="attrs"
                    :input-value="selected"
                    color="blue-grey darken-1"
                    class="mt-1"
                    close
                    @click="select"
                    @click:close="removeChipUnit(item.id_pessoa)"
                  >
                    <v-icon
                      left
                      small
                    >
                      business
                    </v-icon>
                    {{ item.nome }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-autocomplete
                v-model="settings.questionnaireTypes"
                label="Tipo do Questionário"
                :rules="[(v) => v.length > 0 || 'Campo obrigatorio']"
                :items="questionnaireTypeItems"
                multiple
                dense
                return-object
                prepend-icon="sell"
              >
                <template #selection="{ attrs, selected, item, select }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    color="teal darken-1"
                    dark
                    class="mt-1"
                    close
                    @click="select"
                    @click:close="removeChipType(item.value)"
                  >
                    <v-icon
                      left
                      small
                    >
                      sell
                    </v-icon>
                    {{ item.text }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <template>
              <v-col
                v-if="view == 'visits' && project == ''"
                cols="12"
                class="pt-0"
              >
                <v-autocomplete
                  v-model="settings.ranges"
                  label="Categoria de Produtores (curva ABC)"
                  :items="listRanges"
                  multiple
                  dense
                  return-object
                  prepend-icon="sell"
                  item-text="nome_faixa"
                  item-value="id_faixa"
                >
                  <template #selection="{ attrs, selected, item, select }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      color="teal darken-1"
                      dark
                      class="mt-1"
                      close
                      @click="select"
                      @click:close="removeChipRanges(item.id_faixa)"
                    >
                      <v-icon
                        left
                        small
                      >
                        sell
                      </v-icon>
                      {{ item.nome_faixa }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="['visits', 'project'].includes(view)"
                cols="12"
                class="pt-0 d-flex"
              >
                <v-icon>ballot</v-icon>
                <div class="ml-2">
                  <span class="caption">Trazer preenchido da última visita</span>
                  <v-switch
                    v-model="settings.filled"
                    :label="settings.filled ? 'Ativado' : 'Desativado'"
                    persistent-hint
                    class="mt-0"
                    color="deep-purple"
                  />
                </div>
              </v-col>
            </template>
            <template v-if="view == 'others'">
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-select
                  v-model="settings.object"
                  label="Seleção de item"
                  :items="objects"
                  prepend-icon="sell"
                  clearable
                />
              </v-col>
            </template>
            <v-col
              v-if="['visits', 'project'].includes(view) || ['ITINERARIO', 'ORDEM_PRODUCAO'].includes(settings.object)"
              cols="12"
              class="pt-0 d-flex"
            >
              <v-icon>emergency</v-icon>
              <div class="ml-2">
                <span class="caption">Preenchimento Obrigatório</span>
                <v-switch
                  v-model="settings.required"
                  :label="settings.required ? 'Ativado' : 'Desativado'"
                  persistent-hint
                  class="mt-0"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 d-flex"
            >
              <div class="ml-2">
                <span class="caption">Mostra nome da empresa na impressão</span>
                <v-switch
                  v-model="settings.showName"
                  :label="settings.showName ? 'Ativado' : 'Desativado'"
                  persistent-hint
                  class="mt-0"
                  color="deep-purple"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 d-flex"
            >
              <div class="ml-2">
                <span class="caption">Mostra CNPJ da empresa na impressão</span>
                <v-switch
                  v-model="settings.showCnpj"
                  :label="settings.showCnpj ? 'Ativado' : 'Desativado'"
                  persistent-hint
                  class="mt-0"
                  color="deep-purple"
                />
              </div>
            </v-col>
            <v-col
              v-if="view === 'others'"
              cols="12"
              class="pb-0"
            >
              <v-autocomplete
                v-model="settings.access"
                :items="users"
                label="Acessos"
                item-value="id"
                item-text="nome"
                prepend-inner-icon="person"
                multiple
                chips
                small-chips
                deletable-chips
              />
            </v-col>
            <template v-if="['visits', 'project'].includes(view)">
              <v-col
                cols="12"
                class="pt-0 d-flex"
              >
                <div class="ml-2">
                  <span class="caption">Mostra ordem na impressão</span>
                  <v-switch
                    v-model="settings.showOrder"
                    :label="settings.showOrder ? 'Ativado' : 'Desativado'"
                    persistent-hint
                    class="mt-0"
                    color="deep-purple"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-autocomplete
                  v-model="settings.questionnaireAnswer"
                  label="Questões indispensáveis"
                  :items="questions"
                  item-value="id"
                  item-text="text"
                  multiple
                  dense
                  return-object
                  prepend-icon="sell"
                >
                  <template #selection="{ attrs, selected, item, select }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      color="teal darken-1"
                      dark
                      class="mt-1"
                      close
                      @click="select"
                      @click:close="removeChipQuestion(item.id)"
                    >
                      <v-icon
                        left
                        small
                      >
                        sell
                      </v-icon>
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="green darken-3"
        @click="save"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style lang="scss">
.switch-conditions {
  display: flex;
  align-items: center;
  font-family: Roboto, Arial, sans-serif;
  font-size: 0.9rem;
  color: #fff;
}
</style>

<script>
import * as _ from "lodash";
import QuestionnaireTypes from "@/Domains/Questionnaires/Constants/QuestionnaireTypes.js";

export default {

  props: {
    view: {
      type: String,
    },

    questionList: {},

    settings: {
      type: Object,
      default: () => ({
        units: [],
        ranges: [],
        questionnaireTypes: [],
        questionnaireAnswer: [],
        access: [],
        filled: false,
        required: false,
        version: "",
        code: "",
        showOrder: false,
        showName: false,
        showCnpj: false,
      })
    },
    project: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      // Lista unidades
      listUnits: [],

      // flag de validacao
      Validation: false,

      //faixas de classificacao de produtores
      listRanges: [],

      objects: [
        { value: 'PLACA_LEITE', text: 'Equipamento' },
        { value: 'ROTA', text: 'Rota' },
        { value: 'SILO', text: 'Silo' },
        { value: 'CIP_SILO', text: 'CIP Silo' },
        { value: 'DESCARGA', text: 'Carga/Descarga' },
        { value: 'ITINERARIO', text: 'Itinerário' },
        { value: 'ORDEM_PRODUCAO', text: 'Ordem de Produção' },
        { value: 'EXPEDICAO', text: 'Expedição' },
        { value: 'GERAL', text: 'Sem seleção' }
      ],

      users: [],
    };
  },

  computed: {
    questionnaireTypeItems() {
      const types = QuestionnaireTypes.filter(({ type }) => type === this.view);

      return types;
    },

    questions() {
      const questions = this.questionList.map(({ values }) =>  ({
        text: values.label,
        id: values.id,
      }));

      return questions;
    },
  },

  mounted() {
    this.loadUnits();
    this.loadRanges();
    this.loadUsers();
  },

  methods: {
    /**
     * Fecha dialog
     */
    closeDialog() {
      this.$emit("openConfirmDialog");
    },

    /**
     * Busca as unidades do laticinio
     */
    async loadUnits() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/pessoa/listaUnidadesLaticinio`);

        this.listUnits = data;
      } catch (e) {
        console.log(e);

      } finally {
        this.loading = false;
      }
    },

    /**
     * Busca as faixas de classificacao de produtor
     */
    async loadRanges() {
      try {

        const { data } = await this.$axios.post(`/configuracao/listaFaixasClassificacaoProdutor`);

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        this.listRanges = data;

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao listar faixas!", "Atenção");
      }
    },

    async loadUsers() {

      const filtrar_usuarios = true;
      if (this.view !== 'others') {
        return;
      }

      try {
        const { data } = await this.$axios.post(`/pessoa/listaJson`, {
          filtrar_usuarios
        });

        this.users = data.map(person => ({
          id: person.id_pessoa,
          nome: person.nome,
        }));
      } catch (err) {
        console.warn(err);
        this.$snotify.error('Oops, ocorreu um erro ao carregar os usuários!', 'Atenção');
      }
    },

    /**
     * @void
     * Remove item da seleção de unidade
     */
    removeChipUnit(unitId) {
      if (_.isEmpty(this.partnersUnits)) {
        return false;
      }

      this.partnersUnits = _.filter(this.partnersUnits, (data) => {
        return data.id_pessoa !== unitId;
      });
    },

    /**
     * @void
     * Remove item da seleção dos tipos
     */
    removeChipType(type) {
      if (_.isEmpty(this.settings.questionnaireTypes)) {
        return false;
      }

      this.settings.questionnaireTypes = _.filter(this.settings.questionnaireTypes, (data) => {
        return data.value !== type;
      });
    },

    removeChipQuestion(id) {
      if (_.isEmpty(this.settings.questionnaireAnswer)) {
        return false;
      }

      this.settings.questionnaireAnswer = _.filter(this.settings.questionnaireAnswer, (data) => {
        return data.id !== id;
      });
    },

    /**
     * @void
     * Remove item da seleção dos tipos
     */
    removeChipRanges(id) {
      if (_.isEmpty(this.ranges)) {
        return false;
      }

      this.ranges = _.filter(this.ranges, (data) => {
        return data.id_faixa !== id;
      });
    },

    /**
     * Valida os campos do formulário
     */
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    save() {
      this.validate();

      if (!this.Validation) {
        return true;
      }
      this.$emit("onSave");
    },
  },
};
</script>
