<template>
  <v-card
    class="mx-auto"
    dark
    :style="{ background: color }"
    :loading="loading"
  >
    <v-card-title>
      <v-icon
        size="28"
        left
      >
        analytics
      </v-icon>
      <span class="title font-weight-light">
        Total de Antibióticos por Princípio Ativo
      </span>
    </v-card-title>

    <v-tabs
      v-show="Object.keys(graph).length > 0"
      v-model="tab"
      align-with-title
      background-color="transparent"
      dark
    >
      <v-tabs-slider color="cyan" />
      <v-tab>
        <v-icon>analytics</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>list</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-chart
          :options="options"
          :style="{ width: '100%', height: `${height} !important` }"
          autoresize
        />
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="reportHeaders"
          :items="reportData"
          dark
          class="elevation-1 report-table"
          dense
          hide-default-footer
          disable-pagination
          :style="{ overflowX: 'auto', maxWidth: '100%' }"
        >
          <template v-slot:item.principle="{ item }">
            {{ item.principle === 'S/PA' ? 'Sem Princípio Ativo' : item.principle }}
          </template>
          <template v-slot:item.positive="{ item }">
            {{ item.positive }}
          </template>
          <template v-slot:item.negative="{ item }">
            {{ item.negative }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.total }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style>
.v-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.v-tab-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

.milk-average-tabs-items {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}

v-chart {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.report-table {
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  table-layout: fixed;
}
</style>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0, 0, 0, 0.4)'
    },
    height: {
      type: String,
      default: '188px'
    },
    period: {
      type: Array,
      default: () => [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ]
    }
  },

  data() {
    return {
      loading: false,
      graph: {},
      totalPrincipios: 0,
      tab: 0,
      colors: [
        '#FF6384', // Rosa
        '#36A2EB', // Azul claro
        '#FFCE56', // Amarelo
        '#4BC0C0', // Turquesa
        '#9966FF', // Roxo claro
        '#FF9F40', // Laranja claro
        '#E74C3C', // Vermelho vibrante
        '#2ECC71', // Verde
        '#F1C40F', // Amarelo dourado
        '#9B59B6', // Roxo
        '#1ABC9C', // Turquesa escuro
        '#E67E22'  // Laranja queimado
      ],
      reportHeaders: [
        { text: "PRINCÍPIO ATIVO", value: "principle", align: "left" },
        { text: "POSITIVO", value: "positive", align: "center", cellClass: "text-center" },
        { text: "NEGATIVO", value: "negative", align: "center", cellClass: "text-center" },
        { text: "TOTAL", value: "total", align: "center", cellClass: "text-center" }
      ]
    };
  },

  computed: {
    options() {
      const labels = Object.keys(this.graph);
      const dataValues = labels.map(label => this.graph[label].total);

      return {
        tooltip: {
          trigger: 'item',
          formatter: function (param) {
            const name = param.name === 'S/PA' ? 'Sem Princípio Ativo' : param.name;
            return `${name}<br>${param.marker} ${param.seriesName}: <b>${param.value}</b> (${param.percent}%)`;
          }
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          top: 'center',
          textStyle: {
            color: '#ffffff',
            fontSize: 8
          }
        },
        series: [
          {
            name: 'Princípios Ativos',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '45%'],
            itemStyle: {
              borderRadius: 0
            },
            label: {
              show: true,
              color: '#fff',
              textBorderWidth: 0,
              textBorderColor: 'transparent',
              textShadowBlur: 0
            },
            data: labels.map((label, index) => ({
              value: dataValues[index],
              name: label,
              itemStyle: {
                color: this.colors[index % this.colors.length]
              }
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    },
    reportData() {
      const rows = Object.keys(this.graph).map(key => {
        const total = this.graph[key].total;
        const negative = this.graph[key].negative;
        const positive = total - negative;
        return {
          principle: key,
          positive,
          negative,
          total
        };
      });
      const grandTotal = rows.reduce((sum, row) => sum + row.total, 0);
      const grandNegative = rows.reduce((sum, row) => sum + row.negative, 0);
      const grandPositive = grandTotal - grandNegative;
      rows.push({
        principle: 'Total',
        positive: grandPositive,
        negative: grandNegative,
        total: grandTotal
      });
      return rows;
    }
  },

  watch: {
    period() {
      this.loadReport();
    }
  },

  created() {
    this.loadReport();
  },

  methods: {
    async loadReport() {
      this.loading = true;
      try {
        const [startDate, endDate] = this.period;

        let { data } = await this.$axios.post(
          '/descargaColeta/coletasPorPrincipioAtivo',
          { data_inicio: startDate, data_fim: endDate }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }
        this.graph = data.reduce((acc, cur) => {
          const key = cur.principio_ativo ? cur.principio_ativo : 'S/PA';
          acc[key] = {
            total: cur.total_registros,
            negative: cur.total_negativos
          };
          return acc;
        }, {});

        this.totalPrincipios = data.reduce((acc, cur) => acc + cur.total_registros, 0);
      } catch (e) {
        this.$snotify.error("Erro ao carregar os dados de antibióticos!", "Atenção");
        console.warn(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
