<template>
  <div class="mx-6">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="2"
          >
            <v-autocomplete
              v-model="filters.show"
              :items="[
                { text: 'Todos', value: 'all' },
                { text: 'Com Respostas', value: 'withAnswers' },
                { text: 'Sem Respostas', value: 'withoutAnswers' },
              ]"
              label="Exibir"
              placeholder=" "
              hide-details
              dark
            />
          </v-col>
          <v-col
            cols="2"
          >
            <v-autocomplete
              v-model="filters.types"
              :items="types"
              label="Tipos"
              placeholder=" "
              multiple
              hide-details
              dark
              clearable
              small-chips
              @change="loadQuestionnaires"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="3"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              append-icon="search"
              single-line
              hide-details
              clearable
              dark
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :search="filters.search"
        :headers="[
          { text: 'Questionário', value: 'title' },
          { text: 'Tipos', value: 'types', width: 400, },
          { text: 'Status', value: 'status', width: 30, },
          { text: '', value: 'operations', width: 320, align: 'end', sortable: false },
          { text: '', value: 'actions', width: 30, align: 'end', sortable: false },
        ]"
        :items="filteredList"
        item-key="id"
        single-expand
        dark
      >
        <template #item.types="{ value }">
          <v-chip
            v-for="type in value"
            :key="type"
            x-small
          >
            {{ getTypeLabel(type) }}
          </v-chip>
        </template>

        <template #item.status="{ value }">
          <v-chip
            x-small
            :color="value === 'ATIVO' ? 'light-green darken-3' : 'red darken-3'"
          >
            {{ value }}
          </v-chip>
        </template>

        <template #item.operations="{ item }">
          <v-tooltip
            v-if="item.status === 'ATIVO' && hasEditAccess"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                depressed
                :x-large="$vuetify.breakpoint.width < 1000"
                v-on="on"
                @click.stop="onChangeTab('aba-edit-questionnaires', item)"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>

            Editar Questionário
          </v-tooltip>

          <v-tooltip
            v-if="item.status === 'ATIVO'"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                depressed
                :x-large="$vuetify.breakpoint.width < 1000"
                v-on="on"
                @click.stop="printModel(item.id)"
              >
                <v-icon>
                  receipt_long
                </v-icon>
              </v-btn>
            </template>

            Modelo do questionário
          </v-tooltip>

          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                depressed
                :x-large="$vuetify.breakpoint.width < 1000"
                :disabled="item.totalAnswered === 0"
                v-on="on"
                @click.stop="onChangeTab('aba-charts', item)"
              >
                <v-icon>
                  bar_chart
                </v-icon>
              </v-btn>
            </template>

            Gráficos
          </v-tooltip>

          <v-tooltip
            v-if="['visits', 'project'].includes(view)"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                depressed
                :x-large="$vuetify.breakpoint.width < 1000"
                :disabled="item.totalAnswered === 0"
                v-on="on"
                @click.stop="onChangeTab('aba-reports', item)"
              >
                <v-icon>
                  assignment
                </v-icon>
              </v-btn>
            </template>

            Relatórios
          </v-tooltip>

          <v-badge
            :value="item.totalAnswered > 0"
            :content="item.totalAnswered"
            color="green darken-1"
            overlap
            dark
            offset-x="11"
            offset-y="11"
            class="mr-3"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  dark
                  icon
                  depressed
                  :x-large="$vuetify.breakpoint.width < 1000"
                  :disabled="['visits', 'project'].includes(view) && item.totalAnswered === 0"
                  v-on="on"
                  @click.stop="onChangeTab(['visits', 'project'].includes(view) ? 'aba-list-producer' : 'aba-answers', item)"
                >
                  <v-icon>
                    rate_review
                  </v-icon>
                </v-btn>
              </template>

              Respostas
            </v-tooltip>
          </v-badge>
        </template>

        <template #item.actions="{ item }">
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="['visits', 'project'].includes(view) && item.totalAnswered > 0"
                @click="onChangeTab('aba-list-answers-deleted', item)"
              >
                <v-list-item-icon>
                  <v-icon>rate_review</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Respostas Excluidas </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.totalAnswered > 0"
                @click="chartExportExcel(item.id)"
              >
                <v-list-item-icon>
                  <v-icon>backup_table</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Excel </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'ATIVO' && hasDeleteAccess"
                @click.stop="openConfirmDialog(item.title, item.id)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Deletar Questionário</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'ATIVO' && item.totalAnswered > 0"
                @click.stop="exportPoints(item)"
              >
                <v-list-item-icon>
                  <v-icon>download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Download Pontuação
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasPointsAccess"
                @click.stop="calculatePoints(item)"
              >
                <v-list-item-icon>
                  <v-icon>refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Calcular a Pontuação
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="hasInsertAccess"
        fab
        dark
        color="teal darken-1"
        @click="createQuestionnaire()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              note_add
            </v-icon>
          </template>
          Cadastro de Questionário
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay v-model="loading">
      Carregando ...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      />
    </v-overlay>

    <v-dialog
      v-model="confirmDeleteDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <span
            class="subtitle-1"
          >
            Deseja realmente excluir o questionário <span class="font-weight-medium">"{{ questionnaireAction.name }}"</span>?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pa-1"
            color="blue-grey darken-1"
            dark
            text
            @click="confirmDeleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="pa-1"
            color="blue darken-2 "
            dark
            text
            @click="deleteQuestionnaire"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx-js-style";
import * as _ from "lodash";
import printJS from "print-js";
import moment from "moment-timezone";

import QuestionnaireTypes from "@/Domains/Questionnaires/Constants/QuestionnaireTypes.js";

export default {

  props: {
    view: {
      type: String,
    },
  },

  data() {
    return {
      filters: {
        search: "",
        date: {
          input: "today",
          range: [],
        },
        show: "all",
        types: null,
      },

      questionnaires: [],

      expanded: [],

      loading: false,

      confirmDeleteDialog: false,

      questionnaireAction: {
        name: null,
        id: null,
        disabledEdition: false,
      },

      nameDelete: null,

      questionnaireIdDelete: null,

      actionMenu: false,

      questionnairesAnswered: [],
    };
  },

  computed: {

    filteredList() {
      return this.questionnaires.filter(questionnaire => {
        return this.checkAnswers(questionnaire);
      })
    },

    types() {
      console.log(this.view);
      return QuestionnaireTypes.filter(({ type }) => type === this.view);
    },

    typesMap() {
      return _.mapValues(_.keyBy(this.types, 'value'), 'text');
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    userId() {
      return this.$store.state.settings.user.id;
    },

    hasInsertAccess() {
      return this.userResources.some(o => o.recurso === 'questionnaire-insert' && o.tipo === 'COMPONENTE');
    },

    hasEditAccess() {
      return this.userResources.some(o => o.recurso === 'questionnaire-edit' && o.tipo === 'COMPONENTE');
    },

    hasDeleteAccess() {
      return this.userResources.some(o => o.recurso === 'questionnaire-delete' && o.tipo === 'COMPONENTE');
    },

    hasPointsAccess() {
      return this.userResources.some(o => o.recurso === 'questionnaire-calculate-points' && o.tipo === 'COMPONENTE');
    },
  },

  watch: {
    confirmDeleteDialog() {
      if (!this.confirmDeleteDialog) {
        this.questionnaireAction = {
          name: null,
          id: null
        }
      }
    },
  },

  mounted() {
    this.loadQuestionnaires();
  },

  methods: {
    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormulario`,
          {
            tipos: _.isEmpty(this.filters.types) ? _.map(this.types, 'value') : this.filters.types,
          }
        );

        this.questionnaires = data.map((item) => {
          return {
            id: item.id,
            title: item.titulo,
            totalAnswered: item.total_respostas,
            types: item.tipo,
            status: item.status,
            object: item.objeto,
            access: item.acessos,
          };
        });

        if (this.view === 'others' && !this.hasInsertAccess) {
          this.questionnaires = this.questionnaires.filter(item => item.access.includes(this.userId));
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @void
     * Método para altera a data do filtro
     */
    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadQuestionnaires();
    },

    /**
     * @void
     * Impressão do modelo do questionário
     */
    async printModel(id) {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/impressaoModeloQuestionario`,
          {
            id_formulario: id,
            tipo: this.view,
          }
        );

        if (data.codigo === 0) {
          throw data.mensagem;
        }

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao imprimir o itinerario!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async exportPoints(item) {

      try {
        this.loading = true;
        if (item.id) {

          const { data } = await this.$axios.post(
            `/formularioCustomizado/listaPontosProdutoresFormulario`,
            {
              id_formulario: item.id,
            }
          );

          let producers = XLSX.utils.json_to_sheet(data.map((item) => {
            return {
              Produtor: item.nome_produtor,
              codigo: item.codigo_laticinio,
              pontos: Math.abs(item.pontos).toFixed(0),
              data: moment(item.data).format('DD/MM/YYYY'),
            };
          }));

          const workbook = XLSX.utils.book_new();
          const filename = "Pontuação_Produtores";
          XLSX.utils.book_append_sheet(workbook, producers, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    //regravar pontos nas respostas do questionario
    async calculatePoints(item) {
      try {
        this.loading = true;
        if (item.id) {

          const { data } = await this.$axios.post(
            `/formularioCustomizado/gravarPontuacao`,
            {
              id_formulario: item.id,
            }
          );

          if (!data || data.codigo != 1) {
            throw data;
          }
          this.$snotify.success("Pontos gravados nas respostas", "Sucesso");
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @void
     * Exporta informacao excel
     */
    exportExcel() {
      let data = XLSX.utils.json_to_sheet(this.questionnaires.map((item) => {
        return {
          Questionário: item.title,
        };
      }));
      const workbook = XLSX.utils.book_new();
      const filename = "Questionário";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /**
     * Abre dialog de cadastro de questionário
     */
    createQuestionnaire() {
      this.$emit('newQuestionnaire');
    },

    openConfirmDialog(name, id) {
      this.questionnaireAction = {
        name,
        id
      };

      this.confirmDeleteDialog = true;
    },

    /**
     * Deleta questionário
     */
    async deleteQuestionnaire() {
      try {
        if (!this.questionnaireAction.id) {
          this.confirmDeleteDialog = false;
          throw "Identificador do formulário não encontrado";
        }

        const { data } = await this.$axios.post(
          `/formularioCustomizado/deletaFormularioCustomziado`,
          this.$qs.stringify({
            id_formulario: this.questionnaireAction.id,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP error";
        }

        if (!data.codigo) {
          this.$snotify.error("Oops, ocorreu um erro ao excluir o questionário!", "Atenção");
        }

        this.confirmDeleteDialog = false;
        this.$snotify.success("Sucesso, questionário excluido com sucesso !", "Sucesso");
        this.loadQuestionnaires();
      } catch (e) {
        console.log(e);
      }
    },

    async chartExportExcel(id) {
      try {
        let { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasFormulario`,
          {
            id_formulario: id,
            exibe_todas_respostas: true,
          }
        );

        if (!data) {
          data = [];
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          data = [];
        }

        const report = _(data).groupBy('id_formulario_customizado_cabecalho')
          .map((items) => ({
            Código: items[0].codigo_laticinio,
            Produtor: items[0].nome_produtor,
            Tecnico: items[0].nome_tecnico,
            Data: items[0].data_hora_registro,
            'Rota Principal': items[0].rota_principal,
            ...items.reduce((acc, cur) => ({
              ...acc,
              [cur.rotulo]: cur.resposta,
            }), {})
          })).value();

        let sheet = XLSX.utils.json_to_sheet(report);

        const workbook = XLSX.utils.book_new();
        const filename = "Questionários_Respondidos";
        XLSX.utils.book_append_sheet(workbook, sheet, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } catch (error) {
        console.warn(error);
      }
    },

    /**
     * @void
     *
     * Evento para abrir graficos de um determinado formulario
     */
    onChangeTab(tabName, value) {
      this.$emit("changeTab", {
        id: value.id,
        name: value.title,
        totalAnswered: value.totalAnswered,
        status: value.status,
        object: value.object,
        tab: tabName,
      });
    },

    /**
     * Trata evento de fechamento do dialog do questionário
     */
    onCloseDialog() {
      this.loadQuestionnaires();
      this.questionnaireAction = {
        name: null,
        id: null
      };
    },

    checkAnswers(questionnaire) {
      if (this.filters.show === 'withAnswers') {
        return questionnaire.totalAnswered > 0;
      }

      if (this.filters.show === 'withoutAnswers') {
        return !questionnaire.totalAnswered;
      }

      return true;
    },

    getTypeLabel(type) {
      return _.get(this.typesMap, type);
    },

  },
};
</script>
