<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-container
      row
      wrap
    />

    <v-row
      justify="center"
    >
      <v-col
        cols="12"
      >
        <h2 class="menu-header white--text">
          Expedição / Estado
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-card
      color="transparent"
      dark
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filter.dateRange"
              label="Data"
              dark
              @change="loadReport"
            />
          </v-col>

          <v-spacer />

          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filter.search"
              filled
              prepend-inner-icon="search"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <data-table
        ref="report"
        name="Expedição por Estado"
        dark
        :items="filteredItems"
        :headers="headers"
        group-by="end_estado"
      >
        <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
          <td
            v-for="(h, idx) in headers"
            :key="h.value"
            :class="h.align ? `text-${h.align}` : 'text-start'"
          >
            <template v-if="h.value === 'data-table-expand'">
              <v-btn
                icon
                @click="toggle"
              >
                <v-icon>
                  {{ isOpen ? 'remove' : 'add' }}
                </v-icon>
              </v-btn>
            </template>
            <template v-else-if="idx === 1">
              <b v-if="group">{{ group }}</b>
            </template>
            <template v-else-if="h.value === 'quantidade'">
              <v-chip
                small
              >
                {{ formatNumber(totals[group]?.quantidade) }}
              </v-chip>
            </template>
            <template v-else-if="h.value === 'quantidade_expedida'">
              <v-chip
                small
              >
                {{ formatNumber(totals[group]?.quantidade_expedida) }}
              </v-chip>
            </template>
            <template v-else-if="h.value === 'peso_bruto_expedido'">
              <v-chip
                small
              >
                {{ formatNumber(totals[group]?.peso_bruto_expedido) }} Kg
              </v-chip>
            </template>
            <template v-else-if="h.value === 'peso_liquido_expedido'">
              <v-chip
                small
              >
                {{ formatNumber(totals[group]?.peso_liquido_expedido) }} Kg
              </v-chip>
            </template>
          </td>
        </template>

        <template #[`item.quantidade`]="{ value, item }">
          {{ formatNumber(value) }}
          {{ item.unidade_medida }}
        </template>

        <template #[`item.quantidade_expedida`]="{ value, item }">
          {{ formatNumber(value) }}
          {{ item.unidade_medida }}
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'

const { progressBar, notify } = useUtils()
const filter = reactive({
  dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
  search: null
})

const headers = [
  { text: 'Cidade', value: 'end_estado' },
  { text: 'Produto', value: 'nome' },
  { text: 'Quantidade', value: 'quantidade', formatter: value => formatNumber(value), mask: '#,##0.0', show: false },
  { text: 'Qtde. Expedida', value: 'quantidade_expedida', formatter: value => formatNumber(value), mask: '#,##0.0', show: false },
  { text: 'Peso Expedido', value: 'peso_bruto_expedido', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.00' },
  { text: 'Peso Líq. Expedido', value: 'peso_liquido_expedido', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.00', show: false },
]

const report = ref()
const items = ref([])
const totals = ref({})

const filteredItems = computed(() => {
  if (!filter.search) {
    return items.value
  }

  const search = filter.search.toUpperCase().trim()

  return items.value.filter(item => {
    const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
    return hasSearch
  })
})

const loadReport = async () => {
  try {
    progressBar?.loading()

    const [startDate, endDate] = filter.dateRange

    const { data } = await axios.get(`/shipment/reports/by-state`, { params: {
      start_date: startDate,
      end_date: endDate
    } })

    items.value = data

    const group = groupBy(items.value, 'end_estado')

    totals.value = mapValues(group, (items) => {
      return {
        quantidade: items.reduce((acc, cur) => acc + parseFloat(cur.quantidade), 0),
        quantidade_expedida: items.reduce((acc, cur) => acc + parseFloat(cur.quantidade_expedida), 0),
        peso_bruto_expedido: items.reduce((acc, cur) => acc + parseFloat(cur.peso_bruto_expedido), 0),
        peso_liquido_expedido: items.reduce((acc, cur) => acc + parseFloat(cur.peso_liquido_expedido), 0),
      }
    })
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const getTitle = () => `Expedição por Estado - ${moment(filter.dateRange[0]).format('DD.MM')} - ${moment(filter.dateRange[1]).format('DD.MM')}`

const exportExcel = () => report.value?.exportExcel(null, getTitle())

const print = () => report.value?.print(null, getTitle())

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value)
</script>
