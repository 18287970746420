var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-select',{attrs:{"items":[
      { text: 'Últimos 3 meses', value: 'threeMonth' },
      { text: 'Últimos 6 meses', value: 'sixMonth' },
      { text: 'Últimos 8 meses', value: 'eightMonth' },
      { text: 'Últimos 10 meses', value: 'tenMonth' },
      { text: 'Últimos 12 meses', value: 'twelveMonth' },
      { text: 'Definir data', value: 'customRange' },
    ],"prepend-inner-icon":"today","dark":"","label":_vm.label,"hide-details":"","filled":"","clearable":_vm.clearable},on:{"click:clear":_vm.clear,"change":_vm.onDateFilterSelect},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(_vm.selected === 'customRange' && item.value === 'customRange')?[_c('span',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.startDate)+" — "+_vm._s(_vm.endDate)+" ")]),_c('v-btn',{attrs:{"icon":"","light":""},on:{"click":function($event){$event.stopPropagation();return _vm.clear.apply(null, arguments)}}},[_c('v-icon',[_vm._v("refresh")])],1)]:[_vm._v(" "+_vm._s(item.text)+" ")]]}},{key:"selection",fn:function({ item }){return [(_vm.selected === 'customRange' && _vm.hasDateRange)?_c('span',[_vm._v(" "+_vm._s(_vm.startDate)+" — "+_vm._s(_vm.endDate)+" ")]):_c('span',[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","content-class":"dialog-picker"},model:{value:(_vm.showSelectDate),callback:function ($$v) {_vm.showSelectDate=$$v},expression:"showSelectDate"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"max":_vm.maxDate,"range":"","reactive":""},on:{"change":_vm.onCustomDateRangeSelect},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }