<template>
  <div
    class="mx-6 px-7"
  >
    <v-flex
      xs12
      class="text-xs-center"
    >
      <h2
        class="menu-header white--text"
      >
        Checklist PMLS
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      v-model="aba"
      centered
      dark
      background-color="transparent"
      class="transparent"
      @change="onTabChange"
    >
      <v-tab href="#aba-general">
        Visão Geral
      </v-tab>
      <v-tab href="#aba-questionnaires">
        Questionários
      </v-tab>
      <v-tab
        href="#aba-edit-questionnaires"
        :disabled="questionnaire.id === null"
      >
        Inserir/Editar
      </v-tab>

      <v-tab
        href="#aba-reports"
        :disabled="questionnaire.id === null"
      >
        Relatórios
      </v-tab>

      <v-tab
        href="#aba-list-producer"
        :disabled="questionnaire.id === null"
      >
        Respostas
      </v-tab>
      <v-tab
        href="#aba-list-answers-deleted"
        :disabled="questionnaire.id === null"
      >
        Respostas Excluídas
      </v-tab>
      <v-tab href="#history">
        Configuração
      </v-tab>
      <v-tab
        href="#planning"
        :disabled="!planning.selected"
      >
        <v-icon>settings</v-icon>
      </v-tab>

      <v-tab-item value="aba-list-answers-deleted">
        <producer-by-questionnaire-list-deleted
          v-if="aba === 'aba-list-answers-deleted'"
          view="visits"
          project="PMLS"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>

      <v-tab-item value="aba-general">
        <questionnaires-report
          ref="general"
          project="PMLS"
        />
      </v-tab-item>

      <v-tab-item value="aba-questionnaires">
        <list-questionnaires
          v-if="aba === 'aba-questionnaires'"
          view="project"
          project="PMLS"
          @changeTab="handleChangeTab"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>
      <v-tab-item value="aba-edit-questionnaires">
        <create-questionnaire
          ref="createQuestionnaire"
          view="visits"
          :questionnaire-id="questionnaire.id"
          project="PMLS"
          :disabled="questionnaire.disabledEdition"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-reports"
      >
        <report-totals-by-question
          v-if="aba === 'aba-reports'"
          view="visits"
          project="PMLS"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
          :questionnaire-date-start="questionnaire.dateStart"
          :questionnaire-date-end="questionnaire.dateEnd"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-list-producer"
      >
        <producer-by-questionnaire-list
          v-if="aba === 'aba-list-producer'"
          view="visits"
          project="PMLS"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
          :questionnaire-date-start="questionnaire.dateStart"
          :questionnaire-date-end="questionnaire.dateEnd"
        />
      </v-tab-item>
      <v-tab-item value="history">
        <list-goals
          ref="hh"
          project="PMLS"
          @newPlanning="onNewPlanning"
          @selectPlanning="onSelectPlanning"
        />
      </v-tab-item>

      <v-tab-item value="planning">
        <goals
          ref="planning"
          v-model="planning.id"
          project="PMLS"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ListQuestionnaires from '@/Domains/Projects/Questionnaires/Components/ListQuestionnaires.vue';
import ReportTotalsByQuestion from '@/Domains/Questionnaires/Views/ReportTotalsByQuestion.vue'
import ProducerByQuestionnaireList from "@/Domains/Questionnaires/Views/ProducerByQuestionnaireList.vue";
import ListGoals from "@/Domains/Projects/ListGoals.vue";
import Goals from "@/Domains/Projects/Questionnaires/Views/Goals.vue";
import QuestionnairesReport from "@/Domains/Projects/Questionnaires/Components/QuestionnairesReport.vue";
import CreateQuestionnaire from "@/Domains/Questionnaires/Views/CreateQuestionnaire.vue";
import ProducerByQuestionnaireListDeleted from "@/Domains/Questionnaires/Views/ProducerByQuestionnaireListDeleted.vue";

import moment from "moment-timezone";

export default {
  components: {
    ListQuestionnaires,
    ReportTotalsByQuestion,
    ProducerByQuestionnaireList,
    ListGoals,
    Goals,
    QuestionnairesReport,
    CreateQuestionnaire,
    ProducerByQuestionnaireListDeleted,
  },

  data() {
    return {

      aba: 'aba-general',

      questionnaire: {
        id: null,
        name: null,
        dateStart: null,
        deteEnd: null,
        disabledEdition: false,
      },

      visibleCharts: true,

      planning: {
        selected: false,
        id: null
      },
    }
  },

  methods: {

    /**
         * Trata evento de naveção nas abas
         */
    handleChangeTab(data) {
      if (data.tab == 'aba-edit-questionnaires') {
        // Caso o questionário já possua resposta não permite editar
        const disabledEdition = data.totalAnswered > 0;
        this.questionnaire.disabledEdition = disabledEdition;
      }

      this.questionnaire.id = data.id;
      this.questionnaire.name = data.name;
      this.questionnaire.dateStart = moment(data.dateStart).format('YYYY-MM-DD') || null;
      this.questionnaire.dateEnd = moment(data.dateEnd).format('YYYY-MM-DD') || null;
      this.aba = data.tab
    },

    /**
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      if (["aba-questionnaires", "aba-questionnaires-answered"].includes(this.aba)) {
        this.questionnaire = { id: null, name: null };
      }

      if (this.aba == 'aba-general' && this.$refs.general) {
        this.$refs.general.loadProject()
      }
      if (this.aba == 'history' && this.$refs.hh) {
        this.$refs.hh.loadHistory();
      }
    },

    onNewPlanning() {
      this.planning.selected = true;
      this.planning.id = null;
      this.aba = 'planning';
    },

    onSelectPlanning(id) {
      this.planning.selected = true;
      this.planning.id = id;
      this.aba = 'planning';
    },

    onNewQuestionnaire() {
      this.questionnaire.id = '';
      this.questionnaire.disabledEdition = false;
      this.aba = 'aba-edit-questionnaires';
    },
  },
}
</script>
