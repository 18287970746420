<template>
  <div
    class="mx-6"
  >
    <questions-card :label="questionnaireName">
      <template #actions>
        <v-menu
          bottom
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changeChartType('column')">
              <v-list-item-icon>
                <v-icon>bar_chart</v-icon>
              </v-list-item-icon>
              <span>Gráficos de colunas</span>
            </v-list-item>
            <v-list-item @click="changeChartType('horizontal')">
              <v-list-item-icon>
                <v-icon>drag_handle</v-icon>
              </v-list-item-icon>
              <span>Gráficos de barras</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-if="withAnswers">
        <v-row>
          <v-col
            cols="6"
            class="py-0"
          >
            <v-tabs
              v-model="aba"
              dark
              background-color="transparent"
              class="transparent"
              style="margin-bottom: 15px"
            >
              <v-tab>
                <v-icon class="ma-1">
                  insert_chart_outlined
                </v-icon>
                Rótulo
              </v-tab>

              <v-tab v-if="withCategories">
                <v-icon class="ma-1">
                  insert_chart_outlined
                </v-icon>
                Categoria
              </v-tab>

              <v-tab>
                <v-icon class="ma-1">
                  insert_chart_outlined
                </v-icon>
                Total
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col
            v-if="view === 'visits'"
            cols="6"
            class="py-0"
          >
            <v-tabs
              v-model="answeredFilter"
              dark
              background-color="transparent"
              class="questionnaire-tabs transparent"
              right
            >
              <v-tab href="#all">
                <v-icon class="mr-2">
                  grading
                </v-icon>
                Todas as Respostas
              </v-tab>
              <v-tab href="#last-producer-answer">
                <v-icon class="mr-2">
                  rule
                </v-icon>
                Última Resposta
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-tabs-items v-model="aba">
          <v-tab-item>
            <v-row no-gutters>
              <v-col
                v-for="question in questions"
                :key="question.label"
                :cols="sizeBox(question.answers)"
                class="pa-0 ma-0"
              >
                <v-lazy
                  :value="true"
                  :options="{
                    threshold: 0.5,
                  }"
                  transition="fade-transition"
                >
                  <answers-chart-card
                    :label="question.label"
                    :question="question"
                    :legend="false"
                    :chart-type="chartType"
                    :type="chartAnswerType"
                  />
                </v-lazy>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item v-if="withCategories">
            <v-row no-gutters>
              <v-col
                v-for="(question, idx) in categories"
                :key="question.label + '-' + idx"
                :cols="sizeBox(question.answers)"
                class="pa-0 ma-0"
              >
                <v-lazy
                  :value="true"
                  :options="{
                    threshold: 0.5,
                  }"
                  transition="fade-transition"
                >
                  <answers-chart-card
                    :label="question.label"
                    :question="question"
                    :legend="false"
                    :chart-type="chartType"
                    :type="chartAnswerType"
                  />
                </v-lazy>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters>
              <v-col
                v-for="question in total"
                :key="question.label"
                cols="12"
                class="pa-0 ma-0"
              >
                <v-lazy
                  :value="true"
                  :options="{
                    threshold: 0.5,
                  }"
                  transition="fade-transition"
                >
                  <answers-chart-card
                    :label="question.label"
                    :question="question"
                    :legend="false"
                    :chart-type="chartType"
                    :type="chartAnswerType"
                  />
                </v-lazy>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template v-else>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="pa-1"
            style="margin-top: 5px"
          >
            <span style="color: white !important">Respostas não encontrados!</span>
          </v-col>
        </v-row>
      </template>
    </questions-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import * as _ from "lodash";
import XLSX from "xlsx-js-style";

import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import AnswersChartCard from "@/Domains/Questionnaires/Components/AnswersChartCard.vue";

export default {
  components: {
    QuestionsCard,
    AnswersChartCard,
  },

  props: {
    view: {
      type: String,
    },

    questionnaireId: {
      type: String,
    },

    questionnaireName: {
      type: String,
      default: "Resposta Questionário",
    },

    questionnaireDateStart: {
      type: String,
      default: null
    },

    questionnaireDateEnd: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      questionnaire: {
        id: "",
      },

      chartType: "horizontal",

      questions: [],
      categories: [],
      total: [],

      loading: false,

      answeredFilter: 'all',

      aba: null,
    };
  },

  computed: {
    withAnswers() {
      return this.questions.length > 0
    },

    withCategories() {
      return this.categories.length > 0
    },

    chartAnswerType() {
      return 'RESPOSTA';
    },
  },

  watch: {
    async answeredFilter() {
      await this.loadAnswers();
    }
  },

  async mounted() {
    await this.loadAnswers();
  },

  methods: {
    setloading: _.debounce(function (param) {
      this.loading = param;
    }, 1000),

    async loadAnswers() {
      try {
        this.loading = true;

        this.questions = [];
        this.categories = [];
        this.total = [];

        let [{ data: questionario }, { data }] = await Promise.all([
          this.$axios.post(
            `/formularioCustomizado/listaFormularioId`,
            this.$qs.stringify({ id_formulario: this.questionnaireId })
          ),
          this.$axios.post(
            `/formularioCustomizado/listaRespostasFormulario`,
            {
              id_formulario: this.questionnaireId,
              agrupa_respostas: this.view === 'visits' && this.answeredFilter !== 'all',
              data_inicio: this.questionnaireDateStart || null,
              data_fim: this.questionnaireDateEnd || null,
              tipo: this.view || null,
            }
          )
        ]);

        if (!data) {
          this.questions = [];
          this.categories = [];
          this.total = [];
          throw "PHP Error";
        }

        /**
         * Verifica se passou
         */
        if (_.has(_.head(data), "codigo")) {
          this.questions = [];
          this.categories = [];
          this.total = [];
          throw data.mensagem;
        }

        this.questions = _.orderBy(
          _.map(_.groupBy(data, "rotulo"), (itens) => {
            /**
             * conta quantidade de respostas e agrupando por resposta
             */
            const count = _.countBy(itens, (value) => {
              return value.resposta;
            });

            return {
              label: _.get(_.head(itens), "rotulo"),
              answered_questions: itens,
              count_by_answers: count,
              answers: JSON.parse(_.get(_.head(itens), "valores")),
            };
          }),
          (value) => value.answers.length
        );

        questionario = _.head(questionario);

        if (questionario && questionario.formulario.find(item => item.tipo === 'TITULO')) {
          const categories = this.getQuestionnaireCategory(questionario);

          const questionsWithTitles = data.map(item => ({
            titulo: categories[item.rotulo],
            grupo: `${categories[item.rotulo]}_${item.valores}`,
            ...item
          }));

          this.categories = _.orderBy(
            _.map(_.groupBy(questionsWithTitles, "grupo"), (itens) => {
              /**
               * conta quantidade de respostas e agrupando por resposta
               */
              const count = _.countBy(itens, (value) => {
                return value.resposta;
              });

              return {
                label: _.get(_.head(itens), "titulo"),
                answered_questions: itens,
                count_by_answers: count,
                answers: JSON.parse(_.get(_.head(itens), "valores")),
              };
            }),
            (value) => value.answers.length
          );
        }
        else {
          this.categories = [];
        }

        this.total = _.orderBy(
          _.map(_.groupBy(data, "valores"), (itens, index) => {
            /**
             * conta quantidade de respostas e agrupando por resposta
             */
            const count = _.countBy(itens, (value) => {
              return value.resposta;
            });

            return {
              label: JSON.parse(index || "['Total']").join(", "),
              answered_questions: itens,
              count_by_answers: count,
              answers: JSON.parse(_.get(_.head(itens), "valores")),
            };
          }),
          (value) => value.answers.length
        );

      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    getQuestionnaireCategory(questionario) {
      let titulo = '';
      return questionario.formulario.reduce((acc, cur) => {
        if (cur.tipo === 'TITULO') {
          titulo = cur.rotulo;
        }
        else {
          acc[cur.id] = titulo
        }
        return acc;
      }, []);
    },

    exportExcel() {
      const sheet = _.map(
        _.reduce(
          this.questions,
          (result, question) => {
            if (question) {
              result = _.concat(result, question.answered_questions);
            }

            return result;
          },
          []
        ),
        (data) => {
          return {
            Código: data.codigo_laticinio,
            Produtor: data.nome_produtor,
            Rotulo: data.rotulo,
            Resposta: data.resposta,
          };
        }
      );

      let data = XLSX.utils.json_to_sheet(sheet);
      const workbook = XLSX.utils.book_new();
      const filename = "Respostas_Questionário";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    /***
     * @returns int
     *
     * Recebe um array das possíves respostas e retornar o valor com o tamanho determinado para o bloco
     */
    sizeBox(array) {
      if (this.chartType === "horizontal") {
        return 12;
      }

      if (array.length > 10) {
        return 12;
      }

      if (array.length > 3) {
        return 6;
      }

      if (array.length == 3) {
        return 4;
      }

      return 3;
    },

    /**
     * Muda o tipo de  chafico de coluna para barra
     */
    changeChartType(type) {

      if (this.chartType === type) {
        return false;
      }

      if (_.isEmpty(type) || !_.includes(["column", "horizontal"], type)) {
        this.chartType = "column";
        return true;
      }

      this.loading = true;

      this.chartType = type;

      /**
       * Este método só roda depois de 1000ms
       */
      this.setloading(false);
    },
  },
};
</script>
