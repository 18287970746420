<template>
  <div class="person-autocomplete-filter">
    <v-autocomplete
      v-model="input"
      :loading="loading"
      :items="filteredResults"
      item-text="name"
      item-value="id"
      :prepend-inner-icon="prependInnerIcon"
      :label="label"
      :background-color="backgroundColor"
      :return-object="returnObject"
      :hide-details="hideDetails"
      :filled="filled"
      :clearable="clearable"
      :dark="dark"
      :placeholder="placeholder"
      :multiple="multiple"
      :chips="chips"
      :small-chips="chips"
      :deletable-chips="chips"
      :search-input.sync="search"
      no-filter
      :hint="hint"
      :persistent-hint="persistentHint"
      v-bind="$attrs"
      @change="onChange"
      @click:append-outer="$emit('click:append-outer')"
    >
      <template
        v-if="type === 'PRODUCER'"
        #no-data
      >
        <v-list-item>
          <v-list-item-title>
            Busque por <b>Código</b> ou <b>Nome</b> do Produtor
          </v-list-item-title>
        </v-list-item>
      </template>
      <template
        v-if="!chips && ['PRODUCER', 'CUSTOMER'].includes(type)"
        #selection="data"
      >
        <div
          v-if="data.item.code"
          class="mx-1 font-weight-light"
        >
          {{ data.item.code }} -
        </div>
        {{ data.item.name }}
        <div
          v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
          class="mx-1 caption font-weight-light"
        >
          ({{ data.item.status }})
        </div>
      </template>
      <template #item="data">
        <template v-if="!isObject(data.item)">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
              <v-icon
                v-if="showGroupings && (data.item.groupingId || data.item.grouping)"
                class="ml-2"
              >
                groups
              </v-icon>
            </v-list-item-title>
            <template v-if="showGroupings && data.item.grouping">
              <v-list-item-subtitle
                v-for="person of data.item.grouping"
                :key="person.id_pessoa"
                class="text-body-1 mt-2"
              >
                {{ person.codigo_nome }}
              </v-list-item-subtitle>
            </template>
            <v-list-item-subtitle v-else>
              <span
                v-if="data.item.code"
                class="mx-1"
                style="font-size: 0.890rem !important;"
              >
                {{ data.item.code }} {{ data.item.federalTaxNumber ? `- ${cnpjFormat(data.item.federalTaxNumber)}` : '' }}
              </span>
              <v-chip
                v-if="data.item.status && !['APROVADO', 'ATIVO'].includes(data.item.status)"
                x-small
                color="orange"
              >
                {{ data.item.status }}
              </v-chip>
              <span
                v-if="showGroupings && data.item.groupingName"
                class="mx-1"
              >
                {{ data.item.groupingName }}
              </span>
              <v-chip
                v-if="type === 'TECHNICAL'"
                x-small
                color="gray"
              >
                {{ data.item.profile }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<style>
.person-autocomplete-filter .v-autocomplete .v-input__control .v-text-field__details .v-messages .v-messages__wrapper {
  font-size: 0.900rem !important;
}
</style>

<script>
import _ from "lodash";
import IMask from "imask";

export default {

  props: {

    value: {
      type: [ Object, Array, String ],
    },

    type: {
      type: String,
    },

    project: {
      type: String,
    },

    label: {
      type: String,
    },

    backgroundColor: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    dark: {
      type: Boolean,
      default: false,
    },

    chips: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    filled: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    returnObject: {
      type: Boolean,
      default: true,
    },

    hint: {
      type: String,
    },

    persistentHint: {
      type: Boolean,
    },

    prependInnerIcon: {
      type: String,
      default: 'person',
    },

    showProducersAndGroups: {
      type: Boolean,
      default: false,
    },

    showFamilyGroup: {
      type: Boolean,
      default: true,
    },

    showGroupings: Boolean,
    showBonusProducers: Boolean,
  },

  data() {
    return {
      loading: false,

      input: [],

      persons: [],

      search: '',

      cnpjFormat(cnpj) {
        if (!cnpj) {
          return '-';
        }

        const masked = IMask.createMask({
          mask: '00.000.000/0000-00',
        });

        masked.resolve(cnpj);

        return masked.value;
      }
    };
  },

  computed: {
    filteredResults() {
      // 1. Early return if type is 'PRODUCER' or search is empty
      if (this.type === 'PRODUCER' || !this.search?.trim()) {
        return this.persons;
      }

      // 2. Prepare the search term for case-insensitive comparison
      const search = this.search.toLowerCase().trim();

      // 3. Filter the persons based on the search term
      const filtered = this.persons.filter(person => {
        const code = person.code?.toLowerCase() || '';
        const name = person.name?.toLowerCase() || '';
        const federalTaxNumber = person.federalTaxNumber?.toLowerCase() || '';

        return (
          code.includes(search) ||
          name.includes(search) ||
          federalTaxNumber.includes(search)
        );
      });

      // 4. Assign priority to each person based on the search term
      let prioritized = filtered.map(person => {
        let priority = 4; // Default priority

        const code = person.code ? person.code.toLowerCase() : '';
        const name = person.name ? person.name.toLowerCase() : '';
        const federalTaxNumber = person.federalTaxNumber ? person.federalTaxNumber.toLowerCase() : '';

        if (parseInt(code) === parseInt(search)) {
          priority = 0; // Exact match on code
        } else if (code.startsWith(search)) {
          priority = 1; // Code starts with search term
        } else if (federalTaxNumber.startsWith(search)) {
          priority = 2; // Federal Tax Number starts with search term
        } else if (name.startsWith(search)) {
          priority = 3; // Name starts with search term
        }

        return {
          ...person,
          priority,
        };
      });

      // 5. Sort the prioritized array
      prioritized = prioritized.sort((a, b) => a.priority - b.priority);

      // 6. Remove the temporary priority field before returning
      return prioritized.map(({ priority, ...rest }) => rest);
    },
  },

  watch: {
    value() {
      this.input = this.value;
      this.queryValue();
    },
    search (val) {
      if (this.type === 'PRODUCER') {
        this.queryProducers(val);
      }
    },
  },

  async mounted() {
    switch (this.type) {
      case 'DRIVER':
        await this.loadDrivers();
        break;
      case 'TECHNICAL':
      case 'TECHNICIAN':
        await this.loadTechnicals();
        break;
      case 'ANALYST':
        await this.loadAnalyst();
        break;
      case 'PRODUCER':
        break;
      case 'SUPPLIER':
      case 'SUPPLIER_ALL':
      case 'COMMERCIAL':
        await this.loadSuppliers();
        break;
      case 'CUSTOMER':
        await this.loadCustomers();
        break;
      case 'SELLER':
        await this.loadSellers();
        break;
      case 'DAIRY':
        await this.loadDairies();
        break;
      case 'CARRIER':
        await this.loadCarries();
        break;
    }

    this.input = this.value;
    this.queryValue();
  },

  methods: {
    queryValue() {
      if (this.type === 'PRODUCER') {
        this.queryProducers(this.value?.id || this.value);
      }
    },

    async loadTechnicals() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTecnicos`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            profile: person.cargo,
          };
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadDrivers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaMotorista`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadAnalyst() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaAnalistas`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            signature: person.assinatura,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os analistas!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    queryProducers: _.debounce(function (search) { this.loadProducers(search) }, 1000),

    async loadProducers(search) {
      const selected = this.returnObject ? this.input?.name : this.input;

      if (!search || search === selected) {
        return;
      }

      try {
        this.loading = true;

        let { data } = await this.$axios.post(
          `/produtores/listaJson`,
          {
            projeto: this.project,
            exibir_produtores_bonificacao: this.showBonusProducers ? 1 : 0,
            exibir_produtores_e_grupos: this.showProducersAndGroups ? 1 : 0,
            exibir_grupo_familiar: this.showFamilyGroup ? 1 : 0,
            busca: search
          }
        );

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          const grouping = this.showGroupings && person.tipo_condominio ? JSON.parse(person.condominio) : undefined
          return {
            id: person.id_pessoa,
            name: person.nome,
            code: person.codigo_laticinio,
            status: person.status,
            groupingId: person.id_condominio,
            groupingName: person.nome_condominio,
            grouping,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadSuppliers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/fornecedor/listaJson`, {"type": this.type});

        if (_.isString(data)) {
          throw data;
        }

        if (this.type === 'COMMERCIAL') {
          data = data.filter(item => item.grupo === 'COMERCIAL');
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os fornecedores!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadCustomers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/pessoa/listaClientes`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
            code: person.codigo_laticinio,
            status: person.status,
            region: person.id_regiao,
            federalTaxNumber: person.cnpj_cpf,
            sellers: person.pessoa_acesso?.map(seller => ({ id: seller.id_usuario, name: seller.nome_usuario })) || [],
            defaultDiscount: person.desconto_padrao,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os clientes!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadSellers() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/pessoa/listaVendedoresJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os clientes!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadDairies() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/pessoa/listaLaticinioJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os laticínios!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadCarries() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/pessoa/listaTransportadora`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => {
          return {
            id: person.id_pessoa,
            name: person.nome,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os técnicos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    onChange(event) {

      const newValue = event || (this.returnObject ? {} : null);

      // atualiza o v-model do componente
      this.$emit("input", newValue);

      this.$emit("change");
    },

    isObject: item => typeof item === 'object',
  },
};
</script>
