<template xmlns:span="http://www.w3.org/1999/html">
  <div>
    <v-dialog
      v-model="transferring.show"
      persistent
      width="80%"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      scrollable
      @close="closeDialogs"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Produção</span>
        </v-card-title>

        <v-card-text class="py-0">
          <v-container>
            <v-form
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    :value.sync="availableSilos"
                    :items="optionsSilos"
                    item-text="label"
                    item-value="id"
                    label="Silos *"
                    placeholder=" "
                    multiple
                    small-chips
                    deletable-chips
                    return-object
                    dense
                    :disabled="orderDisabled || !canEditVolume"
                    :rules="[v => v.length > 0 || 'Campo obrigatório!']"
                  >
                    <template #item="{ item }">
                      <v-list-item-content
                        @click.stop="onSiloSelect(item)"
                      >
                        <v-list-item-title>
                          {{ item.label }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="item.rawProduct.id">
                            {{ item.rawProduct.name }}
                          </span>
                          <v-chip small>
                            {{ item.vol }}L / {{ item.capacity }}L
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>

                    <template #selection="{ item }">
                      <v-chip x-small>
                        <span>{{ item.label }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-menu
                    v-model="transferring.form.transferredAt.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="transferring.form.transferredAt.date | date"
                        label="Data de Baixa *"
                        placeholder=" "
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="transferring.form.transferredAt.date"
                      no-title
                      @input="transferring.form.transferredAt.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col>
                  <masked-input
                    v-model="transferring.form.transferredAt.time"
                    label="Hora de Baixa *"
                    placeholder=" "
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :mask="{
                      mask: '00:00',
                    }"
                    :max-length="5"
                    dense
                    persistent-hint
                  />
                </v-col>
                <v-col>
                  <v-menu
                    v-model="transferring.form.processedAt.picker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="transferring.form.processedAt.date | date"
                        label="Data Produção *"
                        :rules="[v => !!v || 'Campo obrigatório!']"
                        persistent-hint
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="transferring.form.processedAt.date"
                      :min="transferring.form.transferredAt.date"
                      no-title
                      @input="transferring.form.processedAt.picker = false"
                    />
                  </v-menu>
                </v-col>
                <v-col>
                  <masked-input
                    v-model="transferring.form.processedAt.time"
                    label="Hora de Produção *"
                    :rules="[v => !!v || 'Campo obrigatório!']"
                    :mask="{
                      mask: '00:00',
                    }"
                    :max-length="5"
                    dense
                    persistent-hint
                  />
                </v-col>
                <v-col>
                  <person-autocomplete-filter
                    v-model="transferring.form.responsible"
                    label="Responsável"
                    type="ANALYST"
                    :filled="false"
                    dense
                  />
                </v-col>
              </v-row>

              <v-divider v-if="availableSilos.length" />

              <div
                v-for="silo of availableSilos"
                :key="silo.id"
              >
                <v-row class="pt-3">
                  <v-col
                    cols="6"
                    md="2"
                    class="pt-0"
                  >
                    <v-text-field
                      :value="silo.label"
                      label="Silo"
                      filled
                      dense
                      disabled
                      hide-details
                    >
                      <template #append>
                        <v-chip
                          small
                          class="mt-3"
                        >
                          <span>{{ silo.vol }}L / {{ silo.capacity }}L</span>
                        </v-chip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="pt-0"
                  >
                    <v-text-field
                      :value="silo.rawProduct.name"
                      label="Matéria Prima"
                      filled
                      dense
                      disabled
                      hide-details
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    md="4"
                    class="pt-0"
                  >
                    <masked-input
                      v-model.number="silo.transferringVol"
                      label="Volume (L) *"
                      :mask="IntNumberMask"
                      return-unmasked
                      filled
                      dense
                      :rules="getSiloRules(silo)"
                      :disabled="!canEditVolume"
                      @change="onWaterChange"
                    >
                      <template #append>
                        <v-btn
                          icon
                          :disabled="!canEditVolume"
                          @click="onSiloVolFillClick(silo)"
                        >
                          <v-icon>opacity</v-icon>
                        </v-btn>
                      </template>
                    </masked-input>
                  </v-col>

                  <v-col
                    cols="5"
                    md="3"
                    class="pt-0"
                  >
                    <v-text-field
                      :value="silo.batchNumber"
                      label="Nº Lote"
                      filled
                      dense
                      disabled
                      hide-details
                    />
                  </v-col>

                  <v-col
                    cols="1"
                    class="d-flex justify-center align-center pt-0"
                  >
                    <v-btn
                      icon
                      shaped
                      :disabled="orderDisabled || !canEditVolume"
                      @click="onRemoveSilo(silo)"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-row
                v-if="transferringVol"
                class="pa-0 justify-end"
              >
                <span
                  class="overline pr-2"
                  style="
                    font-size: 11px !important;
                    font-weight: bold;
                    line-height: 15px;
                  "
                >
                  Total: {{ transferringVol | Mask(IntNumberMask) }} L
                </span>
              </v-row>

              <v-divider
                v-if="transferring.form.silos.length"
                style="padding-bottom: 20px;"
              />

              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="transferring.form.productionOrder"
                    :items="options.productionOrders"
                    label="Ordem de Produção *"
                    placeholder=" "
                    item-value="id"
                    return-object
                    dense
                    :disabled="orderDisabled || !canEditVolume"
                    :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                    @change="onProductionOrder"
                  />
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="transferring.form.item"
                    label="Produto *"
                    placeholder=" "
                    :items="options.items"
                    :rules="[v => !!v.id || 'Campo obrigatório!']"
                    item-value="id"
                    item-text="description"
                    :disabled="!canEditVolume || (transferring.form.productionOrder && !!transferring.form.productionOrder.itemId)"
                    dense
                    return-object
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="transferring.form.tinas"
                    label="TINA"
                    placeholder=" "
                    :items="options.tina"
                    item-value="id"
                    item-text="description"
                    return-object
                    dense
                    multiple
                    required
                  >
                    <template #selection="{ item }">
                      <v-chip x-small>
                        <span>{{ item.description }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-tabs
                v-model="analysisTab"
              >
                <v-tab
                  v-for="silo of availableSilos"
                  :key="silo.id"
                >
                  {{ silo.label }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="analysisTab">
                <v-tab-item
                  v-for="silo of availableSilos"
                  :key="silo.index"
                >
                  <v-card flat>
                    <v-card-text class="px-0 pb-0">
                      <v-row>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.initialFat"
                            label="Gordura Inicial"
                            name="initialFat"
                            :mask="FloatNumberMask"
                            tabindex="1"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('acidity')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.acidity"
                            label="Acidez"
                            name="acidity"
                            :mask="FloatNumberMask"
                            tabindex="2"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('finalFat')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.finalFat"
                            label="Gordura Final"
                            name="finalFat"
                            :mask="FloatNumberMask"
                            tabindex="3"
                            currency
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('esd')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.esd"
                            label="ESD"
                            name="esd"
                            :mask="FloatNumberMask"
                            tabindex="5"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('protein')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.protein"
                            name="protein"
                            label="Proteína"
                            :mask="FloatNumberMask"
                            tabindex="6"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('milkWhey')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.milkWhey"
                            name="milkWhey"
                            label="Gordura Soro"
                            :mask="FloatNumberMask"
                            tabindex="7"
                            return-unmasked
                            dense
                            @onEnter="goToNextInput('crioscopy')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model.number="silo.analysis.crioscopy"
                            name="crioscopy"
                            label="Crioscopia"
                            :mask="FloatNumberMask"
                            tabindex="8"
                            return-unmasked
                            dense
                            @change="onWaterChange"
                            @onEnter="goToNextInput('waterPercentage')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.waterPercentage"
                            name="waterPercentage"
                            label="Água (%)"
                            :mask="FloatNumberMask"
                            tabindex="9"
                            return-unmasked
                            dense
                            @change="onWaterPercentageChange"
                            @onEnter="goToNextInput('waterLiters')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <masked-input
                            ref="analysis-elm"
                            v-model="silo.analysis.waterLiters"
                            name="waterLiters"
                            label="Água (L)"
                            :mask="FloatNumberMask"
                            tabindex="10"
                            dense
                            return-unmasked
                            @onEnter="goToNextInput('phosphatase')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <v-autocomplete
                            ref="analysis-elm"
                            v-model="silo.analysis.phosphatase"
                            name="phosphatase"
                            label="Fosfatase"
                            placeholder=" "
                            :items="[
                              { text: 'Positivo', value: true },
                              { text: 'Negativo', value: false },
                            ]"
                            tabindex="3"
                            dense
                            @onEnter="goToNextInput('peroxide')"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                          lg="2"
                        >
                          <v-autocomplete
                            ref="analysis-elm"
                            v-model="silo.analysis.peroxide"
                            name="peroxide"
                            label="Peroxidase"
                            placeholder=" "
                            :items="[
                              { text: 'Positivo', value: true },
                              { text: 'Negativo', value: false },
                            ]"
                            tabindex="4"
                            dense
                            @onEnter="goToNextInput('esd')"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
            <small>* Campo obrigatório</small>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogs()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isSaveAvailable"
            color="blue darken-1"
            text
            @click="onTransferringSaveClick"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import IMask from "imask";

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {
  components: {
    PersonAutocompleteFilter,
    MaskedInput,
  },

  filters: {

    date(value) {
      return moment(value).format('DD/MM/YYYY');
    },

    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  props: {
    orderDisabled: Boolean,
    history: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      // Tabs de análises dos silos
      analysisTab: 0,

      tabindex: 0,

      // Dialog data
      transferring: {
        show: false,
        form: {
          productionId: null,
          silos: [],
          transferredAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },
          processedAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },
          responsible: {},

          item: {},
          tinas: [],
          productionOrder: {},
        },
      },

      // Mascara usada nos campos numéricos inteiros
      IntNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      options: {
        silos: [],
        tina: [],
        items: [],
        productionOrders: [],
      }
    };
  },

  computed: {

    canEditVolume() {
      return !this.transferring.form.groupedProductionId
    },

    transferringVol() {
      return _.sumBy(this.availableSilos, 'transferringVol');
    },

    optionsSilos() {
      if (this.canEditVolume && this.transferring.form.productionOrder.id) {
        const { rawMaterialIds = [] } = this.transferring.form.productionOrder;

        return this.options.silos.filter(silo => rawMaterialIds.includes(silo.rawProduct.id));
      }

      return this.options.silos.filter(silo => silo.rawProduct && silo.rawProduct.id);
    },

    availableSilos() {
      return this.transferring.form.silos.filter(silo => silo.status === 'ACTIVE');
    },

    isSaveAvailable() {
      return this.transferringVol > 0;
    },

  },

  methods: {

    async show(transferring) {
      this.$root.$progressBar.loading();
      await this.loadOptions();

      return this.loadTransferringDetails(transferring);
    },

    async loadTransferringDetails(transferring) {
      try {
        const {
          transferredAt,
          processedAt,
          silo,
          silos,
          item,
          tinas,
          productionId,
          groupedProductionId,
          productionOrder,
          responsible,
        } = transferring;

        this.transferring = {
          show: true,
          form: {
            ...this.transferring.form,
            groupedProductionId,
            transferredAt: {
              picker: false,
              date: _.isEmpty(transferredAt) ? moment().format('YYYY-MM-DD') : moment(transferredAt).format('YYYY-MM-DD'),
              time: _.isEmpty(transferredAt) ? moment().format('HH:mm') : moment(transferredAt).format('HH:mm'),
            },
            processedAt: {
              datePicker: false,
              timePicker: false,
              date: _.isEmpty(processedAt) ? moment().format('YYYY-MM-DD') : moment(processedAt).format('YYYY-MM-DD'),
              time: _.isEmpty(processedAt) ? moment().format('HH:mm') : moment(processedAt).format('HH:mm'),
            },
            responsible: responsible || {},
            item: item || {},
            tinas: tinas || [],
            productionOrder: productionOrder
              ? {
                ...this.options.productionOrders.find(order => order.id === productionOrder.id),
                ...productionOrder,
              }
              : {},
          },
        };

        if (_.isEmpty(productionId)) {
          if (!_.isEmpty(silo)) {
            const transferringSilo = this.options.silos.find(siloOption => siloOption.id === silo.id);

            const transferringVol = this.getProductionOrderVol(transferringSilo);

            this.transferring.form = {
              ...this.transferring.form,
              silos: [
                {
                  ...transferringSilo,
                  transferringVol,
                  pending: true,
                  status: 'ACTIVE',
                  productionId: null,
                  analysis: {
                    initialFat: null,
                    acidity: null,
                    phosphatase: false,
                    peroxide: true,
                    esd: null,
                    finalFat: null,
                    protein: null,
                    milkWhey: null,
                    crioscopy: -0.5,
                    waterPercentage: null,
                    waterLiters: null,
                  },
                },
              ],
            }
          } else if (!_.isEmpty(silos)) {
            this.transferring.form = {
              ...this.transferring.form,
              silos: silos.map(silo => {
                const transferringSilo = this.options.silos.find(siloOption => siloOption.id === silo.id);
                return {
                  ...transferringSilo,
                  transferringVol: silo.transferringVol,
                  pending: true,
                  status: 'ACTIVE',
                  productionId: null,
                  analysis: {
                    initialFat: null,
                    acidity: null,
                    phosphatase: false,
                    peroxide: true,
                    esd: null,
                    finalFat: null,
                    protein: null,
                    milkWhey: null,
                    crioscopy: -0.5,
                    waterPercentage: null,
                    waterLiters: null,
                  },
                }
              }),
            }
          }

          return;
        }

        const { data } = await this.$axios.post(
          `/silo/detalheProducao`,
          {
            id_agrupador_producao: groupedProductionId,
          }
        );

        if (!_.isArray(data)) {
          throw 'Erro ao carregar os dados';
        }

        this.transferring.form.silos = data.map(siloTransferred => {
          const transferringSilo = this.options.silos.find(siloOption => siloOption.id === siloTransferred.id_silo);

          return {
            ...transferringSilo,
            batchNumber: siloTransferred.numero_lote,
            productionId: siloTransferred.id_producao,
            transferringVol: parseInt(siloTransferred.volume),
            status: siloTransferred.ativo ? 'ACTIVE' : 'INACTIVE',
            analysis: {
              initialFat: parseFloat(siloTransferred.gordura_inicial) || null,
              acidity: parseFloat(siloTransferred.acidez) || null,
              phosphatase: siloTransferred.fosfatase,
              peroxide: siloTransferred.peroxidase,
              esd: parseFloat(siloTransferred.esd) || null,
              finalFat: parseFloat(siloTransferred.gordura_final) || null,
              protein: parseFloat(siloTransferred.proteina) || null,
              milkWhey: parseFloat(siloTransferred.soro) || null,
              crioscopy: parseFloat(siloTransferred.crioscopia) || null,
              waterPercentage: parseFloat(siloTransferred.porcentagem_agua) || null,
              waterLiters: parseInt(siloTransferred.volume_agua) || null,
            },
          };
        });
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os detalhes da transferência!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    /**
     * Fecha todos os dialogos abertos na tela
     */
    closeDialogs() {
      this.transferring = {
        show: false,
        form: {
          productionId: null,

          silos: [],

          transferredAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          processedAt: {
            picker: false,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
          },

          responsible: {},

          item: {},
          tinas: [],
          productionOrder: {},
        },
      };
    },

    async loadOptions() {
      try {
        const [ silos, tina, items, productionOrders] = await Promise.all([
          this.$axios.get(`/silo/principal`),
          this.$axios.get(`/silo/listaTina`),
          this.$axios.get(`/item`, { params: { tipos_item: ['PRODUCAO'] } }),
          this.$axios.get(`/production-order`, { params: { status: 'LIBERADO', with: 'ITENS' } }),
        ]);

        if (!_.isArray(tina.data) || !_.isArray(items.data) || !_.isArray(silos.data.silo)) {
          throw "PHP Error";
        }

        this.options.tina = tina.data.map(tina => ({ id: tina.id_tina, description: tina.descricao }));
        this.options.items = items.data.map(item => {
          return {
            id: item.id_item,
            code: item.cod_item,
            description: `${item.cod_item} - ${item.nome}`,
          };
        });
        this.options.silos = silos.data.silo.map(silo => {
          return {
            index: (silo.descricao || '').replace(/\D/g, ''),
            id: silo.id_silo,
            label: silo.descricao,
            batchNumber: silo.numero_lote,
            vol: parseInt(silo.volume_atual),
            capacity: parseInt(silo.volume_total),
            transferringVol: null,
            productionId: null,
            analysis: {
              initialFat: null,
              acidity: null,
              phosphatase: false,
              peroxide: true,
              esd: null,
              finalFat: null,
              protein: null,
              milkWhey: null,
              crioscopy: -0.5,
              waterPercentage: null,
              waterLiters: null,
            },
            rawProduct: {
              id: silo.id_materia_prima,
              name: silo.nome_materia_prima,
            },
          }
        }).sort((a, b) => a.index - b.index);

        this.options.productionOrders = productionOrders.data.map(order => ({
          id: order.id_ordem_producao,
          text: `${order.codigo} - ${order.descricao}`,
          itemId: order.id_item,
          rawMaterialIds: order.itens
            .map(item => item.id_item),
          items: order.itens
            .map((item) => ({
              id: item.id_ordem_producao_item,
              rawMaterialId: item.id_item,
              quantity: parseFloat(item.quantidade_base),
              plannedQuantity: parseFloat(item.quantidade_base) * parseFloat(order.quantidade_planejada),
              accomplishedQuantity: parseFloat(item.quantidade_realizada),
            })),
        }));

      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os silos!",
          "Atenção"
        );
      }
    },

    onSiloVolFillClick(transferringSilo) {
      this.transferring.form.silos = this.transferring.form.silos.map(silo => {

        if (transferringSilo.id !== silo.id) {
          return silo;
        }

        return {
          ...silo,
          transferringVol: silo.vol,
        }
      });
    },

    getSiloRules(transfer) {
      if (!this.canEditVolume) {
        return [];
      }

      const rules = [
        v => !!v || 'Informe o volume',
        v => v > 0 || 'Volume não pode ser negativo',
      ]

      if (!this.history && this.$store.state.settings.gerais.validar_saldo_silo) {
        rules.push(() => transfer.transferringVol <= transfer.vol || 'Volume indisponível no silo')
      }

      return rules
    },

    onTransferringSaveClick: _.debounce(function () {
      const valid = this.$refs.form.validate();

      if (!valid) {
        const errors = this.$refs.form.inputs
          .filter(item => item.hasError)
          .map(item => item.label ? `<span><b>${item.label.replace(' *', '')}</b>: ${item.errorBucket[0]}</span>` : `<b>${item.errorBucket[0]}</b>`)

        if (errors.length) {
          this.$snotify.error('Atenção', { html: `<div class="text-h4">Atenção</div>${errors.join('\n')}` })
        }

        return;
      }

      return this.saveTransferring(this.transferring.form);
    }, 500),

    async saveTransferring(form) {
      try {
        this.$root.$progressBar.saving();

        const transferredAt = `${form.transferredAt.date} ${form.transferredAt.time}`;
        const processedAt = `${form.processedAt.date} ${form.processedAt.time}`;

        const transfers = form.silos.map(silo => {
          return {
            id_producao: silo.productionId,
            id_agrupador_producao: form.groupedProductionId,
            volume: silo.transferringVol,
            id_silo: silo.id,
            numero_lote: silo.batchNumber,
            data_baixa: transferredAt,
            data_producao: processedAt,
            id_responsavel: form.responsible?.id,
            nome_responsavel: form.responsible?.name,
            tinas: form.tinas,
            nome_item: form.item.description,
            id_item: form.item.id,
            id_ordem_producao: form.productionOrder.id,
            ativo: silo.status === 'ACTIVE',

            gordura_inicial: silo.analysis.initialFat,
            acidez: silo.analysis.acidity,
            gordura_final: silo.analysis.finalFat,
            fosfatase: silo.analysis.phosphatase,
            peroxidase: silo.analysis.peroxide,
            esd: silo.analysis.esd,
            proteina: silo.analysis.protein,
            soro: silo.analysis.milkWhey,
            crioscopia: silo.analysis.crioscopy,
            porcentagem_agua: silo.analysis.waterPercentage,
            volume_agua: silo.analysis.waterLiters, // novo
          }
        });

        const { data } = await this.$axios.post(
          `/silo/transferenciaProducao`,
          {
            transferencias: transfers,
          },
        );

        const { codigo } = data;

        if (!codigo) {
          throw "PHP Error";
        }

        this.$emit('onSiloTransferred', form);

        return this.closeDialogs();
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar a transferência!",
          "Atenção"
        );
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    onWaterChange() {
      this.transferring.form.silos = this.transferring.form.silos.map(silo => {

        if (!silo.analysis.crioscopy || !silo.transferringVol) {
          silo.analysis.waterPercentage = 0;
          silo.analysis.waterLiters = 0;

          return silo;
        }

        const crioscopy = Math.abs(silo.analysis.crioscopy) * 1000;

        if (crioscopy >= 535) {
          silo.analysis.waterPercentage = 0;
          silo.analysis.waterLiters = 0;

          return silo;
        }

        const liters = (((crioscopy - 535) * 100) / 535) * silo.transferringVol;
        const waterPercentage = (liters / silo.transferringVol) || 0;

        silo.analysis.waterPercentage =  Math.abs(waterPercentage);

        return silo;
      });

      return this.onWaterPercentageChange();
    },

    onWaterPercentageChange() {
      this.transferring.form.silos = this.transferring.form.silos.map(silo => {
        // Caso não tenha volume indentificado, ou o % seja 0, não é possível calcular o volume de água
        if (!silo.transferringVol || silo.analysis.waterPercentage <= 0) {
          return silo;
        }

        const vol = (silo.transferringVol  * (silo.analysis.waterPercentage / 100)) || 0;

        silo.analysis.waterLiters = vol.toFixed(1);

        return silo;
      });
    },

    onRemoveSilo(silo) {
      this.transferring.form.silos = this.transferring.form.silos.map(transferringSilo => {

        if (transferringSilo.id === silo.id) {
          return {
            ...transferringSilo,
            status: 'INACTIVE',
          }
        }

        return transferringSilo;
      });
    },

    onSiloSelect(silo) {

      const hasSiloInList = this.transferring.form.silos.find(listedSilo => listedSilo.id === silo.id);

      if (!hasSiloInList) {
        const transferringVol = this.getProductionOrderVol(silo);

        this.transferring.form.silos = [
          ...this.transferring.form.silos,
          {
            ...silo,
            transferringVol,
            pending: true,
            status: 'ACTIVE',
          },
        ];

        return;
      }

      if (hasSiloInList.status === 'INACTIVE') {
        this.transferring.form.silos = this.transferring.form.silos.map(listedSilo => {

          if (listedSilo.id === silo.id) {
            return {
              ...listedSilo,
              status: 'ACTIVE',
            }
          }

          return listedSilo;
        });

        return;
      }

      if (hasSiloInList.pending) {
        const removedSiloIndex = this.transferring.form.silos.findIndex(listedSilo => listedSilo.id === silo.id);

        this.transferring.form.silos.splice(removedSiloIndex);

        return;
      }

      return this.onRemoveSilo(silo);
    },

    onProductionOrder({ itemId = null, rawMaterialIds = [] }) {
      if (itemId) {
        this.transferring.form.item = { id: itemId }
      }

      // Remove os silos selecionados com matérias-primas que não estão na ordem de produção
      this.availableSilos
        .filter(silo => !rawMaterialIds.includes(silo.rawProduct.id))
        .forEach(silo => this.onSiloSelect(silo))
    },

    getProductionOrderVol(silo) {
      let transferringVol = null;

      const productionOrder = this.transferring.form.productionOrder;

      if (productionOrder.id) {
        const item = productionOrder.items.find(item => item.rawMaterialId === silo.rawProduct.id);

        if (item) {
          const availableVolume = item.plannedQuantity - item.accomplishedQuantity;

          if (availableVolume > 0) {
            transferringVol = availableVolume;
          }
        }
      }

      return transferringVol
    },

    goToNextInput(nextInputName) {
      const inputs = this.$refs['analysis-elm'];

      const nextElm = inputs.find(elm => {
        return elm.$attrs.name === nextInputName
      });

      if (_.isEmpty(nextElm)) {
        return;
      }

      if (!_.isFunction(nextElm.focus)) {
        return;
      }

      return nextElm.focus();
    },

  },

}
</script>
