<template>
  <div>
    <div
      v-if="loading || saving"
      class="d-flex flex-column pa-4 align-center"
    >
      {{ loading ? 'Carregando...' : 'Salvando...' }}
      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="6"
      />
    </div>
    <div
      v-else
      class="d-flex pa-3"
    >
      <v-badge
        :value="shipment.status !== 'PROCESSING'"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShipmentLoad()"
            >
              <v-icon>
                conveyor_belt
              </v-icon>
            </v-btn>
          </template>

          Carregamento
        </v-tooltip>
      </v-badge>

      <v-badge
        :value="shipment.status === 'FINISHED'"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShipmentPacking()"
            >
              <v-icon>
                inventory_2
              </v-icon>
            </v-btn>
          </template>

          Montagem da Carga
        </v-tooltip>
      </v-badge>

      <v-menu
        v-if="checklists?.length > 0"
        bottom
        left
        :min-width="200"
      >
        <template #activator="{ on: dialog }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                dark
                icon
                depressed
                offset-x="11"
                offset-y="11"
                class="mr-3"
                :x-large="$vuetify.breakpoint.width < 1000"
                v-on="{ ...dialog, ...tooltip }"
              >
                <v-icon>
                  receipt_long
                </v-icon>
              </v-btn>
            </template>

            Checklists
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(checklist, index) in checklists"
            :key="index"
            @click="showChecklist(checklist)"
          >
            <v-list-item-icon>
              <v-badge
                :value="!!checklist.answerId"
                color="green darken-1"
                icon="verified"
                overlap
                dark
              >
                <v-icon color="secondary">
                  receipt_long
                </v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title>
              {{ checklist.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-badge
        v-if="shipment.status !== 'FINISHED'"
        :value="false"
        color="green darken-1"
        icon="verified"
        overlap
        dark
        offset-x="11"
        offset-y="11"
        class="mr-3"
      >
        <v-tooltip
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              dark
              icon
              depressed
              v-on="on"
              @click.stop="onShowConclusionDialog()"
            >
              <v-icon>
                done_all
              </v-icon>
            </v-btn>
          </template>

          Concluir
        </v-tooltip>
      </v-badge>
    </div>

    <questionnaire-form-dialog
      ref="questionnaireFormDialog"
      :headers="questionnaireHeaders"
      @save="reloadShipments"
    />
  </div>
</template>

<script>
import api from '@/Domains/Shipment/Api/Shipment.js'
import QuestionnaireFormDialog from '@/Domains/Questionnaires/Components/QuestionnaireFormDialog.vue'

import moment from 'moment'

export default {

  components: {
    QuestionnaireFormDialog,
  },

  props: {
    shipment: {
      type: Object,
      default: () => ({}),
    },

  },

  data() {
    return {
      loading: false,
      saving: false,

      checklists: [],
    }
  },

  computed: {
    questionnaireHeaders() {
      return [
        { title: 'Código Carga', value: this.shipment.code },
        { title: 'Veículo', value: this.shipment.vehicle.plate },
        { title: 'Motorista', value: this.shipment.driver.name },
        { title: 'Data Carregamento', value: this.formatDate(this.shipment.operationTime, 'DD/MM/YYYY') },
      ]
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    userId() {
      return this.$store.state.settings.user.id;
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasConcludeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'expedicao-concluir' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    if (this.isAdmin) {
      this.checklists = this.shipment.checklists || []
    } else {
      this.checklists = this.shipment.checklists?.filter(item => item.access.includes(this.userId)) || [];
    }
  },

  methods: {

    async onShipmentLoad() {
      this.$emit('showShipmentLoad', this.shipment.id);
    },

    async onShipmentPacking() {
      this.$emit('showShipmentPacking', this.shipment.id);
    },

    async onShowConclusionDialog() {
      if (!(await this.$root.$confirm('Atenção', `Deseja concluir esta expedição?`, { color: 'green' }))) {
        return;
      }

      try {
        this.saving = true;

        const payload = { estado: 'FINALIZADO' }

        if (!this.shipment.operationTime) {
          payload.data_hora_descarga = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        await api.update(this.shipment.id, payload);

        this.$snotify.success('Expedição finalizada com sucesso', 'Sucesso');
        this.reloadShipments();
      } catch (e) {
        this.$snotify.error('Erro ao atualizar expedição', 'Atenção');
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },

    reloadShipments() {
      this.$emit('reloadShipments');
    },

    async showChecklist({ id, answerId }) {
      this.$refs.questionnaireFormDialog.show({
        id,
        answerId,
        object: {
          id: this.shipment.id,
          description: `${this.shipment.code || this.shipment.itinerary.code}`,
        }
      });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
  }
}
</script>
