<template>
  <v-dialog
    v-model="dialog"
    width="350"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Medicamento
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="close()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-select
                v-model="form.type"
                label="Tipo"
                :items="['ANTIBIOTICO', 'NORMATIVA']"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              v-if="form.type == 'ANTIBIOTICO'"
              cols="12"
              class="py-0"
            >
              <v-autocomplete
                v-model="form.activeIngredientId"
                :items="activeIngredients"
                label="Princípio Ativo"
                item-value="id"
                item-text="description"
                :filter="(person, queryText, itemText) => person.search.indexOf(queryText.toLocaleLowerCase()) > -1"
                :rules="[v => !!v || 'Campo obrigatório!']"
              >
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.description }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip
                        v-for="group of item.groups"
                        :key="group.id"
                        x-small
                      >
                        {{ group.description }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="form.description"
                label="Descrição"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { notify, progressBar } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()
const form = reactive({
  id: null,
  type: null,
  activeIngredientId: null,
  description: null,
})

const show = async (item = {}) => {
  form.id = item.id
  form.type = item.type
  form.activeIngredientId = item.activeIngredientId
  form.description = item.description
  dialog.value = true
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.saving()

    const payload = {
      tipo: form.type,
      descricao: form.description,
      id_principio_ativo: form.activeIngredientId,
    };

    form.id
      ? await axios.put(`/registrations/antibiotic/medicine/${form.id}`, payload)
      : await axios.post(`/registrations/antibiotic/medicine/`, payload)

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const close = () => {
  form.id = null
  form.description = null
  dialog.value = false
}

const activeIngredients = ref([])

const loadActiveIngredients = async () => {
  try {
    const { data } = await axios.get(`/registrations/antibiotic/active-ingredient`)

    activeIngredients.value = data.map(item => ({
      id: item.id,
      description: item.descricao,
      groups: item.grupos.map(group => ({
        id: group.id,
        description: group.descricao
      })),
      search: `${item.descricao} - ${item.grupos.map((group) => group.descricao).join(', ')}`.toLocaleLowerCase(),
    }))
  } catch (err) {
    console.warn(err)
    notify.error('Oops, ocorreu um erro ao carregar os grupos!', 'Atenção')
  }
}

loadActiveIngredients()

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>

