<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        Transferir Pallet de Unidade
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
        >
          <div class="d-flex justify-space-between mb-4">
            <div class="flex-grow-1">
              <div class="text-caption">
                Pallets
              </div>

              <div class="d-flex mb-4">
                <v-chip
                  v-for="pallet in form.pallets"
                  :key="pallet.id"
                  class="mr-2"
                >
                  {{ pallet.descricao }}
                </v-chip>
              </div>
            </div>

            <div class="flex-grow-1 mb-4">
              <div class="text-caption">
                Peso Bruto
              </div>

              <div class="text-body-1 secondary--text">
                {{ formatNumber(grossWeight) }} Kg
              </div>
            </div>

            <div class="flex-grow-1 mb-4">
              <div class="text-caption">
                Peso Líquido
              </div>

              <div class="text-body-1 secondary--text">
                {{ formatNumber(netWeight) }} Kg
              </div>
            </div>
          </div>

          <v-select
            v-model="form.dairyId"
            label="Destino"
            :items="dairies"
            item-value="id"
            item-text="description"
            :rules="[v => !!v || 'Campo obrigatório!']"
            @change="onDairyChange"
          />

          <v-select
            v-if="form.dairyId"
            v-model="form.destinationId"
            label="Depósito"
            :items="dairyWarehouses"
            item-value="id"
            item-text="description"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { notify, progressBar } = useUtils()

const formatNumber = val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val)

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const formRef = ref()
const dialog = ref(false)

const form = reactive({
  pallets: [],
  dairyId: null,
  destinationId: null,
})

const grossWeight = computed(() => form.pallets.reduce((acc, cur) => acc + (cur.peso_bruto || 0), 0))

const netWeight = computed(() => form.pallets.reduce((acc, cur) => acc + (cur.peso_liquido || 0), 0))

const show = (pallets) => {
  dialog.value = true

  form.pallets = [...pallets]
}

const save = async () => {
  if (!await formRef.value?.validate()) {
    return
  }

  try {
    progressBar?.saving()

    const payload = {
      ids_pallets: form.pallets.map(p => p.id),
      id_laticinio_destino: form.dairyId,
      id_deposito_destino: form.destinationId,
    }

    await axios.post('pallet/transfer', payload)

    emit('save')
    close()
  } catch (error) {
    console.error(error)
    notify.error('Oops, ocorreu um erro ao transferir!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const close = () => {
  form.pallets = []
  form.dairyId = null
  form.destinationId = null
  dialog.value = false

  formRef.value?.reset()
}

const warehouses = ref([])

const dairies = ref([])

const dairyWarehouses = computed(() => warehouses.value.filter(w => w.dairyId === form.dairyId))

const loadDairies = async () => {
  try {
    const { data } = await axios.get(`/warehouse`, { params: { only_externals: 1 } })

    warehouses.value = data.map(item => ({
      id: item.id_deposito,
      description: item.descricao,
      dairyId: item.id_laticinio,
    }))

    dairies.value = data.reduce((acc, cur) => {
      if (!acc.find(o => o.id === cur.id_laticinio)) {
        acc.push({
          id: cur.id_laticinio,
          description: cur.nome_laticinio
        })
      }

      return acc
    }, [])

  } catch (error) {
    console.log(error)

    notify.error('Oops, ocorreu um erro ao carregar os laticínios!', 'Atenção')
  }
}

// Auto seleciona o depósito Geral, que possui o mesmo ID do laticinio
const onDairyChange = (value) => {
  console.log({ value })
  if (!value || !warehouses.value.find(w => w.id === value)) {
    console.log({ value: null })
    form.destinationId = null
  }

  form.destinationId = value
}

loadDairies()

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>
