<template>
  <div>
    <v-chart
      :options="option"
      :style="{width: '100%', height: `${height}px !important`}"
      autoresize
    />
  </div>
</template>

<script>
import  _ from "lodash";

export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Boolean,
      default: false,
    },
    abountQuestion: {
      type: Object,
    },
    category: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Gráfico',
    },
  },
  data() {
    return {
      option: {
        color: ["#675bba"],
        title: {
          left: 10,
          top: 10,
          text: this.title,
          textStyle: {
            fontSize: 14,
          },
        },
        toolbox: {
          top: 30,
          left: 10,
          feature: {
            saveAsImage: {
              title: '',
              backgroundColor: '#FFFF',
            }
          }
        },
        tooltip: {
          axisPointer: {
            type: "shadow",
          },
          position: "top",
          formatter: (param) => [
            param.seriesName,
            `${param.marker}${param.name}: <b>${param.value}</b>%`,
            _.has(param, 'data.totalByAnswer') && param.data.totalByAnswer ? `Total: <b>${param.data.totalByAnswer}</b>` : "",
          ].join('<br>')
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: this.category,
          name: "%",
          axisLabel: {
            rotate: 0,
            margin: 6,
            showMinLabel: true,
            axisTick: {
              alignWithLabel: true,
            },
            fontSize: 8.6
          },
        },
        grid: {
          left: "25%",
          right: "3%",
          bottom: 40,
          top: 67,
        },
        series: this.series,
      },
    };
  },

  computed: {
    height() {
      return 110 + (this.category.length * 30)
    }
  },
};
</script>
