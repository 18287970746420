<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="300px"
  >
    <v-card>
      <v-card-title>
        Configuração de valores
      </v-card-title>
      <v-divider />
      <v-card-text
        class="pt-5"
        style="max-height: calc(100vh - 250px);"
      >
        <v-form
          ref="form"
          lazy-validation
          @submit="saveSettings"
        >
          <v-text-field
            v-model="samplePrice"
            label="Preço por amostra"
            prefix="R$"
            type="number"
            placeholder=" "
            @keypress="disableDot"
          />
          <v-text-field
            v-model="freeSamples"
            label="Qtde. amostras/testes grátis"
            type="number"
            placeholder=" "
            @keypress="disableDotAndComma"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="() => show = false"
        >
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :loading="saving"
          @click="saveSettings"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },

  data() {
    return {
      saving: false,
      samplePrice: null,
      freeSamples: null,
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.getSettings();
      }
    }
  },

  methods: {
    getSettings() {
      if (this.$store.state.settings.gerais.amostras) {
        this.samplePrice = this.$store.state.settings.gerais.amostras.valor_amostra || null;
        this.freeSamples = this.$store.state.settings.gerais.amostras.amostras_gratis || null;
      }
    },

    async saveSettings() {
      this.saving = true;
      try {
        const payLoad = {
          configuracaoQualidade: JSON.stringify([{
            amostras: {
              valor_amostra: this.samplePrice,
              amostras_gratis: this.freeSamples,
            }
          }]),
        };

        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify(payLoad)
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
        this.show = false;
        this.$snotify.success("Configurações salvas com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      } finally {
        this.saving = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  }
}
</script>
