<template>
  <div class="mx-6 mb-6">
    <questions-card
      :label="questionnaireName"
    >
      <v-card
        dark
        color="transparent"
      >
        <v-card-title class="pb-0">
          <v-row
            class="pa-0"
            align-content="center"
          >
            <v-col
              md="3"
            >
              <date-range-picker
                v-model="filters.date.input"
                @change="onDateFilter"
              />
            </v-col>

            <v-col
              md="3"
              offset="6"
            >
              <v-text-field
                v-model="filters.search"
                filled
                label="Buscar"
                append-icon="search"
                single-line
                hide-details
                clearable
                dark
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          v-model="selected"
          dark
          :items="answers"
          :search="filters.search"
          show-select
          :headers="[
            { text: 'Preenchido', value: 'personName', width: '350' },
            { text: 'Objeto', value: 'object' },
            { text: 'Questionário', value: 'questionnaire' },
            { text: 'Dada de Preenchimento', value: 'responseAt', width: '150' },
            { text: 'Codigo', value: 'code', align:'center'},
            { text: '', value: 'actions', sortable: false, align: 'end', width: 20 },
          ]"
        >
          <template #[`item.questionnaire`]="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  color="orange lighten-3"
                  dark
                  icon
                  v-on="on"
                  @click="showQuestionnaire(item)"
                >
                  <v-icon>receipt_long</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </template>
          <template #item.responseAt="{ item }">
            <v-chip x-small>
              {{ formatDate(item.responseAt, 'DD/MM/YYYY') }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  small
                  @click="showChecklist(item)"
                >
                  <v-list-item-icon>
                    <v-icon>edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="hasDeleteAnswersAccess"
                  small
                  @click="confirmDeleteAnswer(item)"
                >
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Excluir</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div
          class="text-caption text-left ml-4"
          style="margin-top: -58px; height: 58px; line-height: 58px;"
        >
          <v-col
            md="4"
            align-self="center"
            class="pa-0"
          >
            <transition name="fade-transition">
              <v-btn
                v-show="selected.length > 0"
                fab
                text
                class="pa-0 ma-0"
                style="margin-left: -16px !important;"
                dark
                @click="print"
              >
                <v-icon>get_app</v-icon>
              </v-btn>
            </transition>
          </v-col>
        </div>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        Carregando...
      </v-overlay>
    </questions-card>

    <v-dialog
      v-model="deleteAnswerDialog.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span>Deseja realmente excluir esta resposta?</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="justifyForm"
            lazy-validation
          >
            <v-textarea
              v-model="deleteAnswerDialog.justification"
              filled
              label="Motivo da exclusão"
              auto-grow
              counter="100"
              :rules="[
                v => !!v || 'Campo obrigatório!',
                v => (v || '').length >= 25 || 'Justificativa muito curta'
              ]"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            outlined
            @click="closeDeleteAnswer()"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            @click="deleteAnswer()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <questionnaire-answers-dialog
      ref="questionnaireAnswersDialog"
    />

    <questionnaire-form-dialog
      ref="questionnaireFormDialog"
      @save="loadAnswers"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="questionnaireObject && !['CIP_SILO'].includes(questionnaireObject)"
        fab
        dark
        color="blue"
        @click="showChecklist()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Preencher Questionário
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>
          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import moment from "moment-timezone";
import printJS from "print-js";
import * as _ from "lodash";

import DateRangePicker from "@/Support/Components/DateRangePicker.vue";
import QuestionsCard from "@/Domains/Questionnaires/Components/QuestionsCard.vue";
import QuestionnaireAnswersDialog from '@/Domains/Questionnaires/Components/QuestionnaireAnswersDialog.vue';
import QuestionnaireFormDialog from '@/Domains/Questionnaires/Components/QuestionnaireFormDialog.vue';

export default {
  components: {
    DateRangePicker,
    QuestionsCard,
    QuestionnaireAnswersDialog,
    QuestionnaireFormDialog,
  },

  props: {
    view: {
      type: String,
    },

    questionnaireId: {
      type: String,
    },

    questionnaireName: {
      type: String,
    },

    questionnaireObject: {
      type: String,
    },
  },

  data() {
    return {
      filters: {
        search: "",

        date: {
          input: "",
          range: [],
        },
      },

      answers: [],

      loading: false,

      selected: [],

      deleteAnswerDialog: {
        show: false,
        id: null,
        justification: '',
      },
    };
  },

  computed: {
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },
    hasDeleteAnswersAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === 'delete-answers-report' && o.tipo === 'COMPONENTE');
    },
  },

  mounted() {
    this.loadAnswers();
  },

  methods: {
    /**
     * Carrega os produtores que respoderam o questionário
     */
    async loadAnswers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaRespostasObjetos`,
          this.$qs.stringify({
            id_formulario: this.questionnaireId,
            data_inicio: !_.isEmpty(this.filters.date.range) ? _.head(this.filters.date.range) : null,
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) : null,
          })
        );

        this.answers = data.map(item => {
          return {
            id: item.id_formulario_customizado_cabecalho,
            responseAt: item.data_cadastro_app,
            object: item.nome_objeto ? item.nome_objeto : 'Sem seleção',
            objectId: item.id_objeto,
            personName: item.nome_usuario,
            code: item.codigo_resposta_sequencial
          };
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async print() {
      try {
        this.loading = true;

        if (_.isEmpty(this.selected)) {
          throw "Selecione os produtores";
        }

        const answersIds = _.map(this.selected, item => item.id);

        const { data } = await this.$axios.post(
          `/formularioCustomizado/impressaoRespotasFormulario`,
          {
            id_formulario: this.questionnaireId,
            id_respostas: answersIds,
            tipo: 'others',
            data_ini: !_.isEmpty(this.filters.date.range) ? _.head(this.filters.date.range) : null,
            data_fim: !_.isEmpty(this.filters.date.range) ? _.last(this.filters.date.range) : null,
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onDateFilter(event) {
      this.filters.date.range = event;

      this.loadAnswers();
    },

    showQuestionnaire(item) {
      return this.$refs.questionnaireAnswersDialog.show({
        id: item.id,
      });
    },

    confirmDeleteAnswer(item) {
      this.deleteAnswerDialog.show = true;
      this.deleteAnswerDialog.id = item.id;
      this.deleteAnswerDialog.justification = '';
    },

    closeDeleteAnswer() {
      this.deleteAnswerDialog.show = false;
    },

    async deleteAnswer() {
      if (!this.$refs.justifyForm.validate()) {
        return;
      }

      try {
        this.closeDeleteAnswer();
        this.loading = true;

        await this.$axios.post(`/formularioCustomizado/excluiRespostasFormulario`, {
          item: {
            id: this.deleteAnswerDialog.id,
            justification: this.deleteAnswerDialog.justification,
          },
        });

        this.$snotify.success('Resposta excluida com suscesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao excluir resposta', 'Atenção');
        console.warn(error);
      } finally {
        this.loading = false;
        this.loadAnswers();
      }
    },

    async showChecklist(item = null) {
      this.$refs.questionnaireFormDialog.show({
        id: this.questionnaireId,
        answerId: item?.id,
        objectId: item?.objectId,
      });
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

  },
};
</script>
