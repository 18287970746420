<template>
  <div>
    <v-select
      v-model="selected"
      :items="[
        { text: 'Últimos 3 meses', value: 'threeMonth' },
        { text: 'Últimos 6 meses', value: 'sixMonth' },
        { text: 'Últimos 8 meses', value: 'eightMonth' },
        { text: 'Últimos 10 meses', value: 'tenMonth' },
        { text: 'Últimos 12 meses', value: 'twelveMonth' },
        { text: 'Definir data', value: 'customRange' },
      ]"
      prepend-inner-icon="today"
      dark
      :label="label"
      hide-details
      filled
      :clearable="clearable"
      @click:clear="clear"
      @change="onDateFilterSelect"
    >
      <template v-slot:item="{ item }">
        <template v-if="selected === 'customRange' && item.value === 'customRange'">
          <span class="grow"> {{ startDate }} — {{ endDate }} </span>
          <v-btn
            icon
            light
            @click.stop="clear"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        <template v-else>
          {{ item.text }}
        </template>
      </template>

      <template v-slot:selection="{ item }">
        <span v-if="selected === 'customRange' && hasDateRange">
          {{ startDate }} — {{ endDate }}
        </span>
        <span v-else>{{ item.text }}</span>
      </template>
    </v-select>

    <v-dialog v-model="showSelectDate" persistent content-class="dialog-picker">
      <v-date-picker
        v-model="dateRange"
        :min="minDate"
        :max="maxDate"
        range
        reactive
        @change="onCustomDateRangeSelect"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  props: {
    value: {
      type: String,
    },

    label: {
      type: String,
      default: 'Data',
    },

    clearable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showSelectDate: false,
      selected: null,
      dateRange: [],
      minDate: moment().subtract(60, 'months').startOf('month').format('YYYY-MM-DD'),
    };
  },

  computed: {
    startDate() {
      return this.dateRange.length > 0 ? moment(this.dateRange[0]).format("DD/MM/YYYY") : null;
    },
    endDate() {
      return this.dateRange.length > 1 ? moment(this.dateRange[1]).format("DD/MM/YYYY") : null;
    },
    hasDateRange() {
      return this.dateRange.length > 1;
    },
    maxDate() {
      if (this.selected === 'customRange') {
        return moment().format('YYYY-MM-DD');
      } else if (this.selected) {
        return moment().endOf('month').format('YYYY-MM-DD'); // Max date is end of current month
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
    parsedDateRange() {
      if (this.selected === 'customRange') {
        return this.dateRange.map(date => moment(date).format('YYYY-MM-DD'));
      } else if (this.selected) {
        return this.calculateDateRange(this.selected);
      } else {
        return [];
      }
    },
  },

  mounted() {
    this.selected = this.value;
  },

  methods: {
    onDateFilterSelect(event) {
      this.$emit("input", event);

      if (this.selected === "customRange") {
        this.showSelectDate = true;
        return;
      }

      if (_.isEmpty(this.selected)) {
        this.dateRange = [];
      } else {
        this.dateRange = this.calculateDateRange(this.selected);
      }

      return this.$emit("change", this.parsedDateRange);
    },

    calculateDateRange(period) {
      let months = 0;
      switch (period) {
        case 'threeMonth': months = 3; break;
        case 'sixMonth': months = 6; break;
        case 'eightMonth': months = 8; break;
        case 'tenMonth': months = 10; break;
        case 'twelveMonth': months = 12; break;
      }
      const endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      const startDate = moment().subtract(months, 'months').startOf('month').format('YYYY-MM-DD');
      return [startDate, endDate];
    },

    onCustomDateRangeSelect() {
      this.showSelectDate = false;
      return this.$emit("change", this.parsedDateRange);
    },

    clear() {
      this.showSelectDate = false;
      this.selected = null;
      this.dateRange = [];
      this.$emit("change", this.parsedDateRange);
    },
  },
};
</script>
