<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <h2 class="menu-header white--text">
          Gráficos de Atestado de Conformidade
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <div class="mx-5 px-6">
      <v-row>
        <v-col cols="12">
          <v-card
            dark
            color="transparent"
          >
            <v-card-title class="mb-3">
              <v-col>
                <person-autocomplete-filter
                  v-model="filter.technician"
                  type="TECHNICAL"
                  label="Técnico"
                  dark
                  @change="loadExams"
                />
              </v-col>
              <v-col>
                <routes-autocomplete-filter
                  v-model="filter.routes"
                  label="Rota"
                  dark
                  multiple
                  @change="loadExams"
                />
              </v-col>
              <v-col>
                <month-picker
                  v-model="filterDate.date.input"
                  not-clearable
                  disable-last-thirty
                  @change="onMonthFilter"
                />
              </v-col>
            </v-card-title>
            <v-card
              class="graph-card"
              color="transparent"
              dark
            >
              <v-card-title>
                {{ title }}
              </v-card-title>
              <v-card-text class="mb-3">
                <v-row>
                  <v-col
                  >
                    Produtores ativos/suspensos: <b> {{ totalProducers }} </b>
                  </v-col>
                </v-row>
                <v-expansion-panels dark>
                  <v-expansion-panel v-for="panel in panels" :key="panel.title" style="background: none">
                    <v-expansion-panel-header>{{ panel.title }} {{ panel.data.length }} produtores ({{ panel.percent }}%)</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="headers"
                        :items="panel.data"
                        class="elevation-1"
                        :items-per-page="-1"
                        hide-default-footer
                      >
                        <template #[`item.status`]="{value}">
                          <v-chip
                            v-if="value == 'DESVINCULADO'"
                            color="red"
                            small
                          >
                            <v-avatar
                              left
                              small
                            >
                              <v-icon>cancel</v-icon>
                            </v-avatar>
                            D
                          </v-chip>
                          <v-chip
                            v-else-if="value == 'SUSPENSO'"
                            color="orange"
                            small
                          >
                            <v-avatar
                              left
                              small
                            >
                              <v-icon>remove_circle</v-icon>
                            </v-avatar>
                            S
                          </v-chip>
                          <v-chip
                            v-else-if="value == 'LEAD' || value == 'NEGOCIACAO' || value == 'APROVACAO' || value == 'REPROVADO'"
                            color="blue"
                            small
                          >
                            <v-avatar
                              left
                              small
                            >
                              <v-icon>remove_circle</v-icon>
                            </v-avatar>
                            P
                          </v-chip>
                          <v-chip
                            v-else
                            color="green"
                            small
                          >
                            <v-avatar
                              left
                              small
                            >
                              <v-icon>check_circle</v-icon>
                            </v-avatar>
                            A
                          </v-chip>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-card>
          <div>
            <v-card
              class="graph-card"
              color="transparent"
              dark
            >
              <v-card-title>
                {{ title }}
              </v-card-title>
              <v-card-text class="mb-3">
                <v-chart
                  ref="chart"
                  :options="options"
                  :style="{ height: `300px !important` }"
                  autoresize />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import { percentageBy, round } from "@/Support/Resources/utils";
import moment from "moment-timezone";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import printJS from "print-js";
import MonthPicker from "@/Support/Components/MonthPicker.vue";

export default {
  name: "exam-graphic",

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    MonthPicker
  },

  data() {
    return {
      sanity: "ATESTADO_CONFORMIDADE",
      title: 'Atestado de Conformidade',
      type: 'Atestado de Conformidade',
      panels: [],
      filter: {
        technician: {
          id: "",
          description: ""
        },
        routes: [],
        date: {
          month: moment().format('YYYY-MM'),
        },
        range: [moment().format('YYYY-MM'), moment().format('YYYY-MM')],
      },

      filterDate: {
        date: {
          input: "thisMonth",
        },
      },

      headers: [
        { text: 'Código', value: 'codigo_laticinio' },
        { text: 'Produtor', value: 'nome' },

        { text: 'Data', value: 'atestado_conformidade.data', align: 'center' },
        { text: 'Qtd. Animais', value: 'atestado_conformidade.quantidade_animais', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
      notVaccine: [],
      hasVaccine: [],
      totalProducers: 0,
      optionsLoaded: false,

    };
  },

  computed: {
    options() {

      if (!this.optionsLoaded) {
        return null;
      }

      let bars = [];

      const data = [
        {name: 'Com Atestado', value: this.hasVaccine},
        {name: 'Sem Atestado', value: this.notVaccine},
      ]

      data.forEach(e => {
        bars.push({
          name: e.name,
          type: 'bar',
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            lineHeight: 20,
            height: 20,
            backgroundColor: '#6a7985',
            color: '#fff',
            borderRadius: 5,
            position: 'top',
            distance: 1,
            formatter: '  {c}  ',
          },
          data: [e.value.length],
        });
      });

      return {
        color: ['rgba(38, 198, 218, 0.7)', 'rgba(140, 23, 23, 1)', 'rgba(251, 84, 37, 0.8)','rgba(37, 251, 39, 0.8)'],
        legend: {
          width: 500,
          textStyle: { color: '#ddd' },
          padding: [50, 0, 0, 0],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: { backgroundColor: '#6a7985' }
          },
          textStyle: {
            fontSize: 12,
          },
        },
        toolbox: {
          top: 20,
          right: 10,
          feature: {
            saveAsImage: {
              title: "",
              backgroundColor: "#0A2F54",
            },
            mySaveAsPdf: {
              show: true,
              icon: 'path://M14,2H6c-1.1,0-1.99.9-1.99,2L4,20c0,1.1.89,2,1.99,2H18c1.1,0,2-.9,2-2V8l-6-6zm2,16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5,9H13z',
              onclick: () => {

                const image = this.$refs.chart.getDataURL({ backgroundColor: '#0c3b69' });
                return printJS({
                  printable: image,
                  type: 'image',
                  style: '@page { size: Letter landscape; }'
                });
              }
            },
          },
          iconStyle: {
            borderColor: "#F3F3F3"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: ['Exames'],
          axisLabel: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(255, 255, 255, 0.1)']
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        series: bars
      };
    },
  },

  async created() {
    await this.getStates();
    await this.loadExams();
  },

  methods: {
    onMonthFilter(event) {
      this.filterDate.range = event;
      this.loadExams();
    },

    async loadExams() {
      try {
        this.$root.$progressBar.loading();

        let [startDate, endDate] = this.filterDate.range

        const { data } = await this.$axios.get(`/projects/health-exam/report`, { params: {
            tecnico: this.filter.technician.id,
            rotas: this.filter.routes.map(({ id }) => id),
            data_ini: startDate,
            data_fim: endDate,
          }});

        this.totalProducers = data.length;

        const producers =  this.transformarVacinas(data);

        this.notVaccine = this.withoutVaccine(producers);
        this.hasVaccine = this.withVaccine(producers);


        this.percentNotVaccine = round(percentageBy(this.totalProducers, this.notVaccine.length), 2);
        this.percentHasVaccine = round(percentageBy(this.totalProducers, this.hasVaccine.length), 2);

        this.panels = [
          { title: 'Sem Vacinas:', data: this.notVaccine, percent: this.percentNotVaccine, isDetailed: false },
          { title: 'Com Vacinas:', data: this.hasVaccine, percent: this.percentHasVaccine },
        ];

        this.optionsLoaded = true;

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os exames!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async getStates() {
      try {
        const { data } = await this.$queries.get(`/estados/listaJson`);

        this.states = data.reduce((result, item) => {
          result[item.uf] = item.end_estado;
          return result;
        }, {});
      } catch (error) {
        console.warn(error);
        this.$snotify.error("Oops, ocorreu um erro ao carregar os estados!", "Atenção");
      }
    },

    formatDate(value, format) {
      if(!value) {
        return '';
      } else if(value == 'VENCIDO') {
        return 'Vencido - fora do intervalo';
      } else {
        return moment(value).format(format)
      }
    },

    getMaiorData(datas) {
      return datas.reduce((a, b) => (new Date(a) > new Date(b) ? a : b));
    },

    getMenorData(datas) {
      return datas.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));
    },

    transformarVacinas(data) {
      const producers = data ?? [];

      return producers.map(produtor => {
        const vacinas = produtor.atestado_conformidade;

        const quantidadeAnimais = vacinas.length > 0 ? vacinas.reduce((total, vacina) => total + vacina.quantidade_animais, 0) : 0;

        const dataVacina = vacinas.length > 0 ? this.getMenorData(vacinas.map(vacina => vacina.data)) : null;
        const dataVencimento = vacinas.length > 0 ? this.getMaiorData(vacinas.map(vacina => vacina.data)) : null;

        produtor.atestado_conformidade = {
          data: dataVacina ? this.formatDate(dataVacina, 'DD/MM/YYYY') : 'Sem vacina' ,
          quantidade_animais: quantidadeAnimais,
          data_vencimento: this.formatDate(dataVencimento, 'DD/MM/YYYY'),
        };

        return produtor;
      });
    },
    withoutVaccine(data) {
      return data.filter(produtor => produtor.atestado_conformidade.data === "Sem vacina");
    },

    withVaccine(data) {
      return data.filter(produtor => produtor.atestado_conformidade.data !== "Sem vacina");
    },
  },
}
</script>
