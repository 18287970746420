<template>
  <div
    class="mx-6 px-7"
  >
    <v-flex
      xs12
      class="text-xs-center"
    >
      <h2
        class="menu-header white--text"
      >
        Checklist Personalizado
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-tabs
      v-model="aba"
      centered
      dark
      background-color="transparent"
      class="transparent"
      @change="onTabChange"
    >
      <v-tab href="#aba-questionnaires">
        Questionários
      </v-tab>

      <v-tab
        v-if="hasEditAccess"
        href="#aba-edit-questionnaires"
        :disabled="questionnaire.id === null || questionnaire.status !== 'ATIVO'"
      >
        Inserir/Editar
      </v-tab>

      <v-tab
        href="#aba-charts"
        :disabled="!questionnaire.id"
      >
        Gráficos
      </v-tab>

      <v-tab
        href="#aba-answers"
        :disabled="!questionnaire.id"
      >
        Respostas
      </v-tab>

      <v-tab-item value="aba-questionnaires">
        <list-questionnaires
          v-if="aba === 'aba-questionnaires'"
          view="others"
          @changeTab="handleChangeTab"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>

      <v-tab-item value="aba-edit-questionnaires">
        <create-questionnaire
          ref="createQuestionnaire"
          view="others"
          :questionnaire-id="questionnaire.id"
          :disabled="questionnaire.disabledEdition"
          @newQuestionnaire="onNewQuestionnaire"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-charts"
      >
        <questionnaires-charts
          v-if="aba === 'aba-charts'"
          view="others"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
        />
      </v-tab-item>

      <v-tab-item
        value="aba-answers"
      >
        <answers-list
          v-if="aba === 'aba-answers'"
          view="others"
          :questionnaire-id="questionnaire.id"
          :questionnaire-name="questionnaire.name"
          :questionnaire-object="questionnaire.object"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ListQuestionnaires from '@/Domains/Questionnaires/Views/ListQuestionnaires.vue';
import QuestionnairesCharts from '@/Domains/Questionnaires/Views/QuestionnairesCharts.vue';
import AnswersList from "@/Domains/Questionnaires/Views/AnswersList.vue";
import CreateQuestionnaire from "@/Domains/Questionnaires/Views/CreateQuestionnaire.vue";

export default {
  components: {
    AnswersList,
    ListQuestionnaires,
    QuestionnairesCharts,
    CreateQuestionnaire,
  },

  data() {
    return {
      aba: 'aba-questionnaires',

      questionnaire: {
        id: null,
        name: null,
        status: null,
        object: null,
        disabledEdition: false,
      },

      visibleCharts: true,
    }
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasEditAccess() {
      return this.userResources.some(o => o.recurso === 'questionnaire-edit' && o.tipo === 'COMPONENTE');
    },
  },

  methods: {

    /**
     * Trata evento de naveção nas abas
     */
    handleChangeTab(data) {
      this.questionnaire.id = data.id;
      this.questionnaire.name = data.name;
      this.questionnaire.status = data.status;
      this.questionnaire.object = data.object;
      this.questionnaire.disabledEdition = data.totalAnswered > 0;
      this.aba = data.tab
    },

    /**
     *
     * Evento acionado ao mudar a aba no menu
     */
    onTabChange() {
      if (["aba-questionnaires", "aba-questionnaires-answered"].includes(this.aba)) {
        this.questionnaire = { id: null, name: null };
      }
    },

    onNewQuestionnaire() {
      this.questionnaire.id = '';
      this.questionnaire.status = 'ATIVO';
      this.questionnaire.disabledEdition = false;
      this.aba = 'aba-edit-questionnaires';
    },
  },
}
</script>
