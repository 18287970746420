
export const icms = {
  cfop: null,

  tributacao: null,
  modalidade: null,
  aliquota_credito: null,
  aliquota: null,
  aliquota_reducao: null,
  aliquota_diferimento: null,
  aliquota_fcp: 0,
  somar_ipi_na_base: false,
  // subtrair_fcp_no_st: false,

  // Campos para ICMS ST
  modalidade_st: null,
  valor_pauta_st: null,
  aliquota_st: null,
  aliquota_reducao_st: null,
  aliquota_mva_st: null,
  aliquota_fcp_st: 0,
  // somar_ipi_na_base_st: false,

  // Campos para ICMS Efetivo: Alíquotas na operação à consumidor final. (opcional a critério da UF)
  aliquota_reducao_efetivo: null,
  aliquota_efetivo: null,
};

export const icms_uf_dest = {
  aliquota_interestadual: null,
  aliquota_interna: null,
  aliquota_fcp: 0,
  calculo_base_por_dentro: false
};

export const ipi = {
  tributacao: null,
  aliquota: null,
  codigo_enquadramento: null,
};

export const pis = {
  tributacao: null,
  aliquota: 0,
  base: 100,
};

export const cofins = {
  tributacao: null,
  aliquota: 0,
  base: 100,
};

export const issqn = {
  tributacao: null,
  aliquota: 0,
  base: 100,
  descontar_iss: false,
};

export const funrural = {
  aliquota: 0,
  descontar_do_total_faturado: false,
};

export const outros = {
  percentual_despesas: 0,
  aliquota_fundesa: 0,
  beneficio_fiscal: null,
};
