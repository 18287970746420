<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="filteredItems"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, -1]
        }"
        mobile-breakpoint="1000"
        class="elevation-1"
        show-select
      >
        <template #item.driver.name="{ value }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ truncate(value || '', 15).toUpperCase() }}
              </v-chip>
            </template>

            {{ value }}
          </v-tooltip>
        </template>

        <template #item.operation="{ item }">
          <v-chip small>
            <v-avatar left>
              <v-icon
                v-if="item.operation === 'DESCARGA'"
                color="red"
                v-text="'arrow_circle_up'"
              />
              <v-icon
                v-if="item.operation === 'CARGA'"
                color="green"
                v-text="'arrow_circle_down'"
              />
            </v-avatar>

            <span>
              {{ item.operation }}
            </span>
          </v-chip>
        </template>

        <template #[`item.operationTime`]="{ value }">
          <v-chip
            v-if="value"
            small
          >
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </v-chip>
        </template>

        <template #[`item.operations`]="{ item }">
          <shipment-operations
            :shipment="item"
            @showShipmentLoad="onShowShipmentLoad"
            @showShipmentPacking="onShowShipmentPacking"
            @reloadShipments="loadShipments"
          />
        </template>

        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="onPrint([item])"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir Romaneio
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="printSheet(item)"
              >
                <v-list-item-icon>
                  <v-icon>assignment</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir Fichas Pallets
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="exportPalletReport(item)"
              >
                <v-list-item-icon>
                  <v-icon>backup_table</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Baixar Relatório Pallets
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasInactivateAccess"
                @click="excludeShipment(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Estornar e Excluir
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <shipment-load-dialog
      ref="shipmentLoad"
      @close="loadShipments"
    />

    <shipment-packing-dialog
      ref="shipmentPacking"
      @close="loadShipments"
    />

    <print-settings-dialog
      ref="printSettings"
      @print="print"
    />

    <pdf-viewer-dialog
      ref="pdfViewer"
    />

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue"
        @click="loadShipments()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>

          Recarregar
        </v-tooltip>
      </v-btn>

      <v-btn
        color="blue darken-3"
        dark
        fab
        @click="onAddShipmentLoad()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Novo Romaneio
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="selectedItems.length > 0"
        color="orange darken-1"
        dark
        fab
        @click="onPrint(selectedItems)"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>
          Imprimir Romaneio
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, getCurrentInstance } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import _ from 'lodash'
import moment from 'moment'
import printJS from 'print-js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import { useReports } from '@/Support/Composables/reports.js'

import ShipmentOperations from '@/Domains/Shipment/Shipment/Components/ShipmentOperations.vue'
import ShipmentLoadDialog from '@/Domains/Shipment/Shipment/Components/ShipmentLoadDialog.vue'
import ShipmentPackingDialog from '@/Domains/Shipment/Shipment/Components/ShipmentPackingDialog.vue'
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue'
import PdfViewerDialog from '@/Support/Components/PdfViewerDialog.vue'

import api from '@/Domains/Shipment/Api/Shipment.js'

const { progressBar, notify, confirm } = useUtils()

const { exportToFile } = useReports()

const { proxy } = getCurrentInstance()

const { hasPermission } = usePermissions()

const hasInactivateAccess = computed(() => hasPermission('shipment-inactivate'))

const filters = reactive({
  search: '',
})

const items = ref([])

const selectedItems = ref([])

const headers = computed(() => {
  if (proxy.$vuetify.breakpoint.md) {
    return [
      { align: 'start', text: 'Motorista', value: 'driver.name' },
      { text: 'Placa', value: 'vehicle.plate' },
      { text: 'Origem/Destino', value: 'route.description' },
      { align: 'end', text: '', value: 'operations', width: 320, sortable: false },
      { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
    ]
  }

  return [
    { align: 'start', text: 'Código', value: 'code' },
    { align: 'start', text: 'Motorista', value: 'driver.name' },
    { text: 'Operação', value: 'operation' },
    { text: 'Placa', value: 'vehicle.plate' },
    { text: 'Data/Hora Operação', value: 'operationTime', width: 165 },
    { text: 'Origem/Destino', value: 'route.description' },
    { align: 'end', text: '', value: 'operations', width: 340, sortable: false },
    { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
  ]
})

const filteredItems = computed(() => {
  if (!filters.search) {
    return items.value
  }

  const search = filters.search.toUpperCase().trim()

  return items.value.filter(item => {
    const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
    return hasSearch
  })
})

const loadShipments = async () => {
  try {
    progressBar?.loading()

    items.value = await api.index({ status: ['PROCESSANDO', 'CARREGADO'] })
  } catch (err) {
    notify.error(
      'Oops, ocorreu um erro ao carregar os carregamentos!',
      'Atenção'
    )

    console.log(err)
  } finally {
    progressBar?.hide()
  }
}

const shipmentLoad = ref()
const shipmentPacking = ref()

const onShowShipmentLoad = (shipment) => {
  shipmentLoad.value.show(shipment)
}

const onShowShipmentPacking = (shipment) => {
  shipmentPacking.value.show(shipment)
}

const onAddShipmentLoad = () => {
  shipmentLoad.value.show()
}

const excludeShipment = async (shipment) => {
  if (!(await confirm(
    'Atenção',
    `Deseja realmente excluir e estornar os itens expedidos deste carregamento?
      <br /> <br />
    - Rota: <b>${shipment.route.description}</b>
      <br />
    - Motorista: <b>${shipment.driver.name}</b>
      <br />
    - Veiculo: <b>${shipment.vehicle.plate || ''}</b>
      <br /> <br />
    Esta ação não poderá ser revertida!
    <br /> <br />`,
    { color: 'red', token: 'EXCLUIR' }
  ))) {
    return
  }

  try {
    progressBar?.loading()

    await api.destroy(shipment.id)

    items.value = items.value.filter(item => item.id !== shipment.id)
  } catch (e) {
    console.log(e)

    notify.error(
      'Oops, ocorreu um erro ao excluir o carregamento!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const printSettings = ref()

const onPrint = (items) => {
  const ids = items.map(item => item.id)

  printSettings.value.show({
    item: { ids },
    params: [
      { title: 'Termo de Responsabilidade - Controle de Carga', key: 'mostra_termo', value: false },
      { title: 'Checklists', key: 'mostra_checklists', value: false },
    ]
  })
}

const pdfViewer = ref()

const print = async ({ item: { ids },  params }) => {
  pdfViewer.value.show({
    title: 'Romaneio de Expedição',
    api: () => api.print({ ids, params })
  })
}

const printSheet = async ({ id }) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/pallet/sheet`, { params: {
      shipment_id: id,
    } })

    return printJS({
      printable: data,
      type: 'raw-html'
    })
  } catch (e) {
    console.warn(e)
    notify.error(
      'Oops, ocorreu um erro ao imprimir as fichas!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const exportPalletReport = async ({ id }) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/shipment/${id}`)

    const report = data.entregas.flatMap(entrega => {
      const customer = entrega.cliente || {}
      const address = JSON.parse(customer.endereco_entrega) || {}
      return entrega.pallets.map(pallet => ({
        'cliente': [
          customer.codigo_laticinio,
          customer.nome,
          entrega.numero_nota ? `Nota: ${entrega.numero_nota}` : '',
          address.end_cidade ? `Cidade: ${address.end_cidade}` : '',
          address.end_estado ? `Estado: ${address.end_estado}` : '',
        ].filter(Boolean).join(' - '),
        'pallet': pallet.descricao,
        'peso_liquido': pallet.peso_bruto - (pallet.tara_pallet || 0) - (pallet.tara_stretch || 0) - (pallet.tara_embalagem || 0),
      }))
    })

    const headers = [
      { text: 'Cliente', value: 'cliente' },
      { text: 'Pallet', value: 'pallet' },
      { text: 'Peso Líquido (Kg)', value: 'peso_liquido', mask: '#,##0.00' },
    ]

    const groupBy = 'Cliente'
    const title = `Relatório de Pallets - ${data.itinerario?.codigo}`
    const sheetName = `Carga ${data.itinerario?.codigo}`

    return exportToFile({ headers, report, groupBy, title, sheetName })

  } catch (e) {
    console.warn(e)
    notify.error(
      'Oops, ocorreu um erro ao imprimir as fichas!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const truncate = (value, length) => _.truncate(value, { length })

onMounted(() => {
  loadShipments()
})
</script>
