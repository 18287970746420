import { default as api } from '@/Support/Resources/axios-instance.js';
import moment from 'moment';

const shipmentStatus = {
  'PROCESSANDO': 'PROCESSING',
  'CARREGADO': 'LOADED',
  'FINALIZADO': 'FINISHED',
}

export default {
  async index(params = {}) {
    const { data } = await api.get('/shipment', { params })

    return data.map((item) => ({
      id: item.id,
      status: shipmentStatus[item.estado],
      code: item.itinerario.codigo,
      departureTime: item.itinerario.data_hora_inicio,
      operationTime: item.itinerario.data_hora_descarga,
      entrancedAt: item.data_hora_baixa_portaria,
      departureAt: item.data_hora_saida_portaria,
      inletWeight: parseInt(item.peso_entrada) || 0,
      outletWeight: parseInt(item.peso_saida) || 0,
      obs: item.observacao,
      operation: item.operacao,
      driver: {
        id: item.itinerario.id_pessoa,
        name: item.itinerario.nome_pessoa,
      },
      vehicle: {
        plate: item.itinerario.placa,
      },
      route: {
        id: item.itinerario.id_rota,
        description: item.itinerario.descricao_rota
      },
      itinerary: {
        id: item.itinerario.id_itinerario,
        type: item.itinerario.tipo,
        code: item.itinerario.cod_itinerario,
      },
      checklists: item.questionarios.map(item => ({
        id: item.id_formulario,
        title: item.titulo,
        required: item.obrigatorio,
        answerId: item.id_formulario_customizado_cabecalho,
        access: item.acessos || [],
      })),
    }));
  },

  async show(id) {
    const { data } = await api.get(`/shipment/${id}`);

    return {
      id: data.id,
      status: shipmentStatus[data.estado],
      code: data.itinerario.codigo,
      departureTime: data.itinerario.data_hora_inicio,
      operationTime: data.itinerario.data_hora_descarga ? moment(data.itinerario.data_hora_descarga).format('YYYY-MM-DD HH:mm') : null,
      entrancedAt: data.data_hora_baixa_portaria,
      departureAt: data.data_hora_saida_portaria,
      temperature: data.temperatura,
      cargoSeal: data.lacre_carga,
      grossWeight: parseFloat(data.peso_bruto) || 0,
      netWeight: parseFloat(data.peso_liquido) || 0,
      obs: data.observacao,
      operation: data.operacao,
      isThird: !data.itinerario.id_pessoa,
      driver: {
        id: data.itinerario.id_pessoa,
        name: data.itinerario.nome_pessoa,
      },
      carrier: {
        id: data.itinerario.id_transportadora,
        name: data.itinerario.nome_transportadora,
      },
      vehicle: {
        id: data.itinerario.id_equipamento,
        description: data.itinerario.equipamento || data.itinerario.placa,
        plate: data.itinerario.placa,
      },
      route: {
        id: data.itinerario.id_rota,
        description: data.itinerario.descricao_rota
      },
      itinerary: {
        id: data.itinerario.id_itinerario,
        type: data.itinerario.tipo,
        code: data.itinerario.cod_itinerario,
      },
      shippings: data.entregas.map(shipping => ({
        id: shipping.id,
        idx: shipping.idx,
        dateTime: shipping.data_hora ? moment(shipping.data_hora).format('YYYY-MM-DD HH:mm') : null,
        code: shipping.codigo,
        notes: shipping.observacao,
        invoiceNumber: shipping.numero_nota,
        customer: {
          id: shipping.id_cliente,
          name: `${shipping.idx} - ${shipping.cliente?.nome}`,
        },
        products: shipping.produtos.map(product => {
          const shipmentMeasurement = {
            id: product.unidade?.id || product.id_unidade_medida,
            unit: product.unidade?.unidade_medida || product.unidade_medida,
            description: product.unidade?.descricao || product.unidade_descricao,
            conversionFactor: product.unidade?.fator_conversao || product.fator_conversao_expedicao,
          }

          const sellingMeasurement = {
            id: product.unidade_venda?.id || product.id_unidade_medida,
            unit: product.unidade_venda?.unidade_medida || product.unidade_medida,
            description: product.unidade_venda?.descricao || product.unidade_descricao,
            conversionFactor: product.unidade_venda?.fator_conversao || product.fator_conversao_expedicao,
          }

          return {
            id: product.id,

            realQuantity: parseFloat(product.quantidade_real),
            realMeasurementId: product.id_unidade_medida_real,
            realMeasurement: product.unidade_real,
            realMeasurementDescription: product.unidade_real_descricao,
            realShipped: parseFloat(product.quantidade_expedida),

            salesQuantity: parseFloat(product.quantidade_venda),
            salesUnitId: product.id_unidade_venda,
            salesUnit: product.unidade_venda,
            salesUnitDescription: product.unidade_venda_descricao,
            salesConversionFactor: product.fator_conversao_venda,
            salesShipped: parseFloat(product.quantidade_expedida) / product.fator_conversao_venda,

            quantity: parseFloat(product.quantidade),
            measurementId: product.id_unidade_medida,
            measurement: product.unidade_medida,
            measurementDescription: product.unidade_descricao,
            conversionFactor: product.fator_conversao_expedicao || 1,

            // Dados do item para venda
            sellingItem: {
              id: product.id_item,

              // Recupera os dados da unidade de medida de venda do item
              measurementId: sellingMeasurement.id,
              measurement: sellingMeasurement.unit,
              measurementDescription: sellingMeasurement.description,
              conversionFactor: sellingMeasurement.conversionFactor,

              // Recupera os dados da unidade de medida real do item
              defaultMeasurementId: product.item.id_unidade_medida,
              defaultMeasurement: product.item.unidade,
              defaultMeasurementDescription: product.item.unidade_descricao,

              name: product.item.nome,
              code: product.item.cod_item,
              sif: product.item.sif,
              sifDipoa: product.item.sif_dipoa,
              barcodes: product.item.codigos_barras,
              duncodes: product.item.codigos_dun,
              barcode: product.item.codigo_barras,
              defaultBarcode: product.item.codigo_barras,
              description: `${product.item?.nome} ${sellingMeasurement.description ? `(${sellingMeasurement.description})` : ''}`,
              type: product.item.tipo,
              requiresLot: product.item.controlar_lotes || false,
              manualLot: product.item.lote_manual || false,
              validateStock: product.item.validar_estoque,
              stockControl: product.item.controle_estoque,

              grossWeight: product.unidade?.peso_bruto || ((product.item.peso_bruto * (product.fator_conversao || 1)) + (product.unidade?.peso_tara || 0)) || null,
              tareWeight1: product.unidade?.peso_tara,
              tareWeight2: (product.item.peso_tara * (product.fator_conversao || 1)) || null,
              fixedWeight: product.unidade?.peso_fixo || false,

              price: product.item.valor ? parseFloat(product.item.valor) : null,

              group: {
                value: product.item.id_item_grupo,
                text: product.item.nome_item_grupo,
              },
            },

            // Dados do item para expedição
            item: {
              id: product.id_item,
              itemId: product.id_item,

              // Recupera os dados da unidade de medida de venda do item
              measurementId: shipmentMeasurement.id,
              measurement: shipmentMeasurement.unit,
              measurementDescription: shipmentMeasurement.description,
              conversionFactor: shipmentMeasurement.conversionFactor,

              // Recupera os dados da unidade de medida real do item
              defaultMeasurementId: product.item.id_unidade_medida,
              defaultMeasurement: product.item.unidade,
              defaultMeasurementDescription: product.item.unidade_descricao,

              name: product.item.nome,
              code: product.unidade?.codigo || product.item.cod_item,
              sif: product.item.sif,
              sifDipoa: product.item.sif_dipoa,
              barcodes: product.item.codigos_barras,
              duncodes: product.item.codigos_dun,
              barcode: product.unidade?.codigo_barras || product.item.codigo_barras,
              defaultBarcode: product.item.codigo_barras,
              description: `${product.item?.nome} ${shipmentMeasurement.description ? `(${shipmentMeasurement.description})` : ''}`,
              type: product.item.tipo,
              requiresLot: product.item.controlar_lotes || false,
              manualLot: product.item.lote_manual || false,
              validateStock: product.item.validar_estoque,
              stockControl: product.item.controle_estoque,

              grossWeight: product.unidade?.peso_bruto || ((product.item.peso_bruto * (product.fator_conversao || 1)) + (product.unidade?.peso_tara || 0)) || null,
              tareWeight1: product.unidade?.peso_tara,
              tareWeight2: (product.item.peso_tara * (product.fator_conversao || 1)) || null,
              fixedWeight: product.unidade?.peso_fixo || false,

              price: product.item.valor ? parseFloat(product.item.valor) : null,

              group: {
                value: product.item.id_item_grupo,
                text: product.item.nome_item_grupo,
              },
            },
            shipped: parseFloat(product.quantidade_expedida) / product.fator_conversao_expedicao,
            packingCodes: product.embalagens.map(item => item.codigo_barras),
            remaining: {
              lots: [],
              packingCodes: [],
              quantityLot: 0,
              quantity: (parseFloat(product.quantidade_real) - parseFloat(product.quantidade_expedida)) / product.fator_conversao_expedicao,
              shipped: 0,
            },
            stocks: product.estoques.map(history => ({
              id: history.id_estoque,
              description: history.numero_lote || 'PADRÃO',
              quantity: history.quantidade,
              lotNumber: history.numero_lote,
              measurement: history.unidade,
              packingQuantity: product.embalagens
                .filter(packing => packing.estoque?.numero_lote === history.numero_lote)
                .reduce((acc, cur) => acc + cur.quantidade, 0),
              manufacturingDate: history.data_fabricacao,
              expirationDate: history.data_validade,
            })),
            packings: product.embalagens.map(packing => ({
              id: packing.id,
              lotNumber: packing.estoque?.numero_lote,
              quantity: packing.quantidade,
              measurement: packing.unidade_medida?.unidade_medida,
              description: packing.unidade_medida ? `${product.item?.nome} (${packing.unidade_medida.descricao})` : product.item?.nome,
              product: `${product.item?.cod_item || ''} - ${product.item?.nome} - ${packing.estoque?.numero_lote || 'SEM LOTE'}`,
              grossWeight: parseFloat(packing.peso_bruto || 0),
              tareWeight: parseFloat(packing.peso_tara_1 || 0) + parseFloat(packing.peso_tara_2 || 0),
              netWeight: parseFloat(packing.peso_bruto || 0) - parseFloat(packing.peso_tara_1 || 0) - parseFloat(packing.peso_tara_2 || 0),
              date: packing.data_hora_cadastro,
              barcode: packing.codigo_barras,
              palletId: packing.id_pallet,
              palletCode: shipping.pallets.find(pallet => pallet.id === packing.id_pallet)?.descricao,
            })),
          }
        }),
        pallets: shipping.pallets.map(pallet => ({
          id: pallet.id,
          barcode: pallet.codigo_barras,
          description: pallet.descricao,
          palletTare: pallet.tara_pallet,
          stretchTare: pallet.tara_stretch,
        })).sort((a, b) => a.description.localeCompare(b.description)),
        palletCodes: shipping.pallets.map(pallet => pallet.codigo_barras),
        pendingPalletCodes: [],
      })),
    };
  },

  async update(id, payload) {
    const { data } = await api.put(`/shipment/${id}`, payload);
    return data;
  },

  async store(payload) {
    const { data } = await api.post('/shipment', payload);
    return data;
  },

  async destroy(id) {
    const { data } = await api.delete(`/shipment/${id}`);
    return data;
  },

  async findStorage(params = {}) {
    const { data } = await api.get('/shipment/find-storage', { params });
    return data;
  },

  async print(params = {}) {
    const { data } = await api.post('/shipment/print', params);
    return data;
  },

}
