<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="pa-0"
            >
              <v-text-field
                v-model="filter.search"
                append-icon="search"
                label="Buscar"
                single-line
                hide-details
                dark
                filled
                class="pt-0 my-2"
              />
            </v-col>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter.search"
            dark
            @click:row="edit"
          >
            <template #[`item.activeIngredient.groups`]="{ value }">
              <v-chip
                v-for="group in value"
                :key="group.id"
                x-small
              >
                {{ group.description }}
              </v-chip>
            </template>

            <template #[`item.activeIngredient.tests`]="{ value }">
              <v-chip
                v-for="test in value"
                :key="test.id"
                x-small
              >
                {{ test.description }}
              </v-chip>
            </template>

            <template #[`item.action`]="{ item }">
              <v-menu
                bottom
                right
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="remove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn
          fixed
          fab
          large
          dark
          bottom
          right
          color="blue"
          v-on="on"
          @click="add()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>

      <span>Novo</span>
    </v-tooltip>

    <antibiotic-medicine-dialog
      ref="antibioticDialogRef"
      @save="loadItens"
    />
  </v-container>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import AntibioticMedicineDialog from '@/Domains/Registrations/Antibiotic/Components/AntibioticMedicineDialog.vue'

const { notify, progressBar, confirm } = useUtils()

const filter = reactive({
  search: ''
})

const headers = [
  { text: 'Descrição', value: 'description' },
  { text: 'Tipo', value: 'type' },
  { text: 'Princípio Ativo', value: 'activeIngredient.description' },
  { text: 'Grupos', value: 'activeIngredient.groups' },
  { text: 'Testes', value: 'activeIngredient.tests' },
  { text: '', value: 'action', width: 30 }
]

const items = ref([])

const loadItens = async () => {
  try {
    progressBar.loading()
    const { data } = await axios.get(`/registrations/antibiotic/medicine`)

    items.value = data.map(item => ({
      id: item.id,
      description: item.descricao,
      type: item.tipo,
      activeIngredientId: item.id_principio_ativo,
      activeIngredient: {
        id: item.principio_ativo?.id,
        description: item.principio_ativo?.descricao,
        groups: item.principio_ativo?.grupos?.map(group => ({
          id: group.id,
          description: group.descricao
        })) || [],
        tests: item.principio_ativo?.testes?.map(group => ({
          id: group.id,
          description: group.descricao
        })) || [],
      },
    }))
  } catch (err) {
    console.warn(err)
    notify.error('Oops, ocorreu um erro ao carregar!', 'Atenção')
  } finally {
    progressBar.hide()
  }
}

const antibioticDialogRef = ref()

const add = () => {
  antibioticDialogRef.value.show()
}

const edit = (item) => {
  antibioticDialogRef.value.show(item)
}

const remove = async ({ id }) => {
  if (!(await confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
    return
  }

  try {
    progressBar.loading()

    await axios.delete(`/registrations/antibiotic/medicine/${id}`)

    notify.success('Registro excluído com sucesso', 'Sucesso')
  } catch (error) {
    notify.error('Erro ao excluir registro', 'Atenção')
    console.warn(error)
  } finally {
    progressBar.hide()
    loadItens()
  }
}

loadItens()
</script>
