<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Configurações de Instruções Normativas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      dark
      background-color="transparent"
      class="transparent"
      centered
    >
      <v-tab href="#aba-analises">
        Críticos e Prioritários
      </v-tab>
      <v-tab href="#aba-novos-produtores">
        Novos Produtores
      </v-tab>
      <v-tab href="#aba-suspender">
        Suspenser Produtores
      </v-tab>
      <v-tab href="#aba-produtores-suspensos">
        Produtores Suspensos
      </v-tab>

      <v-tab-item value="aba-analises">
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Ir para lista de atendimentos (Críticos e Prioritários)
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="critics_list"
                  label="Críticos"
                >
                  <v-radio
                    label="Listar como Fora da IN ( Critico) no mês posterior ao resultado da 2º Media Geométrica trimestral"
                    value="MONTH_AFTER_SECOND_AVERAGE"
                    class="mb-0"
                  />
                  <v-radio
                    label="Listar como Fora da IN ( Critico) no momento em sair o resultado da 2º Media Geométrica "
                    value="SAME_MONTH_SECOND_AVERAGE"
                    class="mb-0"
                  />
                </v-radio-group>

                <v-radio-group
                  v-model="priority_list"
                  label="Prioritários"
                >
                  <v-radio
                    label="Listar como Fora da IN ( Prioritários) no mês posterior ao resultado (conforme configuração da meta)"
                    value="MONTH_AFTER"
                    class="mb-0"
                  />
                  <v-radio
                    label="Listar como Fora da IN ( Prioritários) no momento em sair o resultado (conforme configuração da meta)"
                    value="SAME_MONTH"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Limite para salvamentos IN 59
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model.number="limit59"
                      label="limite:"
                      placeholder="1"
                      type="number"
                      dense
                      dark
                      outlined
                      hide-details
                      :rules="[v => !v || v >= 0 || 'obrigatório']"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    Considerações:<br>
                    *Se Limite = 0 vai abrir tela para suspender/desvincular produtor.<br>
                    *Se Limite > 0 deve ser configurado critérios para visitas e qualidade.
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Para cumprir a IN 59 e atendimento aos prioritários  (visitas)
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="in59_visits"
                  label="Críticos"
                >
                  <v-radio
                    label="Considerar a visita realizada apenas dentro do mês que o produtor esta fora da IN"
                    value="VISIT_SAME_MONTH_OUT"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar a visita realizada no mês anterior que produtor esta fora da IN"
                    value="VISIT_AFTER_MONTH_OUT"
                    class="mb-0"
                  />
                </v-radio-group>

                <v-radio-group
                  v-model="priority_visits"
                  label="Prioritários"
                >
                  <v-radio
                    label="Considerar a visita realizada apenas dentro do mês que o produtor esta fora da IN"
                    value="VISIT_SAME_MONTH_OUT"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar a visita realizada no mês anterior que produtor esta fora da IN"
                    value="VISIT_AFTER_MONTH_OUT"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Para ir a lista de suspenção e desvinculação após estar com as duas geométricas trimestrais fora da IN( Críticos)
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="list_to_suspend"
                  label=""
                  dark
                >
                  <v-radio
                    label="Considerar  1 (uma) analise fora do padrão (imediato) "
                    value="ONE_ANALYSIS_IMMEDIATELY"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar  1 (uma) analise geométrica mensal fora do padrão (respeita o fechamento do mês)"
                    value="ONE_MONTHLY_GEOMETRIC_AFTER_MONTH"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar 3º média geométrica trimestral ( imediatamente após o resultado)"
                    value="THIRD_MONTHLY_GEOMETRIC_IMMEDIATELY"
                    class="mb-0"
                  />
                  <v-radio
                    label="Após 3º média geométrica trimestral (respeita o fechamento do mês)"
                    value="THIRD_MONTHLY_GEOMETRIC_AFTER_MONTH"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-produtores-suspensos">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Considerar para reingresso do produtor
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Obrigatório lançamento do valor da analise dentro do padrão"
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Todas as analises dentro do mês"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Reativação do produtor Suspenso
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Considerar somente analise dentro do padráo lançado pelo tecnico na reativação "
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar todas as analises dentro do mes (RBQL) sem a analise lançada pelo tecnico"
                    value="2"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar apenas a analise menor dentro do pradrao (RBQL)"
                    value="3"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Obrigatóriedade para reingresso produtor após suspensão
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-checkbox
                    label="Obrigatório analise de CPP dentro do padrão (RBQL)"
                    value="1"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório visita tecnica com recomendação dentro do mês"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório aplicação de questionario (PQFL) dentro do mês"
                    value="3"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório analise de CCS dentro do padrão (RBQL)"
                    value="4"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-suspender">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Configurações para suspender Produtores
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                  label=""
                >
                  <v-checkbox
                    label="Obrigatório visita técnica com recomendação dentro do mês"
                    value="1"
                    class="mb-0  mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório preenchimento do termo de suspensão assinado pelo produtor"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-novos-produtores">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Configurações para efetivar Novos PRODUTORES
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-checkbox
                    label="Obrigatório analise de CPP dentro do padrão (RBQL) nos últimos 30 dias"
                    value="1"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório visita técnica com recomendação nos últimos 30 dias"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório aplicação de questionario (PQFL) nos últimos 30 dias"
                    value="3"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório atender requisitos indispensavel (CHECKLIST INICIAL)"
                    value="3"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório analise de CCS dentro do padrão (RBQL) nos últimos 30 dias"
                    value="4"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório atestado de conformidade brucelose/tuberculose valido"
                    value="5"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório preenchimento total do Cadastro"
                    value="6"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="showConfirmationDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>

          Salvar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>Confirmação</v-card-title>
        <v-card-text>
          <p>A alteração das configurações dos padrões de normativa, são de inteira responsabilidade do usuario.</p>
          <v-text-field
            v-model="userName"
            label="Nome"
            required
          />
          <masked-input-form
            v-model="userCpf"
            dense
            label="CPF"
            :mask="CPFMask"
            maxlength="14"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="green" text @click="confirmSave">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay v-model="loading">
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>

import moment from "moment-timezone";
import MaskedInputForm from "@/Domains/Producer/Management/Components/MaskedInput.vue";

export default {
  components: {
    MaskedInputForm,
  },

  data() {
    return {
      loading: false,
      critics_list: "MONTH_AFTER_SECOND_AVERAGE",
      priority_list: "MONTH_AFTER",
      limit59: 1,
      in59_visits: "VISIT_SAME_MONTH_OUT",
      priority_visits: "VISIT_SAME_MONTH_OUT",
      list_to_suspend: "THIRD_MONTHLY_GEOMETRIC_AFTER_MONTH",
      dialog: false,
      userName: "",
      userCpf: "",

      CPFMask: {
        mask: "000.000.000-00",
        maxlength: 14,
      },

    };

  },

  mounted() {
    this.loadActiveConfiguration();
  },
  methods: {
    async loadActiveConfiguration() {
      try {
        this.loading = true;
        const response = await this.$axios.get('/projects/normative-settings');
        const data = response.data;

        // Preencher os campos com a configuração ativa
        this.critics_list = data.lista_criticos;
        this.priority_list = data.lista_prioritarios;
        this.limit59 = data.limite59;
        this.in59_visits = data.visitas_in59;
        this.priority_visits = data.visitas_prioritarios;
        this.list_to_suspend = data.lista_suspender;
        this.user_name = data.nome_usuario;
        this.user_cpf = data.cpf_usuario;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$snotify.error("Erro ao carregar configuração ativa.", "Atenção");
        console.error(error);
      }
    },
    showConfirmationDialog() {
      this.dialog = true;
    },
    async confirmSave() {
      if (!this.userName || !this.userCpf) {
        this.$snotify.error("Nome e CPF são obrigatórios!", "Atenção");
        return;
      }
      try {
        this.loading = true;
        this.dialog = false;
        const payload = {
          lista_criticos: this.critics_list,  // Certifique-se de que está preenchido
          lista_prioritarios: this.priority_list,
          limite59: this.limit59,
          visitas_in59: this.in59_visits,
          visitas_prioritarios: this.priority_visits,
          lista_suspender: this.list_to_suspend,
          nome_usuario: this.userName,
          cpf_usuario: this.userCpf,
        };

        const { data } = await this.$axios.post(`/projects/normative-settings`, payload);
        // Check if the response contains a success message
        if (data.message === 'Sucesso!') {
          this.$snotify.success('Configurações salvas com sucesso!', 'Sucesso');
        }
      } catch (err) {
        console.log(err);
        this.$snotify.error("Oops, ocorreu um erro ao salvar", "Atenção");

        console.warn(err);
      } finally {
        this.loading = false;
      }
    },


    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },

}
</script>
