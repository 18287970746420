<template>
  <div>
    <v-card
      :width="dialog.width"
      :min-height="dialog.height"
      tile
      class="d-flex flex-column"
    >
      <v-card-title>Parâmetros</v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="4"
          >
            <v-select
              :value="form.lab"
              :items="labs"
              label="Laboratorio"
              item-text="description"
              item-value="id"
              filled
              dense
              hide-details
              return-object
              :loading="isLoading"
              @change="onLabSelect"
            />
          </v-col>

          <v-col
            cols="8"
            class="text-right align-center"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="purple"
                  elevation="3"
                  icon
                  large
                  raised
                  rounded
                  dark
                  v-on="on"
                  @click="test"
                >
                  <v-icon>bolt</v-icon>
                </v-btn>
              </template>

              Realiza teste manual de conexão com o laboratório
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
          >
            <v-select
              v-model="form.lab.integrationType"
              :items="integrationTypes"
              label="Tipo de Integração"
              filled
              dense
              hide-details
              :loading="isLoading"
            />
          </v-col>
          <v-col
            v-if="form.lab.integrationType === 'DATE_RANGE'"
            cols="4"
          >
            <v-text-field
              v-model="form.lab.dateRangeSearch"
              label="Dias Busca Retroativa"
              filled
              dense
              hide-details
              :loading="isLoading"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <v-select
              v-model="form.lab.integrationMethod"
              :items="integrationMethods"
              label="Método de Integração"
              filled
              dense
              hide-details
              :loading="isLoading"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="form.lab.apiUrl"
              label="URL Base"
              filled
              dense
              :loading="isLoading"
              hide-details
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="form.lastChecksum"
              label="Ultimo Checksum"
              filled
              dense
              clearable
              :loading="isLoading"
              hide-details
            />
          </v-col>
          <v-col
            cols="4"
          >
            <v-select
              v-if="form.lab && form.lab.description === 'UPF'"
              v-model="form.importAnalysisAboveLimit"
              label="Importar analise acima de 999"
              :items="importAnalysisAboveLimitItems"
              filled
              dense
              hide-details
              :loading="isLoading"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
          >
            <v-select
              v-model="form.producerSearchType"
              :items="producerSearchTypes"
              label="Tipo de Busca de Produtor"
              filled
              dense
              persistent-hint
              hint="Ordem da busca de produtor para o laticínio"
            />
          </v-col>

          <v-col
            cols="4"
          >
            <v-text-field
              v-model="form.parser.code"
              label="Regex - Código"
              persistent-hint
              dense
              filled
              hint="Regex utilizada para o replace do campo codigo_laticinio do produtor (Não utilizar /)"
            />
          </v-col>

          <v-col
            cols="4"
          >
            <v-text-field
              v-model="form.parser.document"
              label="Regex - CPF/CNPJ"
              persistent-hint
              dense
              filled
              hint="Regex utilizada para o replace do campo cpf_cnpj do produtor (Não utilizar /)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="parameter of form.parameters"
            :key="parameter.id"
            cols="6"
          >
            <v-text-field
              v-if="parameter.type !== 'SELECT'"
              v-model="parameter.value"
              :label="parameter.key"
              persistent-hint
              dense
              filled
              :hint="parameter.description"
            />

            <v-select
              v-if="parameter.type === 'SELECT'"
              v-model="parameter.value"
              :label="parameter.key"
              :items="getParameterItems(parameter)"
              persistent-hint
              dense
              filled
              :hint="parameter.description"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          text
          @click="close()"
        >
          Fechar
        </v-btn>

        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="executionTestResult.show"
      :timeout="-1"
      :color="executionTestResult.status === 'SUCCESS' ? 'deep-purple accent-4' : 'red accent-4'"
      elevation="24"
    >
      <div v-if="executionTestResult.status === 'SUCCESS'">
        Teste realizado com sucesso!
        <strong>{{ executionTestResult.results }} resultados e {{ executionTestResult.errors }} erros encontrados!</strong>
      </div>

      <div v-else>
        Ocorreram erros na realização do teste: {{ executionTestResult.debug }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="executionTestResult.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import isEmpty from "lodash/fp/isEmpty";

export default {

  props: {
    dialog: {
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default: () => ({}),
    },

    labs: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      saved: true,

      executionTestResult: {
        show: false,
        status: '',
        debug: '',
        results: 0,
        errors: 0,
      },

      form: {
        id: null,
        system: {
          key: null,
          name: null,
        },
        dairy: {
          id: null,
          description: null,
        },
        lab: {
          id: null,
          description: null,
          integrationType: null,
          dateRangeSearch: null,
          integrationMethod: null,
          apiUrl: null,
        },
        lastSyncedAt: null,
        status: null,
        parameters: [],
        lastChecksum: null,
        producerSearchType: null,
        parser: {
          code: null,
          document: null
        },
        importAnalysisAboveLimit: false
      },

      producerSearchTypes: [
        {
          text: "Apenas Código",
          value: "ONLY_CODE",
        },
        {
          text: "Apenas CPF/CNPJ",
          value: "ONLY_DOCUMENT",
        },
        {
          text: "Código -> CPF/CNPJ",
          value: "CODE_DOCUMENT",
        },
        {
          text: "CPF/CNPJ -> Código",
          value: "DOCUMENT_CODE",
        },
      ],

      integrationTypes: [
        {
          text: "Busca Retroativa",
          value: "DATE_RANGE",
        },
        {
          text: "Cursor",
          value: "CURSOR",
        },
      ],

      integrationMethods: [
        {
          text: "Gravação Direta",
          value: "DIRECT_SAVE",
        },
        {
          text: "Pré-validação",
          value: "PRE_VALIDATION",
        },
      ],
      importAnalysisAboveLimitItems: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ]
    }
  },

  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(loading) {
        return this.$emit('update:loading', loading);
      },
    },
  },

  watch: {
    form: {
      deep: true,
      handler(form) {
        this.saved = false;

        return this.$emit('update:settings', form);
      },
    },
  },

  async mounted() {
    this.form = {
      ...this.form,
      ...this.settings,
    }

    await this.$nextTick();

    const { parameters = [] } = this.labs.find(({ id }) => id === this.form.lab.id) || {};

    // Verifica se o parâmetro ainda existe nas configs do lab, caso não exista, remove-se ele
    this.form.parameters = this.form.parameters.map(parameter => {
      const isParameterActive = parameters.some(labParameter => labParameter.id === parameter.id);

      return isParameterActive ? parameter : null;
    }).filter(n => n);

    parameters.forEach(labParameter => {
      const hasParameterAlready = this.form.parameters.find(({ id }) => id === labParameter.id);

      if (hasParameterAlready) {
        return;
      }

      this.form.parameters = [
        ...this.form.parameters,
        labParameter,
      ]
    })

    this.saved = true;
  },

  methods: {
    onLabSelect(lab) {
      const parameters = lab.parameters.map(lab => ({ ...lab, value: null }));

      this.form = {
        ...this.form,
        lab,
        lastSyncedAt: null,
        status: 'PENDING',
        parameters,
      }
    },

    async save(notify = true) {
      try {
        this.isLoading = true;

        const lab = this.form.lab;

        if (isEmpty(lab)) {
          throw new Error('Formulário inválido!');
        }

        const parametros = (this.form.parameters || []).map(parameter => {
          return {
            id: parameter.id,
            chave: parameter.key,
            valor: parameter.value,
            descricao: parameter.description,
            tipo: parameter.type,
            opcoes: parameter.options,
          }
        });

        await this.$axios.put(`/settings/labs/dairy/${this.form.id}`, {
          id_laboratorio: lab.id,
          nome_laboratorio: lab.description,
          tipo_integracao: lab.integrationType,
          intervalo_busca: lab.dateRangeSearch,
          metodo_integracao: lab.integrationMethod,
          endpoint_integracao: lab.apiUrl,
          ultimo_checksum: this.form.lastChecksum,
          tipo_busca_produtor: this.form.producerSearchType,
          regex_codigo_laticinio: this.form.parser.code,
          regex_cpf_cnpj: this.form.parser.document,
          importa_analises_acima_do_limite: this.form.importAnalysisAboveLimit,
          parametros,
        });

        this.saved = true;

        if (!notify) {
          return;
        }

        this.$snotify.success("Informações salvas com sucesso", "Sucesso");

        return this.$emit('onSave');
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    close() {
      return this.$emit('close');
    },

    async test() {
      try {
        if (!this.saved) {
          await this.save(false);
        }

        this.isLoading = true;

        const { data } = await this.$axios.put(`/settings/labs/dairy/${this.form.id}/test`);

        const result = data.result || {};

        this.executionTestResult = {
          show: true,
          status: data.status,
          debug: result.errorMessage || result.message || '',
          results: data.results,
          errors: data.errors,
        }

        if (data.status === 'ERROR') {
          return;
        }

        return this.$emit('onSave');
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao testar a integração!", "Atenção");

        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    getParameterItems(parameter) {
      if (isEmpty((parameter.options))) {
        return [];
      }

      return parameter.options.split(',').map(option => ({ text: option, value: option }));
    }
  },
}
</script>
