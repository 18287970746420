<template>
  <v-simple-table class="pl-3 pt-4">
    <template #default>
      <thead>
        <tr>
          <th>
            Chave

            <v-btn
              icon
              small
              class="ml-2"
              @click="() => show = !show"
            >
              <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
            </v-btn>
          </th>
          <th>Anterior</th>
          <th>Atual</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(item, key) in value"
        >
          <template v-if="!show && hasNoDiff(item)" />
          <template
            v-else-if="isArray(item)"
          >
            <tr
              :key="`${itemKey}-${key}`"
            >
              <td
                colspan="3"
                class="text-left"
              >
                {{ startCase(key) }} [{{ item.length }}]
              </td>
            </tr>
            <tr
              v-if="isSimpleList(item)"
              :key="`simplelist-${key}`"
            >
              <td
                colspan="3"
                class="text-left"
              >
                <audit-item
                  :value="item"
                  :item-key="key"
                />
              </td>
            </tr>
            <template v-else>
              <tr
                v-for="(innerItem, idx) in item"
                :key="`${itemKey}-${key}-${idx}`"
              >
                <template v-if="!isEmpty(innerItem)">
                  <td
                    colspan="3"
                    class="text-left"
                  >
                    <audit-item
                      :value="innerItem"
                      :item-key="`${itemKey}-${key}-${idx}`"
                    />
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template
            v-else-if="isSimpleObject(item)"
          >
            <tr :key="`key-${itemKey}-${key}`">
              <td
                colspan="3"
                class="text-left"
              >
                {{ startCase(key) }}
              </td>
            </tr>
            <tr :key="`${itemKey}-${key}`">
              <td
                colspan="3"
                class="text-left"
              >
                <audit-item
                  :value="item"
                  :item-key="itemKey"
                />
              </td>
            </tr>
          </template>
          <tr
            v-else-if="show || item.previous !== item.current"
            :key="`${itemKey}-${key}`"
          >
            <td class="text-left">
              {{ startCase(key) }}
            </td>
            <td
              class="text-left"
              :class="{
                'red--text text--accent-3': item.previous !== item.current,
                'blue--text text--accent-3': item.previous === item.current
              }"
            >
              {{ item.previous }}
            </td>
            <td class="text-left green--text text--accent-3">
              {{ item.current }}
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';
import isObject from 'lodash/fp/isObject';
import startCase from 'lodash/fp/startCase';

export default {
  name: 'audit-item',

  props: {
    value: [Object, Array],
    itemKey: String,
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    isArray,
    isEmpty,
    startCase,
    isSimpleList(item) {
      if (item.length === 0) {
        true;
      }
      return item[0] && ('previous' in item[0])
    },
    isSimpleObject(item) {
      if (!isObject(item)) {
        false;
      }
      return !('previous' in item || 'current' in item)
    },

    hasNoDiff(item) {
      if (isArray(item)) {
        if (this.isSimpleList(item)) {
          return !item.some(o => o.previous !== o.current);
        }

        return false;
      }

      if (this.isSimpleObject(item)) {
        return Object.values(item).every(o => this.hasNoDiff(o));
      }

      return item?.previous === item?.current
    },
  }
}
</script>
